.form__title {
  padding: 10px 20px;
  font-size: 18px;
  text-align: center;
  margin-bottom: 0;
  color: theme-color('text-color');
}


.fobi-form {
  margin-bottom: 30px;
  @include make-row();

  .form-control {
    font-size: 16px;
    background-color: darken(white, 3);
  }

  .control-label {
    font-size: 16px;
    font-weight: bold;
    color: darken(theme-color('text-color'), 10);
  }

  .form-group {

    @include make-col-ready();
    // @include make-col(4);
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }

    p {
      margin-bottom: 0;
    }

    &.form-group-votre_message,
    &.form-group-comment_avez_vous_connu_le_service_groupes_et_congres2,
    &.form-group-merci_de_preciser_votre_choix_,
    &.form-group-comment_avez_vous_eu_connaissance_de_notre_site_internet_,
    &.form-group-si_autre_merci_de_preciser,
    &.form-group-quel_est_le_motif_de_votre_visite_sur_notre_site_internet_,
    &.form-group-si_autre_merci_de_preciser_2,
    &.form-group-que_pensez_vous_de_linformation_disponible_,
    &.form-group-facilite_a_trouver_les_informations_souhaitees_,
    &.form-group-avez_vous_telecharge_de_la_documentation_,
    &.form-group-si_oui_laquelle_,
    &.form-group-globalement_apres_la_visite_de_notre_site_internet_vous_etes_,
    &.form-group-des_remarques_des_suggestions_,
    &.form-group-avez_vous_deja_sejourne_sur_le_territoire_de_valence_romans_sud_rhone_alpes,
    &.form-group-quelle_image_avez_vous_du_territoire_de_valence_romans_sud_rhone_alpes_,
    &.form-group-si_vous_souhaitez_une_reponse_merci_de_laisser_votre_adresse_email_ {

      @include media-breakpoint-up(lg) {
        @include make-col(12);
      }
    }

  }

  .help-block {
    font-size: 12px;
    color: #9e9e9e;
  }

  .required-field, .has-error, .has-error .control-label {
    color: theme-color('red-darker');
    padding: 0.4rem;
  }
}


.form-group-newsletter {
  display: flex;

  label {
    margin-right: 20px;
  }
}

.form-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @include make-col-ready();
  @include make-col(12);

}

