.home-carousel {
  .carousel__img {
    @include media-breakpoint-up(lg) {
      height: calc(100vh - 65px);
      max-height: 900px;
    }

    @include media-breakpoint-only(md) {
      height: 500px;
    }

    @include media-breakpoint-down(sm) {
      height: calc(100vh - 105px);
    }

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: block !important;

  }

  .carousel-control-next,
  .carousel-control-prev {
    @include media-breakpoint-up(md) {
      span {
        display: inline-flex;
        width: 60px;
        height: 60px;
        background-size: 20px 20px;
        transition: all 0.3s ease;
        border-radius: 50%;
        position: relative;

        &.sr-only {
          display: none;
        }

        &::after {
          pointer-events: none;
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          content: "";
          box-sizing: content-box;
          transition: transform 0.2s, opacity 0.2s;
          transform: scale(0.8);
          opacity: 0;
          top: -7px;
          left: -7px;
          padding: 7px;
          box-shadow: 0 0 0 4px theme-color("primary");
        }
      }

      &:hover {
        span {
          background-color: theme-color("primary");

          &::after {
            transform: scale(1);
            opacity: 1;
          }
        }
      }

      &:focus {
        .carousel-control-prev-icon,
        .carousel-control-next-icon {
          background-color: theme-color("primary");
        }
      }
    }
  }

  .carousel-control-next {
    @include media-breakpoint-up(lg) {
      bottom: 30px;
    }
  }

  .carousel-content {

    position: absolute;
    color: white;
    text-align: center;
    p{
      font-size: 18px;
    }
    @include media-breakpoint-up(sm) {
      z-index: 999;
      &.slide-center-center{
        top: 50%;
        left: 50%;
        width: 800px;
        margin-left: -400px;
        transform: translateY(-50%);
      }
      &.slide-top-center{
        top: 150px;
        left: 50%;
        width: 800px;
        margin-left: -400px;
      }
      &.slide-bottom-center{
        bottom: 200px;
        left: 50%;
        width: 800px;
        margin-left: -400px;
      }

    }
    @include media-breakpoint-down(sm) {
      padding: 1rem;
      padding-bottom: 30vh;
      padding-top: 2rem;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    h1,
    h2 {
      font-family: $bukhari;
      text-align: center;
      text-shadow: 0 4px 11px rgba(0, 0, 0, 0.1);
      @include media-breakpoint-up(sm) {
        margin-bottom: 20px;
        color: #ffffff;
        font-size: 64px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 3rem;
        color: theme-color("primary");
      }
    }
  }
  .carousel-indicators {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.activity-gallery-carousel {
  .carousel-control-next {
    right: -5%;
  }
  .carousel-control-prev {
    left: -5%;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 20%;
    opacity: .8;

    &:hover {
      opacity: 1;
    }
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-color: theme-color("gray-dark");
    border: solid 1.2rem theme-color("gray-dark");
    border-radius: 50%;
    width: 35px;
    height: 35px;
  }
}
#simple_carousel{
  .carousel-control-prev, .carousel-control-next{
    top: 50%;
    bottom: unset;
    transform: translateY(-50%);
  }
}
