



$optin_primary: theme-color('primary');
$optin_slider: #2980b9;

.cookieoptin-notice{
  padding: 20px;
  background-color: #222;
  border:1px solid #fff;
  strong{
    color: white;
    display: block;
  }
  a{
    color: $optin_primary;
  }
  .btn{
    box-shadow: none;
    border-radius: 0;
    padding: 10px 15px;
    border:0;
    &.btn-success{
      background-color: $optin_primary;
      color: white;
    }
    &.btn-decline{
      border: 1px solid #888;
      color: #444;
      background-color: transparent;
    }
  }
}

#klaro{
  .klaro{
    input:checked + .cm-app-label .slider{
      background-color: $optin_slider;
    }
    p, h1, h2, ul, li, strong{
      color: #333;
    }
    a{
      color:$optin_primary ;
    }
    h1{
      font-size: 24px;
      font-weight: bold;
    }
    /* ---- Btn ----*/
    .cm-btn{
      box-shadow: none;
      border-radius: 0;
      padding: 10px 35px;
       transition: all .3s ease;
      &.cm-btn-success{
        background-color: $optin_primary;
        color: black;
        &:hover{
          background-color: darken($optin_primary, 5);
        }
      }
      &.cn-decline{
        border: 1px solid #fff;
        color: #888;
        background-color: transparent;

        &:hover{
          border: 1px solid #ccc;
          color: $optin_primary;
        }
      }
    }
    .cm-modal{
      background-color: #fff;
      .cm-header, .cm-footer{
        border-color: #eee;

      }
      .cm-toggle-all{
        border-top: 0;
      }
      .cm-body{
        padding: 30px;
      }
    }


    .cookie-notice {
      background-color: #fff;
      padding-top: 100px;
      box-shadow: none;
      border-radius: 0;
      border: 4px solid $optin_primary;
      @media (min-width: 690px){
        max-width: 500px;
      }
      &::before{
        content:"";
        position: absolute;
        top: 0;
        right: 50%;
        margin-right: -50px;
        height: 100px;
        width: 100px;
        background-image: url('../img/cookie.png');
      }


      .cn-ok{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #eee;
        @include media-breakpoint-down(sm){
          flex-direction: column;
          button{
            margin-bottom: 10px;
          }
        }
      }

    }
  }
}



.video {
  position: relative;
  .video-player {
      padding: 1rem;
  }
}
.cookieoptin-notice + iframe {
  display: none;
}

.cookieoptin-hide {
    display: none;
    &.cookieoptin-notice + iframe{
      display: block;
    }
}
