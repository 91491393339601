.main-wrap{
  position: relative;
}

/* Buttons */

.btn:focus {
	outline: none;
}

.btn--hidden {
	pointer-events: none;
	opacity: 0;
}
.search__form{
  border-bottom: 4px solid theme-color("dark");
  background-color: white;
  input{
    padding: 1rem 2rem;
    font-size: 3vw;
    color: theme-color("dark");;
    &[type="submit"]{
      &:hover{
        color: darken(theme-color('primary'), 2);
      }
    }
  }
  ::placeholder{
    color: rgba(black,.3);
  }
}

/* Reset Search Input */
.search_fulltxt{

  .search__input, input[type="submit"] {
    border: 0;
    background: transparent;
    border-radius: 0;
    -webkit-appearance: none;
  }
}

.search__input:focus {
	outline: none;
}


.js .main-wrap::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	opacity: 0;
	background: rgba(0,0,0,0.3);
}

/* Layout for search container */
.search_fulltxt {
	padding: 3em;
}

.js .search_fulltxt {
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 100%;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	background: theme-color('primary');
}

.js .search_fulltxt::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
}

.btn--search-close {
	font-size: 1rem;
	position: fixed;
	z-index: 9999;
	top: 1rem;
	right: 1rem;
  svg{
    width: 40px;
  }
}


.search__info {
	font-size: 90%;
	font-weight: bold;
	display: block;
	width: 50%;
	padding: 0.85em 0;
	color: #000;
}

.search__related {
	display: flex;
	width: 35%;
	padding: 4em 0 0 0;
	pointer-events: none;
}

.search__suggestion h3 {
	font-size: 1.35em;
	margin: 0;
}

.search__suggestion h3::before {
	content: '\21FE';
	display: inline-block;
	padding: 0 0.5em 0 0;
}

.search__suggestion p {
	font-size: 1.15em;
	line-height: 1.4;
	margin: 0.75em 0 0 0;
	color: #ff4848;
}

/************************/
/* Transitions 			*/
/************************/

.js .main-wrap::after {
	transition: opacity 0.3s;
	transition-timing-function: ease-out;
}

.js .main-wrap--overlay::after {
	opacity: 1;
	transition-delay: 0s;
}

.js .search_fulltxt {
	pointer-events: none;
	transition: transform 0.3s;
	transition-delay: 0.4s;
	transition-timing-function: ease-out;
}

.js .search--open {
	pointer-events: auto;
	transform: translate3d(-100%,0,0);
	transition-delay: 0s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.js .search_fulltxt::after {
	transition: transform 0.3s;
	transition-timing-function: ease-out;
}

.js .search--open::after {
	transform: translate3d(100%,0,0);
	transition-delay: 0.4s;
}

.js .btn--search-close {
	transition: opacity 0.1s;
	transition-delay: 0.3s;
}

@media screen and (max-width: 40em) {
	.btn--search-close {
		font-size: 1.25em;
	}
	.search__related {
		font-size: 80%;
		width: 75%;
	}
  .search__form{
    margin: 1rem;
    input{
      font-size: 2rem;
    }
  }

	.search__info {
		width: 90%;
	}
}




.plugin__search{
  @include make-container();
  max-width: 1140px;
  .pagenav{
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    span{
      padding: 0 !important;
    }
    .page-item{
      color: black;
      text-decoration: none;
    }
  }
  .search-results{
    ul{
      list-style: none;
      padding-left: 0;
      li{
        transition: backgroun .3s ease;
        padding: 1rem;
        &:hover{
          background-color: rgba(theme-color('primary'), .2);
        }
      }
      h3{
        font-size: 2rem;
      }
      .highlighted{
        color: black;
        font-weight: bold;
        text-decoration-color: rgba(21, 132, 196, 0.2);
        text-decoration-style: normal;
        text-underline-offset: 4px;
        text-decoration-thickness: 2px;
        box-shadow: inset 0 -5px 0 0 rgba(#1584c4, 0.2);
      }
    }
  }
  .frm__search{
    padding: 1rem;
    border: 1px solid #eee;
    background-color: darken(white, 3);
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    .wrapper{
      display: flex;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        width: 100%;
        label, input{
          display: block;
        }
        p{
          width: 100%;
        }
      }
      p{
        margin-bottom: 0;
        @include media-breakpoint-down(sm) {
          margin-bottom: 1rem;
          input{
            min-width: 100%;
          }
        }
      }
      input{
        padding: 18px 28px;
        border: 1px solid #eee;
        border-right: 0;
        &[type="search"]{

          &:focus{
            outline: theme-color('primary');
          }
        }
      }
    }
  }
}
