/* Default custom select styles */
div.cs-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;

  z-index: 100;
  width: 100%;
  max-width: 500px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1;
}



div.cs-select:focus {
  outline: none;
  /* For better accessibility add a style for this in your skin */
}

.cs-select select {
  display: none;
}

.cs-select span {
  display: flex;
  position: relative;
  cursor: pointer;
  padding: 1rem;
  align-items: center;

}

/* Placeholder and selected option */
.cs-select>span {
  padding-right: 3rem;
}

.cs-select>span::after,
.cs-select .cs-selected span::after {
  speak: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cs-select>span::after {
  right: 1em;
}

.cs-select .cs-selected span::after {
  margin-left: 1em;
}

.cs-select.cs-active>span::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

div.cs-active {
  z-index: 200 ;
  @include media-breakpoint-down(md){
    z-index: 200 !important;
  }
}

/* Options */
.cs-select .cs-options {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: #fff;
  visibility: hidden;
}

.cs-select.cs-active .cs-options {
  visibility: visible;
  border-bottom: 1px solid theme-color('blue');

  ul {
    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.12);
  }
}

.cs-select ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  li:not(:last-child) {
    border-bottom: 1px dashed #eee;
  }
}

.cs-select ul span {
  padding: 1em;
}

.cs-select ul li.cs-focus span {
  background-color: #ddd;
}

/* Optgroup and optgroup label */
.cs-select li.cs-optgroup ul {
  padding-left: 1em;
}

.cs-select li.cs-optgroup>span {
  cursor: default;
}





div.cs-skin-elastic {
  background: transparent;
}


.cs-skin-elastic>span {
  background-color: #fff;
  z-index: 100;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.cs-skin-elastic>span::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f078";
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.cs-skin-elastic .cs-options {
  overflow: visible;
  background: transparent;
  opacity: 1;
  visibility: visible;
  pointer-events: none;
  z-index: 999;
  top: 66px;
  left: 0;
}

.cs-skin-elastic.cs-active .cs-options {
  pointer-events: auto;
}

.cs-skin-elastic .cs-options>ul::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transform: scale3d(1, 0, 1);
  transform: scale3d(1, 0, 1);
  background: #fff;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.cs-skin-elastic.cs-active .cs-options>ul::before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: none;
  transition: none;
  -webkit-animation: expand 0.6s ease-out;
  animation: expand 0.6s ease-out;
}

.cs-skin-elastic .cs-options ul li {
  opacity: 0;
  -webkit-transform: translate3d(0, -25px, 0);
  transform: translate3d(0, -25px, 0);
  -webkit-transition: opacity 0.15s, -webkit-transform 0.15s;
  transition: opacity 0.15s, transform 0.15s;
}

.cs-skin-elastic.cs-active .cs-options ul li {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  -webkit-transition: none;
  transition: none;
  -webkit-animation: bounce 0.6s ease-out;
  animation: bounce 0.6s ease-out;
}

/* Optional delays (problematic in IE 11/Win) */
/*
/* with more items, more delays declarations are needed */
.cs-skin-elastic.cs-active .cs-options ul li:first-child {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.cs-skin-elastic.cs-active .cs-options ul li:nth-child(2) {
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

.cs-skin-elastic.cs-active .cs-options ul li:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.cs-skin-elastic.cs-active .cs-options ul li:nth-child(4) {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}


.cs-skin-elastic .cs-options span {
  background-repeat: no-repeat;
  background-position: 1.5em 50%;
  background-size: 2em auto;
}

.cs-skin-elastic .cs-options span:hover,
.cs-skin-elastic .cs-options li.cs-focus span,
.cs-skin-elastic .cs-options .cs-selected span {
  color: theme-color('primary');
}



@-webkit-keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1);
  }

  25% {
    -webkit-transform: scale3d(1, 1.2, 1);
  }

  50% {
    -webkit-transform: scale3d(1, 0.85, 1);
  }

  75% {
    -webkit-transform: scale3d(1, 1.05, 1)
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
  }
}

@keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1);
    transform: scale3d(1, 0, 1);
  }

  25% {
    -webkit-transform: scale3d(1, 1.2, 1);
    transform: scale3d(1, 1.2, 1);
  }

  50% {
    -webkit-transform: scale3d(1, 0.85, 1);
    transform: scale3d(1, 0.85, 1);
  }

  75% {
    -webkit-transform: scale3d(1, 1.05, 1);
    transform: scale3d(1, 1.05, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}


@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    opacity: 0;
  }

  25% {
    -webkit-transform: translate3d(0, 10px, 0);
  }

  50% {
    -webkit-transform: translate3d(0, -6px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 2px, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
    opacity: 0;
  }

  25% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  50% {
    -webkit-transform: translate3d(0, -6px, 0);
    transform: translate3d(0, -6px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 2px, 0);
    transform: translate3d(0, 2px, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

[data-value="family"],
[data-value="famille"] {
  span {
    &::before {
      margin-right: 20px;
      content: "";
      @include retina-sprite($sprite-family_black-group);
    }
  }

  &:hover {
    span {
      &::before {
        @include retina-sprite($sprite-family-group);
      }
    }
  }
}

[data-value="couple"] {
  span {
    &::before {
      margin-right: 20px;
      content: "";
      @include retina-sprite($sprite-couple_black-group);
    }
  }

  &:hover {
    span {
      &::before {
        @include retina-sprite($sprite-couple-group);
      }
    }
  }
}

[data-value="friends"],
[data-value="amis"] {
  span {
    &::before {
      margin-right: 20px;
      content: "";
      @include retina-sprite($sprite-friends_black-group);
    }
  }

  &:hover {
    span {
      &::before {
        @include retina-sprite($sprite-friends-group);
      }
    }
  }
}

[data-value="solo"] {
  span {
    &::before {
      margin-right: 27px;
      margin-left: 6px;
      content: "";
      @include retina-sprite($sprite-solo_black-group);
    }
  }

  &:hover {
    span {
      &::before {
        @include retina-sprite($sprite-solo-group);
      }
    }
  }
}

.cs-options li,
.cs-options li span {
  transition: all .3s ease;
}

.before .cs-options li {
  position: relative;

  &::before {
    border: 1px solid #eee;
    border-radius: 50%;
    content: "";
    height: 50px;
    width: 50px;
    position: absolute;
    left: 6px;
    top: 6px;
    background-color: #f5f5f5;
  }

}
