a {
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
  }
}

.button {
  background: theme-color("primary");

  // border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  display: inline-block;

  @include media-breakpoint-up(sm) {
    letter-spacing: 1px;
    padding: 18px 28px;
  }

  @include media-breakpoint-down(sm) {
    padding: 2rem;
  }

  text-align: center;
  //box-shadow: 0 13px 20px 0 rgba(241, 162, 165, 0.55);
  text-transform: uppercase;

  border: 0;
  transition-property: color,
  background-color,
  box-shadow;
  transition-duration: .3s;
  transition-timing-function: ease;

  &:hover {
    background: darken(theme-color("primary"), 3);
    box-shadow: 0 13px 20px 0 rgba(theme-color("primary"), 0.55);
    color: $white;
  }

  &.secondary {
    background: theme-color("blue");

    &:hover {
      background: darken(theme-color("blue"), 9);
      box-shadow: 0 13px 20px 0 rgba(theme-color("blue"), 0.55);
    }
  }
}

.btn {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  transition-property: color, background-color, box-shadow;
  transition-duration: .3s;
  transition-timing-function: ease;


  @include media-breakpoint-up(sm) {
    letter-spacing: 1px;
    padding: 18px 28px;
  }

  @include media-breakpoint-down(md) {
    padding: 1.5rem;
    font-size: 18px;
  }

  &.btn-sm {
    padding: 10px 18px;
  }


  &.btn-primary {
    color: $white;

    &:hover {
      background: darken(theme-color("primary"), 3);
      border-color: darken(theme-color("primary"), 3);
      color: $white;
    }
  }

  &.btn-secondary {
    background: theme-color("blue");
    border-color: theme-color('blue');
    color: $white;

    &:hover {
      background: darken(theme-color("blue"), 9);
    }
  }

}

.btn-outline-primary,
.btn-outline-secondary {
  &:hover {
    color: white !important;
  }
}

// .btn{
//   @extend .button;
//   background-color: transparent;
//   border: 1px solid #fff;
//   display: inline-block;
//   &.btn-outline-primary{
//     color: theme-color('primary');
//     border-color: theme-color('primary');
//   }
//   &.btn-primary{
//     color: white;
//     border-color: theme-color('primary');
//     background-color: theme-color('primary');;
//   }
//   &:hover{
//     box-shadow: none;
//     color: $white;
//   }
//   &.btn-outline-secondary{
//     color: theme-color('red');
//     border-color: theme-color('red');
//     &:hover{
//       background-color: theme-color('red');
//       color: $white;
//     }
//   }
// }
