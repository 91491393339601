.thematics__list {
  .teaser-default {
    @include media-breakpoint-up(lg) {

      &:nth-child(even) {
        transform: translateY(60px);
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
      // min-width: 100%;
      margin-bottom: 1rem;
      flex: none;
      width: 100%;
    }

    @include media-breakpoint-only(md) {
      flex: 1 0 30%;
      margin-right: 1%;
      height: 100%;
      min-height: 335px;

      >a {
        min-height: 335px;
      }


      &:last-child {
        margin-right: 0;

        .teaser-content {
          height: 110px;
        }
      }
    }
  }
}

.teaser {
  position: relative;

  &-link {
    display: block;
    position: relative;

    a {
      display: block;
      overflow: hidden;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 0;
        left: 0px;
        right: 0px;
        background-image: linear-gradient(180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 0.69) 100%);
      }

      img {
        min-width: 100%;
      }
    }

    .teaser-content {
      position: absolute;
      bottom: 0;
      padding: 1rem;
      z-index: 2;
    }
  }

  &-image {
    padding: 8px;
  }
}

.teaser-default {
  img {
    min-width: 100%;
  }

  .teaser-image {
    position: relative;
    transition: all 0.3s ease;
  }

  &::after {
    content: "";
    position: absolute;
    height: 10px;
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 3px 78px 0 rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.25);
    bottom: 0px;
    filter: blur(6px);
    border-radius: 50%;
  }

  >div,
  >a {
    background: #ffffff;
    border: 1px solid #f0efef;
    position: relative;
    z-index: 1;
    display: block;
    height: 100%;

    h2,
    h3,
    h4,
    .teaser-title {
      font-size: 20px;
      position: relative;
      padding-top: 20px;
      font-family: $intro_bold;
      color: theme-color('dark');

      &::before {
        content: "";
        position: absolute;
        width: 30px;
        height: 5px;
        top: 0;
        background: theme-color("yellow");
      }
    }

    &.sport {
      h3::before {
        background-color: theme-color("cyan");
      }

      .teaser-image:hover {
        background-color: theme-color("cyan");
      }
    }

    &.patrimoine {
      h3::before {
        background-color: theme-color("red");
      }

      .teaser-image:hover {
        background-color: theme-color("red");
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .teaser-content {
    padding: 2rem;
  }

  &:hover {
    .teaser-image {
      background-color: theme-color("primary");
    }
  }
}

#id_landing {
  .teaser {
    text-align: center;

    @include media-breakpoint-down(sm) {
      padding: 0;
      // min-width: 100%;
      margin-bottom: 1rem;
      margin-right: 1rem;
      flex: none;
    }

    .teaser-image {
      padding: 0;
    }

    .teaser-content {

      h3,
      h2,
      h4 {
        font-size: 16px;
        text-transform: uppercase;
        font-family: $intro_medium;
        color: theme-color('dark') !important;
        padding-top: 0 !important;

        &::before {
          display: none;
        }
      }

      p {
        color: theme-color("blue");
      }
    }
  }
}

.offices_link {
  .teaser {
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }
  }
}
