.row-background {
    padding-top: 3rem;
    padding-bottom: 3rem;

    h2:first-child,
    h3:first-child,
    h4:first-child {
        margin-top: 0;
    }

    a:hover {
        text-decoration: underline;
    }
}

.multicolumn-wrapper{
  padding-top: 3rem;
  padding-bottom: 3rem;
  &:first-child{
    padding-top: 1rem;
  }

}

.multicolumn-fixed-width  .multicolumn{
  @include make-container();
  padding-left: 0;
  padding-right: 0;
  @include container-widths;

}
.multicolumn-full-width  .multicolumn{
  @include make-container();
  padding-left: 0;
  padding-right: 0;
  overflow: visible;
  @include container-widths;

  @include media-breakpoint-down(sm){
    align-items: center;

    &::-webkit-scrollbar{
      display: none;
    }

  }
}

.column-background {
    padding: 15px;
    margin-bottom: 15px;
    overflow: hidden;

    p:last-child {
        margin-bottom: 0;
    }

    a:hover {
        text-decoration: underline;
    }
}

.blue-background {
  color: $white;
  background-color: theme-color("blue");

  h2,
  h3,
  h4,
  h5 {
    color: theme-color('white');
  }

  a:not(.btn){
    color: theme-color('primary');
    box-shadow: none;
  }
  .btn {
    color: theme-color('white');
    &.btn-outline-primary{
      border-color:white;
    }
    &.btn-outline-secondary{
      border-color:white;
    }
  }
  .post_content{
    color: theme-color('text');
    h3{
      color: theme-color('primary');;
    }
  }
}
.red-background {
  color: $white;
  background-color: theme-color("red-darker");

  h2,
  h3,
  h4,
  h5 {
    color: theme-color('white');
  }

  a:not(.btn){
    color: theme-color('gray');
    box-shadow: none;
  }
  .btn {
    color: theme-color('white');
    &.btn-outline-primary{
      border-color:white;
    }
    &.btn-outline-secondary{
      border-color:white;
    }
  }
  .post_content{
    color: theme-color('text');
    h3{
      color: theme-color('primary');;
    }
  }
}

.yellow-background {
  color: theme-color('gray');
  background-color: theme-color("primary");

  h2,
  h3,
  h4,
  h5, h6 {
    color: theme-color('gray') !important;
  }
  .btn {
    color: black;
    &.btn-outline-primary{
      border-color:black;
    }
    &.btn-outline-secondary{
      border-color:black;
    }
  }

  a:not(.btn){
    color: theme-color('secondary');
    box-shadow: none;
    &.btn{
      background-color: darken(theme-color('primary'), 10) ;
    }
  }

  .red-background{
    a {
      color: theme-color('primary');
      &.btn{
        background-color: darken(theme-color('secondary'), 10) ;
      }
    }
  }
}

.black-background {
  color: $white;
  background-color: #222;

  h2,
  h3,
  h4,
  h5, h6 {
    color: theme-color('primary') !important;
  }

  a:not(.btn){
    color: theme-color('secondary');
    box-shadow: none;
    &.btn{
      background-color: darken(theme-color('primary'), 10) ;
    }
  }
  .post_content{
    color: theme-color('text');
    h3{
      color: theme-color('primary');;
    }
  }
  .red-background{
    a {
      color: theme-color('primary');
      &.btn{
        background-color: darken(theme-color('secondary'), 10) ;
      }
    }
  }
}

.white-background {
  color: $body-color;
  background-color: $white;

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: theme-color('primary');
  }

  a:not(.btn){
    color: theme-color('secondary');
  }

  .yellow-background {
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $white;
    }
  }

  .red-background {
    a {
      color: theme-color('primary');
      &.btn-primary, &.btn{
        color: darken(theme-color('primary'), 10);
      }
    }

    h2,
    h3,
    h4,
    h5, h6 {
      color: $white;

    }
  }
}

.gray-background {
  color: $body-color;
  background-color: darken($white, 10);


  a:not(.btn){
    color: theme-color('secondary');
  }

  .yellow-background {
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $white;
    }
  }

  .red-background {
    a:not(.btn) {
      color: theme-color('primary');
      &.btn-primary, &.btn{
        color: darken(theme-color('primary'), 10);
      }
    }

    h2,
    h3,
    h4,
    h5, h6 {
      color: $white;

    }
  }
}

.gray-light-background {
  color: $body-color;
  background-color: theme-color('gray_light');


  a:not(.btn) {
    color: theme-color('secondary');
  }
  h2{
    color: theme-color('secondary');
  }
  h3{
    color: theme-color('primary');
  }
  .yellow-background {
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $white;
    }
  }

  .red-background {
    a:not(.btn) {
      color: theme-color('primary');
      &.btn-primary, &.btn{
        color: darken(theme-color('primary'), 10);
      }
    }

    h2,
    h3,
    h4,
    h5, h6 {
      color: $white;

    }
  }
}

.column-spacer {
    width: 100%;
}

.column-spacer-sm {
    height: 2em;
}

.column-spacer-md {
    height: 4em;
}

.column-spacer-lg {
    height: 8em;
}

.img-responsive{
  max-width: 100%;
  height: auto;
}
.multicolumn-background-image{
  background-blend-mode: multiply;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
