.hero__banner {
  position: relative;

  @include media-breakpoint-up(lg) {
    height: calc(100vh - 65px);
    max-height: 900px;
    min-height: 300px;
  }

  @include media-breakpoint-only(md) {
    height: 500px;
    width: 100%;
  }

  .homepage-hero-module {
    display: flex;
    justify-content: center;
  }

  .hello {
    z-index: 10;

    @include media-breakpoint-up(lg) {
      bottom: 10%;
      left: 50%;
      transform: translate(-50%, 0%);
      max-width: 800px;

      width: 90%;
    }

    @include media-breakpoint-up(md) {
      position: absolute;
    }

    @include media-breakpoint-only(md) {
      bottom: 40px;
      left: 20px;
      right: 20px;

      .form__thematics {
        margin-top: 0 !important;
        background-color: white;
      }
    }
    @include media-breakpoint-down(sm) {
      background-color: theme-color("gray_light");
      padding: 10px;
      padding-bottom: 3rem;
    }

    .form__thematics {
      @include media-breakpoint-up(md) {
        margin-top: 100px;
      }
    }

    h1 {
      font-family: $bukhari;
      text-align: center;
      color: #ffffff;
      text-shadow: 0 4px 11px rgba(0, 0, 0, 0.5);

      @include media-breakpoint-up(sm) {
        font-size: 91px;
        line-height: 91px;
        transform: rotate(-14deg);
      }
    }
  }
}

.thematics_form_section {
  background-color: darken(white, 5);

  @include media-breakpoint-up(md) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  @include media-breakpoint-down(md) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  h2 {
    margin-bottom: 20px;
  }

  form {
    box-shadow: 1px 1px 40px rgb(203, 203, 203);
  }
}

.form__thematics {
  font-family: $intro_medium;

  p {
    font-family: $intro_medium;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }

  form {
    display: flex;
    //background: $white;

    @include media-breakpoint-up(lg) {
      min-width: 800px;

      div {
        background-color: $white;
        z-index: 9999;
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      width: 100%;

      div {
        min-height: 80px;
        flex-direction: column;
        label {
          position: static;
          color: theme-color("dark");
        }
      }
    }

    button,
    div {
      flex: 1;
    }

    div {
      display: flex;
      position: relative;

      label {
        @include media-breakpoint-up(md) {
          position: absolute;
          z-index: 10000;
          top: 8px;
          left: 10px;
          color: theme-color("primary");
        }
        @include media-breakpoint-down(sm) {
          font-size: 22px;
        }
        font-size: 14px;
        pointer-events: none;
      }

      select {
        width: 100%;
        color: #6d7783;
        text-transform: uppercase;
        padding-top: 0.5rem;
      }
    }

    select {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      border: 0;
      border-radius: 0;
      padding-left: 2rem;
    }

    button {
      border: 0;
      background: theme-color("primary");

      padding: 2rem;
      color: $white;
      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }
  }

  .cs-select:focus {
    outline: solid 2px theme-color("primary");
  }
}

.homepage-hero-module {
  border-right: none;
  border-left: none;
  position: relative;
}

.no-video .video-container video,
.touch .video-container video {
  display: none;
}

.no-video .video-container .poster,
.touch .video-container .poster {
  display: block !important;
}

.video-container {
  position: relative;
  bottom: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #000;
}

.video-container .poster img {
  width: 100%;
  bottom: 0;
  position: absolute;
}

.video-container .filter {
  z-index: 100;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
}

.video-container video {
  position: absolute;
  z-index: 0;
  bottom: 0;
}

.video-container video.fillWidth {
  width: 100%;
}

body:not(#id_index) {
  .main_wrapper {
    position: relative;
    padding-top: 40px;
  }
}

@include retina-sprites($retina-groups);

.thematics {
  @include media-breakpoint-up(sm) {
    padding-bottom: 4rem;
    padding-top: 8rem;
  }

  @include media-breakpoint-down(md) {
    padding-top: 2rem;
  }
  &--wrapper{
    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 3rem;
      align-items: center;
      margin-bottom: 60px;
    }
  }
  &__list {

  }

  &__title {

  }

  &__list__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    @include media-breakpoint-down(sm){
     display: block;

    }

  }

  .title {
    @include media-breakpoint-up(sm) {
      padding-top: 80px;
    }
  }
}

.office {
  @include media-breakpoint-up(md) {
    padding: 4rem;
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 1rem;
  }
}

.content {
  @include media-breakpoint-down(md) {
    padding: 20px;
  }

  .menu {
    @include make-container();
    padding-left: 0;
    padding-right: 0;
    max-width: 1140px;
    margin-bottom: 3rem;
    display: grid;
    grid-gap: 30px;

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }

    &__item {
      min-height: 200px;
    }
  }
}
#id_landing{
  .menu {
    @include make-container();
    padding-left: 0;
    padding-right: 0;
    max-width: 1140px;
    margin-bottom: 3rem;
    display: grid;
    grid-gap: 30px;

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }

    &__item {
      min-height: 200px;
    }
  }
}
.menu {
  .menu__item {
    flex: 1 0 25%;

    &::before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    h3 {
      font-size: 18px;
      font-family: $bukhari;
      color: theme-color("white");
    }
  }
}

.menu__item {
  position: relative;
  display: block;
  max-width: 450px;
  overflow: hidden;

  .menu-image {
    background: theme-color("dark");
    overflow: hidden;
  }

  img {
    min-width: 100%;
    transition: all 1s ease;
  }

  .menu-content {
    position: absolute;
    top: 50%;
    padding: 1rem;
    z-index: 2;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);

    h3 {
      text-shadow: 1px 1px 40px #222;
      transition: all 0.3s ease;
      color: white;
    }
  }

  &:hover {
    img {
      transform: scale(3);
      opacity: 0.2;
    }

    h3 {
      color: theme-color("primary");
    }
  }
}

.mustsee {
  padding: 5rem 0rem;
  background: #f9f8ea;

  img {
    border-radius: 8px;
    max-width: 100%;
    height: auto;
  }

  .title {
    max-width: 800px;
    margin: 0 auto;
  }

  .slick-prev:before,
  .slick-next:before {
    color: theme-color("primary");
  }

  .slick-slide {
    transition: all 0.3s ease;
    position: relative;

    &:not(.slick-center) {
      transform: scale(0.85);
    }

    .carousel__img {
      position: relative;
      border-radius: 8px;

      img {
        border-radius: 8px;
        min-width: 100%;
        height: auto;
      }
    }

    .link,
    .carousel__content {
      opacity: 0;
      visibility: hidden;
    }

    .link {
      width: 40px;
      height: 40px;
      background: $white;
      border-radius: 50%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      position: absolute;
      bottom: 20px;
      left: 50%;
      margin-left: -20px;
      transform: translateY(140px);
      transition: all 0.3s ease;

      &::after {
        display: none;
      }
    }

    .carousel__content {
      transition: all 0.3s ease;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 7px;
      text-align: center;
      padding: 3rem;
      display: flex;
      border-radius: 8px;
      flex-direction: column;
      justify-content: center;
      background-color: rgba($black, 0.4);
      color: $white;
      z-index: 9;

      h3 {
        color: $white;
        font-family: $bukhari;
      }
    }

    &.slick-center, &.slick-current,
    &:hover {
      .carousel__content,
      .link {
        opacity: 1;
        visibility: visible;

        h3 {
          color: theme-color("primary");
        }
      }

      .link {
        transform: translateY(-20px);
      }
    }

    &:not(.slick-center):hover {
      .carousel__content {
        h3 {
          font-size: 20px;
        }
      }
    }
  }
}

.mustsee__list {
  position: relative;
  margin-bottom: 3rem;
  margin-top: 3rem;

  .slick-arrow {
    width: 40px;
    height: 40px;
    background-color: theme-color("primary");
    color: white;
    border-radius: 50%;
    transition: all 0.2s ease;

    &::after {
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      content: "";
      box-sizing: content-box;
      transition: transform 0.2s, opacity 0.2s;
      transform: scale(0.8);
      opacity: 0;
      top: -7px;
      left: -7px;
      padding: 7px;
      box-shadow: 0 0 0 4px theme-color("primary");
    }

    &::before {
      font-family: "Font Awesome 5 Free" !important;
      font-weight: 900;
      color: white;
      transition: all 0.2s ease;
      content: "\f054";
      font-size: 14px;
    }

    &.slick-prev {
      &::before {
        content: "\f053";
      }
    }

    &:hover {
      box-shadow: 1px 1px 8px #ddd;

      &::before {
      }

      &::after {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .slick-track {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .slick-center {
      min-width: 450px;
    }
  }

  .slick-next {
    right: 25px;
  }

  .slick-prev {
    left: 25px;
    z-index: 1;
  }
}

.experiences {
  @include media-breakpoint-up(md) {
    padding: 8rem 4rem;

    .block {
      align-items: center;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__list {
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
      @include make-col(8);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(9);
    }

    @include media-breakpoint-down(md) {
      .blog-latest-entries {
        .post-item {
          margin: 0;

          &:nth-child(even) {
            @include media-breakpoint-up(lg) {
              transform: translateY(-20px);
            }
          }
        }
      }
    }
    .blog-latest-entries article .blog-lead .post__author {
      top: -40px;
    }
  }

  &__title {
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
      @include make-col(4);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(3);
    }
  }

  .blog {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include media-breakpoint-down(sm) {
      padding: 10px;
      .plugin{
        max-width: 100%;
      }
    }
  }

  .blog__article {
    padding: 1rem;
    flex: 1;
    position: relative;

    .item-link-overlay {
      position: absolute;
      z-index: 1;
      top: 10px;
      right: 10px;
      bottom: 10px;
      left: 10px;
      width: 100%;
      height: 100%;
    }

    &:nth-child(2) {
      transform: translateY(-20px);
    }

    .item-image {
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.06);
      max-width: 100%;
      height: 350px;
      min-width: 250px;
      background-size: cover;
      border-radius: 8px;
    }

    .item-content {
      padding: 1.5rem;
      padding-top: 3rem;

      h3 {
        font-size: 26px;
      }
    }

    .author {
      position: absolute;
      transform: translateY(-60px);

      img {
        border-radius: 50%;
        border: 2px solid $white;
      }
    }
  }
}

.title {
  h6,p:first-child {
    color: theme-color("primary");
    font-family: $bukhari;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      font-size: 2.5rem;
    }
  }
  h2{
    @include media-breakpoint-down(sm) {
      font-size: 4rem;
      line-height: 1;
    }
  }
}
.content_sejour{
  .slick-dots{
    @include media-breakpoint-down(sm) {
      position: static;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}


.link {
  text-transform: uppercase;
  color: theme-color("cyan");
  font-weight: 800;
  font-size: 14px;
  position: relative;

  &::after {
    font-family: "Linearicons-Free";
    content: "\e87a";
    margin-left: 7px;
  }
}

.location {
  @include media-breakpoint-up(sm) {
    padding: 4rem;
  }

  @include media-breakpoint-down(sm) {
    padding: 2rem 0;
    p{
      text-align: center;
    }
  }

  background: #f8f8f8;
  margin-bottom: 3rem;

  .valence {
    animation: pulse 2s infinite;
  }

  .maps {
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      padding: 4rem;
    }

    img {
      max-width: 100%;
      display: block;

      &:first-child {
        max-width: 70%;
      }

      &:last-child {
        max-width: 30%;
        transform: translate(-50px, -60px);
      }
    }
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.events {
  overflow-x: hidden;

  @include media-breakpoint-down(sm) {
    .img__wrapper{
      display: none;
    }
    hr{
      display: none;
    }
  }

  @include media-breakpoint-up(sm) {
    padding: 4rem;
  }

  &__block {
    @include media-breakpoint-up(lg) {
      padding: 3rem;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    overflow: visible;

    @include media-breakpoint-down(sm) {
     display: block;

      &::-webkit-scrollbar{
        display: none;
      }
    }

    img {
      // /* Sketch doesnt export pattern fills at this point */
      box-shadow: 30px 20px 39px 16px rgba(0, 0, 0, 0.05);
      @extend .img-fluid;
      min-width: 100%;
      border-radius: 10px;
    }

    .date {
      font-family: $bukhari;
      font-size: 16px;
      color: #9fd2c0;

      span {
        margin-right: 6px;
      }
    }
  }

  .event {
    padding: 1rem;
    transition: all 0.3s ease;
    @include media-breakpoint-up(sm) {
      flex: 1 0 auto;
      width: 33%;

    }

    @include media-breakpoint-down(sm) {
      flex: none;
      margin-bottom: 2rem;
      background-color: rgba(theme-color('primary'), .1);
      border-radius: 10px;
    }

    &:hover {
      transform: translateY(-10px);

      .event__link a {
        color: theme-color("blue");
      }
    }

    .type {
      font-style: italic;
    }

    &__title {
      font-family: $intro_bold;
      font-size: 18px;
      color: #272726;
    }

    &__content {
      font-size: 16px;
      color: #656565;
      line-height: 29px;
      font-family: $intro_regular;
    }

    &__image {
      position: relative;
      display: block;
    }

    &__link {
      a {
        color: theme-color("text-color");

        &:hover {
          color: theme-color("blue");
        }
      }
    }
  }
}

.img__wrapper .img-thumbnail-img{
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: -40px;
    right: -40px;
    background-image: url("../img/bg.png");
    height: 120px;
    width: 120px;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background: url("../img/bg@2x.png") no-repeat top left;
      background-size: 120px 120px;
    }
  }
}

.greeters {
  padding: 1rem 4rem;
  margin-top: 5rem;
  background: #f9f6ed;

  .text {
    padding-top: 3rem;
  }

  img {
    @include media-breakpoint-up(sm) {
      transform: translateY(-4rem);
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  @include media-breakpoint-down(sm) {
    padding-top: 40px;
  }
}

ul.menu {
  @include make-row();
  width: 100%;
  @extend .list-unstyled;
  margin: 0;

  li {
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }

    a {
      padding: 1rem;
      color: theme-color("primary");
      display: block;

      transition: all 0.3s ease;
      font-weight: bold;
      font-size: 22px;
      position: relative;
      margin-top: 20px;
      border: 2px solid darken($white, 6);

      &:hover {
        text-decoration: none;
        transform: translateY(-20px);
      }

      span {
        background-color: darken($white, 6);
        justify-content: center;
        align-items: center;
        text-align: center;
        display: flex;
        min-height: 200px;
        width: 100%;
        height: 100%;
        padding: 1rem;
      }
    }
  }
}

.offices_link {
  @include make-row();
  position: relative;
  z-index: 1;

  ul.menu {

    @include media-breakpoint-down(sm){
      align-items: center;
      flex-wrap: nowrap;
      overflow-x: scroll;

      &::-webkit-scrollbar{
        display: none;
      }
    }

    li {
      @include media-breakpoint-down(sm) {
        padding: .5rem;
        margin-bottom: 1rem;
        margin-right: 1rem;
        flex: none;
        width: 90%;
      }
      a {
        border-color: $white;

        span {
          background-color: $white;
          font-family: $bukhari;
          font-weight: normal;
          letter-spacing: 1px;
        }
      }
    }
  }
}

.office {
  display: flex;
  align-items: center;
  position: relative;
  background: linear-gradient(46deg, #6cb1cc 0%, theme-color("blue") 100%);
  overflow: hidden;

  &::after {
    background-image: url(../img/wow.png);
    background-size: cover;
    background-position: center center;
    content: "";
    display: block;
    height: 400px;
    width: 700px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @include media-breakpoint-up(sm) {
    background-position: center;
    background-repeat: no-repeat;
    min-height: 400px;
  }

  @include media-breakpoint-down(sm) {
    background-color: theme-color("primary");
  }
}

.top_image {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(lg) {
    min-height: 700px;
    height: 700px;
  }

  @include media-breakpoint-only(md) {
    height: 400px;

    h1 {
      font-size: 40px !important;
    }
  }

  background-color: #222;
  text-align: center;
  overflow: hidden;
  position: relative;

  .picture__bg {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    opacity: 1;
    background-blend-mode: overlay;
  }

  .top_content {
    position: relative;
  }

  h1 {
    font-family: $bukhari;
    color: #ffffff;

    @include media-breakpoint-up(md) {
      text-align: center;
      text-shadow: 0 4px 11px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;
      font-size: 70px;
      margin-bottom: 2rem;
      text-shadow: 1px 1px 40px #4e4e4e;
    }
  }

  .breadcrumb {
    background-color: transparent;
    text-align: center;
    justify-content: center;
    color: $white;

    .breadcrumb-item::before {
      color: white;
    }

    .breadcrumb-item.active {
      color: white;
    }

    a {
      color: theme-color("primary");
    }
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "›";
}

.best_items {
  @include media-breakpoint-up(md) {
    padding: 8rem 0;
  }

  @include media-breakpoint-down(md) {
    padding: 2rem;
  }

  background: darken($white, 3);
  margin-bottom: 2rem;

  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > div {
      flex: 1 0 50%;
      padding: 1rem;
      display: flex;
      position: relative;
      max-width: 50%;

      img {
        max-width: 100%;
        min-width: 100%;
        height: auto;
        border-radius: 8px;
        box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.06);
      }

      a {
        display: block;
        width: 100%;
      }

      &:nth-child(2) {
        img {
          transform: scale(0.94);
          transform-origin: bottom left;
        }
      }

      &:nth-child(3) {
        img {
          transform: scale(0.9);
          transform-origin: top right;
        }
      }

      &:nth-child(4) {
        img {
          transform: scale(0.7);
          transform-origin: top left;
        }
      }
    }
  }
}

.activities {
  padding: 4rem 0;

  .title {
    text-align: center;
  }

  &_list {
    @include media-breakpoint-up(md) {
      background-image: linear-gradient(
        180deg,
        #ffffff 0%,
        #ffffff 22%,
        #f8d369 22%,
        #f8d369 76%,
        #ffffff 76%,
        #ffffff 100%
      );
    }

    @include media-breakpoint-down(md) {
      background-color: #f8d369;
      padding: 2rem;
    }

    .wrapper {
      max-width: 900px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;

      @include media-breakpoint-up(md) {
        padding-top: 40px;
        margin-top: 20px;
        overflow: visible;
      }

      @include media-breakpoint-down(sm){
        margin-top: 2rem;
        display: block;

      }

      .menu__item {
        @include media-breakpoint-up(md) {
          max-width: 50%;
        }

        @include media-breakpoint-up(md) {
          padding: 10px;
        }

        @include media-breakpoint-down(md) {
          margin-bottom: 20px;
        }

        @include media-breakpoint-down(sm) {
          padding: 0;
          // min-width: 100%;
          margin-bottom: 1rem;
          flex: none;
          .child{
            background-color: #222;
            .menu-image{
              img{
                opacity: .6;
              }
            }
          }
        }

        &-image {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;

            background-image: linear-gradient(
              180deg,
              rgba(97, 118, 127, 0) 0%,
              #324148 100%
            );
          }
        }

        h3 {
          font-size: 20px;
          position: relative;
          padding-bottom: 20px;
          letter-spacing: 1px;
          font-family: $bukhari;

          &::after {
            content: "";
            position: absolute;
            width: 40px;
            height: 2px;
            background: theme-color("primary");
            bottom: 10px;
            left: 50%;
            margin-left: -20px;
          }
        }

        &:nth-child(odd) {
          @include media-breakpoint-up(md) {
            transform: translateY(-40px);
          }
        }
      }
    }
  }
}

.thematics_link {
  overflow: hidden;

  @include media-breakpoint-up(md) {
    padding: 4rem;
  }

  @include media-breakpoint-down(md) {
    padding: 3rem 0;
  }

  @include media-breakpoint-down(sm) {
    min-height: 0;
  }

  position: relative;
  min-height: 400px;

  &::before {
    background-size: cover;
    background-position: center center;
    content: "";
    display: block;

    position: absolute;

    @include media-breakpoint-up(md) {
      top: 50%;
      left: 50%;
      height: 400px;
      width: 900px;
      transform: translate(-50%, -50%);
    }

    @include media-breakpoint-down(md) {
      width: 130%;
      height: 210px;
      top: 0;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .container {
    position: relative;
  }

  &.gourmet {
    @include media-breakpoint-up(lg) {
      background: linear-gradient(-46deg, #f8d369 0%, #c9a645 100%);

      &::before {
        background-image: url(../img/saveur.png);
      }

    }
  }

  &.sport {
    @include media-breakpoint-up(lg) {
      background: linear-gradient(-46deg, #9fd2c0 0%, rgb(107, 171, 148) 100%);

      &::before {
        background-image: url(../img/nature.png);
      }
    }
  }

  &.patrimoine {
    @include media-breakpoint-up(lg) {
      background: linear-gradient(
        -46deg,
        rgb(242, 169, 172) 0%,
        rgb(201, 125, 132) 100%
      );

      &::before {
        background-image: url(../img/patrimoine.png);
      }
    }
  }

  li.selected {
    position: relative;

    > a {
      color: black !important;
    }
  }

  .thematic {
    @include media-breakpoint-up(md) {
    }

    padding: 3rem 1rem;
    text-align: center;
    position: relative;
    z-index: 1;

    .title {
      font-family: $intro_bold;
      font-size: 18px;
      margin-top: 1rem;
    }

    .icon {
      width: 60px;
      height: 60px;
      background: $white;
      border-radius: 50%;
      margin: 0 auto;
    }

    &.gastronomy {
      background-color: theme-color("red");
      color: black;
    }

    &.sport {
      background-color: theme-color("cyan");
      color: black;
    }

    &.patrimoine {
      background-color: theme-color("primary");
      color: black;
    }
  }
}

.social_wall {
  @include media-breakpoint-up(md) {
    padding: 4rem;
  }

  @include media-breakpoint-down(md) {
    padding: 2rem 0;
  }

  .wrapper {
    @include make-row();
  }

  .social_item {
    @include make-col-ready();
    margin-top: 3rem;

    @include media-breakpoint-up(sm) {
      @include make-col(4);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(3);
    }

    position: relative;

    &::after {
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 5 Brands";
      content: "\f16d";
      color: theme-color("instagram");
      position: absolute;
      bottom: 15px;
      left: 30px;
    }

    img {
      max-width: 100%;
    }
  }
}

.rating {
  color: theme-color("primary");

  i {
    font-size: 14px;
  }
}

.breadcrumb {
  background: transparent;
  padding-left: 0;
}

.flex-list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 0;

  li {
    flex: 1 0 25%;

    i {
      margin-right: 10px;
    }
  }
}
#id_index{
  right: 0 !important;
  width: 100%;
}
#id_content {
  // right: 0 !important;
  width: 100%;

  .intro_sejours{

    margin-top: 2rem;
    .intro_text_sejours{
      font-size: 20px;
      font-family: $intro_bold;
      font-style: italic;

      @include media-breakpoint-up(md){
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }


  .image_intro {
    position: relative;

    @include media-breakpoint-up(lg) {
      transform: translateY(-100px);
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
    img {
      min-width: 100%;
    }
  }



  .main_wrapper {
    padding-top: 0 !important;
  }

  .top_image {
    @include media-breakpoint-up(lg) {
      min-height: 500px;
      height: 500px;

      h1 {
        font-size: 40px;
      }
    }

    @include media-breakpoint-down(sm) {
      font-size: 30px;
    }
  }
}


.intro_text {
  padding-top: 2rem;
}

.content {
  .page__title {
    @include make-container;
    max-width: 1140px;
    margin-bottom: 3rem;
  }

  h2 {
    color: theme-color("cyan");
    @include media-breakpoint-up(md) {
      @include font-size(3.6);
    }
  }
}

.content_sejour{
  margin-top: 2rem;

  h2{
    font-size: 30px;
  }
  .slick-dots{
    margin-bottom: 10px;
    li button:before {
      font-size: 16px;
      color: theme-color('secondary');
    }
  }
  .pictures_sejour{
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    img{
      min-width: 100%;
      height: auto;
    }
    @include media-breakpoint-down(sm) {
      align-items: center;
      flex-wrap: nowrap;
      overflow-x: scroll;
      justify-content: start;
    }

    .sejour__pictures_onepicture{

      img {
        @include media-breakpoint-down(sm) {
          padding: .5rem;
          margin-bottom: 1rem;
          margin-right: 1rem;
          flex: none;
        }
      }
    }
  }

  .blog_posts{
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: #eee;

    p:first-child{
      color: #fdc600;
      font-family: "BukhariScript-Regular", "Helvetica Neue", Arial, sans-serif;
      font-size: 26px;
    }

    .blog-latest-entries{

      gap: 3rem;
      .post-item{
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        margin-bottom: 0;
        @include media-breakpoint-down(sm) {
          box-shadow: none;
        }

        .blog-visual{

          .img-fluid {
            border-radius: 0;
            object-fit: cover;
            width: 350px;
            height: 300px;
          }
        }

        .blog-lead{
          .post__author{
            top: -40px;
          }
        }


      }
    }
  }

  .sejours_restaurants{
    margin-top: 4rem;
    margin-bottom: 4rem;

    p:first-child{
      color: #fdc600;
      font-family: "BukhariScript-Regular", "Helvetica Neue", Arial, sans-serif;
      font-size: 26px;
    }

    .list__items{
      @include media-breakpoint-up(md) {

        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 2rem;
      }


      &::-webkit-scrollbar {
        display: none;
      }

      .item{

      }
    }
  }
  .sejours_events{
    background-color: #eee;
    padding-top: 4rem;
    padding-bottom: 4rem;

    p:first-child{
      color: #fdc600;
      font-family: "BukhariScript-Regular", "Helvetica Neue", Arial, sans-serif;
      font-size: 26px;
    }

    .list__items{
      @include media-breakpoint-up(md) {

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .sejours_others{
    background-color: #3f92b5;
    padding-top: 4rem;
    padding-bottom: 4rem;
    h2{
      text-align: center;
      color: white;
    }
  }
}


.landing_intro {
  .breadcrumb {
    @include media-breakpoint-up(lg) {
      padding-left: 48%;
    }
  }
}

.thematic_intro {
  display: flex;

  @include media-breakpoint-up(md) {
    justify-content: center;
    align-items: center;
    margin-bottom: 8rem;
    padding-left: 4rem;
    padding-right: 4rem;

    .image {
      min-width: 40%;
      margin-top: -8rem;
    }

    .text {
      padding: 1rem 3rem 3rem 8rem;

      h2 {
        margin-top: 30px;
      }

      .link-wrapper {
        margin-top: 20px;

        a {
          display: inline-block;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding: 2rem;
    flex-direction: column-reverse;
    .img__wrapper{
      display: none;
    }
  }
}

.thematics_menu {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 30px;
    overflow: visible;
  }

  @include media-breakpoint-down(sm){
    margin-top: 20px;
    display: flex;
    overflow-x: scroll;
    gap: 10px;
  }

  padding-left: 0;
  list-style: none;

  li {
    display: flex;
    min-height: 100px;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    @include media-breakpoint-down(md) {
      margin-bottom: 2rem;
    }

    @include media-breakpoint-down(sm) {
      padding: 2rem;
      margin-bottom: 1rem;
      flex: 1 0 90%;
    }

    background-color: theme-color("cyan");

    &:first-child {
      background-color: theme-color("primary");
    }

    &:last-child {
      background-color: theme-color("red");
    }


    a {
      color: theme-color("dark");
      font-family: $intro_bold;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    .icon {
      margin-bottom: 1rem;
    }
  }
}

#id_landing {
  .blog-latest-entries {
    padding-top: 5rem;
    padding-bottom: 5rem;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);

    article:nth-child(2n) {
      @include media-breakpoint-up(sm) {
        transform: translateY(40px);
      }
    }

    .blog-visual {
      margin-bottom: 0;
    }

    .article-wrapper {
      background: #ffffff;
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.06);

      img {
        box-shadow: none;
        border-radius: 0;
      }

      h3 {
        color: theme-color("primary");

        a {
          color: inherit;
        }
      }

      a {
        color: theme-color("cyan");
      }

      .read-more {
        text-transform: uppercase;
        font-family: $intro_medium;
        letter-spacing: 0.8px;
        font-size: 14px;
      }
    }
  }

  .blog-lead {
    padding: 3rem;
  }
}

.gallery__grid {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.list__items {
  margin-top: 2rem;

  .wrapper {
    background: $white;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .content_wraper {
    padding: 3rem 1rem;
    display: block;
    text-align: center;
    color: theme-color("text-color");
  }

  .item_title {
    text-transform: uppercase;
    font-family: $intro_bold;
    font-size: 14px;
    color: theme-color("gray");
  }

  .item__content {
    color: theme-color("blue");
  }

  .item__image {
    position: relative;
    display: block;
  }
}

.france_map {
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
  }
}

.wishlist-wrapper {
  .count {
    height: 20px;
    width: 20px;
    background-color: theme-color("primary");
    border-radius: 20px;
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    top: 0px;
    right: -7px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: theme-color("dark");
  }
}

.wishlist-count.wishlist-empty {
  .count {
    display: none;
  }
}

/* ==========================================================================
  Detail page
========================================================================== */
.address__block {
  min-height: 100%;
  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 10px;
  }
}
#id_activity_detail .detail_title{
  @include media-breakpoint-down(sm) {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
}
.detail_header {
  position: relative;
  padding-bottom: 50px;
  @include media-breakpoint-down(sm) {
    .container{
      padding: 0;
    }
  }
}

.at-share-tbx-element {
  min-width: 130px;
  text-align: center;
  padding: 0.6rem !important;

  .at-share-btn:last-child {
    margin-right: 0;
  }
}

#id_activity_detail {
  header {
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.06);
  }
  .carousel {
    @include media-breakpoint-down(md) {
      margin-top: 20px;
    }
  }

  .widget {
    @include media-breakpoint-down(md) {
      margin-top: 30px;
    }
  }

  .white_section{
    @include media-breakpoint-down(sm) {
      .container{
        padding: 0;
      }
    }
  }
  .detail_title {
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;

    h1 {
      text-transform: uppercase;
      color: theme-color("blue");
      font-family: $intro_black;
      margin-bottom: 0;
    }
  }

  .catalog {
    &.is__fixed {
      position: fixed;
    }
  }

  .activity-contact {
    li{
      @include media-breakpoint-up(md){
        margin-bottom: 1rem;
      }
      @include media-breakpoint-down(sm){
        font-size: 16px;
        margin-bottom: 2rem;
      }
    }
  }

  .adress__block {
    p{
      @include media-breakpoint-down(sm){
        font-size: 18px;
      }
    }
  }

  .activity-type {
    font-family: $intro_medium;
    color: theme-color("gray");
    font-size: 14px;

    @include media-breakpoint-down(sm){
      font-size: 18px;
    }
  }

  .catalog_list {
    padding: 0;
    min-width: 100%;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    text-transform: uppercase;
    font-size: 24px;
    position: relative;
    padding-bottom: 20px;
    color: theme-color("blue");

    &::after {
      width: 30px;
      height: 4px;
      content: "";
      position: absolute;
      left: 0;
      bottom: 10px;
      background: #f8d369;
    }
  }

  h3 {
    font-family: $intro_medium;
    letter-spacing: -1px;
    font-size: 20px;
    margin-bottom: 10px;
  }

  address {
    p {
      margin-bottom: 0;
      font-family: $intro_medium;
    }
  }

  .btn-goto-interactive-map {
    position: absolute;
    top: 1em;
    right: calc(15px + 1em);
    z-index: 9;

    @include media-breakpoint-down(sm) {
      position: relative;
      display: block;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 1rem;
    }

    @include media-breakpoint-down(sm) {
      position: relative;
      display: block;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 1rem;
    }
  }

  .location-map-wrapper {
    position: relative;
  }
}

.name .activity-address {
  padding-left: 60px;
  margin-left: 40px;
  margin-top: 20px;
  position: relative;

  &::before {
    content: "";
    height: 37px;
    width: 33px;
    background-image: url(../img/icons/location.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    position: absolute;
    left: 0px;
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    &::before {
      background-image: url(../img/icons/location@2x.png);
    }
  }
}

.activity-meansofpayments {
  @extend .list;
  @extend .list-inline;

  li {
    @extend .list-inline-item;
  }
}

.gray_section {
  background: theme-color("white_darker");
}

.gray_section,
.white_section {
  @include media-breakpoint-up(md) {
    padding: 5rem;

    .row {
      margin-bottom: 50px;
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.list {
  li {
    padding-left: 15px;
    position: relative;
    margin-right: 20px;

    &::before {
      bottom: 8px;
      left: 0;
      content: "";
      position: absolute;
      height: 8px;
      width: 8px;
      border-radius: 8px;
      border: 3px solid theme-color("primary");
    }
  }
}

#carousel-activity-photos {
  img {
    max-width: 100%;
    height: auto;
  }
}

.shape {
  position: absolute;
  left: 0;
  right: 0;
  height: 450px;
  background-size: cover;
  background-image: url("../img/shape.png");
  background-repeat: no-repeat;
  background-position: center bottom;
}

.address {
  h3 {
    text-transform: uppercase;
    color: $white;
    margin-top: 10px;
  }

  a {
    color: theme-color("primary");
  }
}

.activity-facilities {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  padding-left: 0;
  list-style-type: none;

  .icn {
    margin-right: 5px;
  }

  li {
    display: flex;
    margin-bottom: 0.5rem;
    align-items: center;

    span {
      &:first-child {
        min-width: 40px;
        height: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }

    @include media-breakpoint-up(md) {
      flex: 0 1 33%;
    }

    @include media-breakpoint-down(md) {
      flex: 1;
      min-width: 100%;
    }
  }
}

.linked-activities {
  margin-bottom: 2rem;
  font-family: $intro_medium;
  @include make-row;

  img {
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.06);
    max-width: 100%;
  }

  .result__photo {
    position: relative;
  }

  .list-item-content {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .type {
    font-family: $avenir_regular;
  }

  .locality {
    color: theme-color("blue");
    font-family: $intro_medium;

    i {
      margin-right: 5px;
    }
  }

  .item {
    margin-bottom: 1rem;

    .wishlist-controls-wrapper {
      position: absolute;
      top: 10px;
      right: 30px;

      a {
        color: white;
      }
    }

    .price {
      position: absolute;
      bottom: -20px;
      z-index: 1;
      right: 20px;
      background: #ffffff;
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.06);
      border-radius: 5px;
      padding: 1rem;
      font-size: 13px;
    }

    .list-item-content {
      padding-top: 2.5rem;

      .name,
      .type {
        margin-bottom: 0;
      }
    }
  }
}

.quick_links {
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.06);
  border-radius: 28.5px;
  position: fixed;
  z-index: 9;
  right: 20px;
  top: 50%;
  padding: 20px 15px;
  color: theme-color("dark");
  transition: all 0.3s ease;
  transform: translateY(-50%);
  @include media-breakpoint-down(sm) {
    display: none;
  }

  > div:not(:last-child) {
    margin-bottom: 1rem;
  }

  a,
  span,
  i {
    color: theme-color("dark");

    &:hover {
      color: theme-color("primary");
    }
  }

  .share__buttons {
    margin-bottom: 0;
  }

  .social-share-buttons {
    display: flex;
    padding: 1rem;

    a.social-share-button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      color: white;
      border-radius: 50%;
      margin-right: 10px;

      i {
        font-size: 12px;
        color: white;
      }

      &.facebook-share-button {
        background-color: theme-color("facebook");
      }

      &.twitter-share-button {
        background-color: theme-color("twitter");
      }

      &.googleplus-share-button {
        background-color: theme-color("googleplus");
      }

      &.linkedin-share-button {
        background-color: theme-color("linkedin");
      }

      &.email-share-button {
        background-color: theme-color("primary");
        margin-right: 0;
      }
    }
  }
}

.breadcrumb__detail {
  margin-bottom: 1rem;
}

#carousel-activity-photos {
  .zoom {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background-color: theme-color("primary");
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &:hover {
    .zoom {
      opacity: 1;
      visibility: visible;
      transform: scale(1.1);
    }
  }
}

.address-map {
  height: 432px;
  position: relative;
  z-index: 1;
}

//characterstics icons
.sprite_terrace {
  @include retina-sprite($sprite-terrasse-group);
}

.sprite_offers-dishes-homemade-meal-activity-specialties {
  @include retina-sprite(
    $sprite-offers-dishes-homemade-meal-activity-specialties-group
  );
}

.sprite_vrt-wifi {
  @include retina-sprite($sprite-wifi-group);
}

.sprite_vrt-parking {
  @include retina-sprite($sprite-parking-group);
}

.sprite_private-parking {
  @include retina-sprite($sprite-parking-group);
}

.sprite_vrt-tv {
  @include retina-sprite($sprite-tv-group);
}

.sprite_shelter-for-bikesmountain-bikes {
  @include retina-sprite($sprite-bike-group);
}

.sprite_vrt-velo {
  @include retina-sprite($sprite-bike-group);
}

.sprite_loan-of-bikes {
  @include retina-sprite($sprite-bike-group);
}

.sprite_lift {
  @include retina-sprite($sprite-elevator-group);
}

.sprite_vrt-restauration {
  @include retina-sprite($sprite-resto-group);
}

.sprite_swimming-pool {
  @include retina-sprite($sprite-piscine-group);
}

.sprite_mini-bar {
  @include retina-sprite($sprite-bar-group);
}

.sprite_pets-welcome {
  @include retina-sprite($sprite-pets-group);
}

.sprite_garden {
  @include retina-sprite($sprite-jardin-group);
}

.sprite_vrt-spa {
  @include retina-sprite($sprite-spa-group);
}

.sprite_breakfast {
  @include retina-sprite($sprite-breakfast-group);
}

.sprite_motorhomes-allowed {
  @include retina-sprite($sprite-caravan-group);
}

.sprite_caravan-hire {
  @include retina-sprite($sprite-caravan-group);
}

.sprite_mobile-home-hire {
  @include retina-sprite($sprite-caravan-group);
}

.sprite_bungalow-rental {
  @include retina-sprite($sprite-bungalow-group);
}

.sprite_tent-hire {
  @include retina-sprite($sprite-tent-group);
}

.sprite_groups-customer-types {
  @include retina-sprite($sprite-groups-group);
}

//to do
.sprite_boules-area {
  @include retina-sprite($sprite-petanque-group);
}

.sprite_vrt-linge {
  @include retina-sprite($sprite-drap-group);
}

.sprite_vrt-camping {
  @include retina-sprite($sprite-camping-group);
}

.sprite_air-conditioning {
  @include retina-sprite($sprite-clim-group);
}

//wihlist
#id_wishlist {
  main, .main-wrap {
    @include media-breakpoint-up(lg) {
      padding-top: 200px;
    }
  }

  .main__header {
    &.is__fixed {
      animation: none;
    }
  }

  .main__header,
  .wishlist-top {
    @include media-breakpoint-up(lg) {
      position: fixed;
      width: 100%;
    }
  }

  .wishlist-top {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: theme-color("dark");
    top: 100px;
    z-index: 1;

    h1 {
      color: theme-color("primary");
    }
  }
}

.wishlist-controls {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .btn {
    padding: 10px 15px;
  }

  a:not(:last-child) {
    margin-right: 1rem;
  }

  i {
    margin-right: 1rem;
  }
}

.wishlist-list {
  padding-top: 2rem;
  list-style-type: none;
  padding-left: 0;
  color: theme-color("text-color") !important;
  margin: 0;
  margin-bottom: 2rem;

  .result__item {
    @include make-col-ready();

    @include media-breakpoint-up(lg) {
      @include make-col(3);
      margin-bottom: 2rem;
    }

  }

  .figure-str {
    position: relative;
    margin-bottom: 20px;

    [data-wishlist-item-remove] {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .img-wrapper {
      position: relative;

      a {
        display: block;
      }

      &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        pointer-events: none;
        background-image: linear-gradient(
          180deg,
          rgba(86, 86, 86, 0.11) 0%,
          rgba(0, 0, 0, 0.2) 100%
        );
      }

      img {
        max-width: 100%;
        height: auto;
        box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.06);
      }
    }

    .figure-rating {
      position: absolute;
      bottom: 10px;
      left: 10px;

      i {
        color: theme-color("primary");
      }
    }

    .btn {
      padding: 0;
      color: theme-color("dark");
      text-transform: none;
      text-align: left;
    }

    &:hover {
      .wishlist-item__min-price {
        opacity: 1;
        visibility: visible;
      }
    }
    @include media-breakpoint-down(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background-color: #eee;
      margin-bottom: 1rem;
      .figure-content{
        padding: 1rem;
      }
    }
  }
}

.figure-name {
  font-size: 18px;
  font-family: $intro_bold;
  margin-top: 2rem;
}

.figure-min-price {
  display: none;
}

// .search-panel__map {
//   width: 40%;
//   position: fixed;
//   right: 0;
//   top: 0;
// }

.wishlist-list {
  @include media-breakpoint-up(lg) {
    width: 70%;
  }
}
.wishlist-top{
  @include media-breakpoint-down(sm) {
    text-align: center;
    .wishlist-controls{
      justify-content: center !important;
      margin-top: 1rem;
    }
  }
}
.wishlist-map {
  position: fixed;
  right: 0;
  top: 0px;
  @include media-breakpoint-up(lg) {
    width: 30%;
    padding-top: 194px;
  }

  @include media-breakpoint-down(md) {

    display: none;
  }

  #id_wishlist_map {
    height: calc(100vh - 200px);
  }
}


.sidr-class-languages{
  display: flex;
  justify-content: center;
  span:first-child{
    font-weight: bold;
    color: white;
  }
  .sidr-class-languages__list{
    display: inline-flex;
    gap: 1rem;
    margin-left: 20px;
    a{
      text-transform: uppercase;
      color: white;
      &:not(.sidr-class-active){
        opacity: .3;
      }
      &:not(:last-child){
        border-right: 1px solid grey;
        padding-right: 10px;
      }
      &.sidr-class-active{
        pointer-events: none;
      }
    }
  }
}

.blog-latest-entries, .events__list, .thematics__list__wrapper, .activities__slider{
  @include media-breakpoint-down(sm) {
    img{
      box-shadow: unset !important;
    }
    .event__image, .teaser{
      margin-bottom: 20px !important;
    }
    .slick-dots{
      margin-bottom: 10px;
      li button:before {
        font-size: 16px;
        color: theme-color('secondary');
      }
    }
  }
}

.activities__slider{
  [class^="col-"] {
    max-width: unset;
    flex: unset;
  }
}

.link-wrapper{
  @include media-breakpoint-down(sm) {
    text-align: center;
    padding-top: 2rem;
  }
}


@include media-breakpoint-down(sm) {
  .thematics-form{
    gap: 1rem;
    .cs-placeholder{
      margin-top: 0 !important;
    }
    >div{
      border: 1px solid darken(#eceae2, 6);
      background-color: darken(#eceae2, 3);
      padding: 1rem;
    }
    form div label{
      padding-top: 10px;
    }
    .cs-select {
      min-height: auto !important;
    }
  }
}


.other_activities{
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: theme-color('gray_light');
  .slick-slide{
    padding: 10px;
  }

}
