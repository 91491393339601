.main__header {
  font-family: $intro_medium;
  z-index: 1004;
  position: relative;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);

  &.white_bg {
    background-color: white;
  }

  @include media-breakpoint-down(md) {
    background: theme-color("dark") !important;
  }

  a {
    position: relative;
    z-index: 999;

    &:hover {
      color: theme-color("primary");
    }
  }

  &.hover {
    background-color: theme-color("gray") !important;
  }

  &.is__fixed {
    @include media-breakpoint-up(md) {
      background-color: theme-color("white");
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.06);
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
    }

    .main-logo {
      &::before {
        display: none;
      }
    }
  }

  a {
    display: block;
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 0 1rem;
  }
}

.menu-is-open {
  .main__header {
    z-index: 1004;
  }
}

#id_index,
#id_landing,
#id_blog,
.cms_content_banner {

  .main__header {
    @include media-breakpoint-up(sm) {
      position: fixed;
      left: 0;
      right: 0;
    }
    box-shadow: none;

    &:not(.is__fixed) {
      .navigation__list > li > a,
      .right__menu {
        color: white;

        a,
        span, button{
          color: white;
        }
        .groups-congres{
          text-transform: none;
          font-weight: bold;
        }
      }

      .sprite_groupes_gray, .sidr-class-sprite_groupes_gray {
        @include retina-sprite($sprite-groupes-group);
      }

      .sprite_heart_gray, .sidr-class-sprite_heart_gray {
        @include retina-sprite($sprite-heart-group);
      }

      .logo__hover {
        display: none;
      }
    }

    &.white_bg,
    &.white_bg.is__fixed {
      .sprite_groupes_gray, .sidr-class-sprite_groupes_gray {
        @include retina-sprite($sprite-groupes_gray-group);
      }

      .sprite_heart_gray, .sidr-class-sprite_heart_gray {
        @include retina-sprite($sprite-heart_gray-group);
      }

      .navigation__list > li > a,
      .right__menu {
        color: inherit;

        a,
        span {
          color: inherit;
        }
      }
    }

    &.is__fixed,
    &.white_bg {
      border-bottom: 1px solid #eee;

      .logo__hover {
        display: block;
      }

      .logo {
        display: none;
      }
    }
    &.is__fixed {
      top: 0;
    }
  }
}

.main-logo {
  position: relative;
  padding: 10px;
  height: 100px;
  display: flex;
  align-items: center;

  a {
    display: flex;
  }

  @include media-breakpoint-down(sm) {
    a {
      display: block;
      vertical-align: middle;
      position: relative;
    }
    img {
      max-width: 200px;
      height: auto;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}



.selected > a,
.ancestor > a {
  color: theme-color("primary") !important;
}

.submenu {
  @extend .list-unstyled;
  display: none;
}

.navigation {
  &__list {
    display: flex;
    margin-bottom: 0;

    > li > a {
      font-family: $intro_bold;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 19px;
      color: theme-color("text-color");
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: theme-color("primary");
        transform: translate3d(-100%, 0, 0) translate3d(-1px, 0, 0);
        transition: transform 0.7s, opacity 0.7s;
        transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
      }

      &:hover {
        &::before {
          transform: translate3d(100%, 0, 0) translate3d(1px, 0, 0);
          opacity: 0.8;
        }
      }
    }

    > li {
      &.hover {
        > a {
          color: theme-color("primary");
        }

        > .submenu__wrapper {
          background-color: rgba(theme-color("gray"), 0.99);
          position: fixed;
          left: 0;
          right: 0;
          top: 100px;
        }

        > .submenu__wrapper > .submenu {
          @include make-container();
          @include container-widths();
          display: flex !important;
          padding: 20px;
          flex-wrap: wrap;
          justify-content: center;

          > li {
            flex: 0 1 20%;

            > a {
              color: theme-color("primary");
              font-family: $bukhari;
              font-size: 20px;
            }

            &.link_border {
              padding: 10px;

              a {
                display: block;
                font-family: $intro_medium;
                border: 1px solid theme-color("primary");
                text-align: center;
              }
            }

            .submenu {
              li {
                transition: all 0.3s linear;

                &:hover {
                  transform: translateX(5px);
                }

                a {
                  &::before {
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: "\f105";
                    margin-right: 5px;
                  }
                }
              }
            }
          }

          .submenu {
            display: block;
            font-family: $intro_regular;

            a {
              color: $white;
              padding: 0 10px;
              line-height: 20px;
              font-size: 15px;

              &:hover {
                color: theme-color("primary");
              }
            }
          }
        }
      }
    }
  }
}

.right__menu{
  display: flex;
  list-style-type: none;
  color: $white;
  text-transform: uppercase;
  align-items: flex-end;
  justify-content: center;
  padding-left: 0;
  margin-bottom: 0;
  color: theme-color("text-color");
  font-family: $intro_regular;
  .dropdown-item {
    color: theme-color("text-color") !important;
  }

  span {
    position: relative;
  }
  .groups-congres{
    text-transform: none;
    font-weight: bold;
    padding: 0.8rem 0.1rem;
  }

  > li {
    > a,
    > span {
      color: theme-color("text-color");
      margin-left: 15px;
      margin-right: 15px;
    }

    &:first-child {
      a {
        margin-left: 0;
      }
    }

    &:last-child {
      a {
        margin-right: 0;
      }
    }
    .dropdown-toggle::after {
      margin-left: -10px;
    }
    &.dropdown {
      .dropdown-menu {
        border-top: 3px solid theme-color("primary");
        font-size: 16px;
        text-transform: none;

        li {
          padding: 1rem;
        }

        a {
          font-family: $intro_medium;

          &:hover {
            color: theme-color("primary") !important;
          }
        }
      }
    }
  }

  .lang {
    font-size: 22px;
    margin-left: 10px;
    display: block;
    font-family: $intro_medium;
  }
  .btn--search{
    background-color: transparent;
    border: 0;
    color: theme-color("text-color");
    padding: 0.5rem 3.5rem;
    font-size: 20px;
  }
}

.sidr-class-right__menu{
  display: flex;
  gap: 3rem;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid darken(grey, 20);
  margin-bottom: 0;
  align-items: center;
  li{
    position: relative;
    display: flex;
    a{
      color: white;
      display: flex;
      justify-content: center;
      width: 40px;
    }
    &:last-child{
      margin-left: auto;
    }
    &:nth-child(2){
      display: none;
    }
  }
  .sidr-class-dropdown-menu{
    display: none;
  }
  .sidr-class-icn{
    display: block;
  }
  .sidr-class-count{
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 12px;
    background-color: theme-color('primary');
    color: white;
    display: inline-flex;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
}
.sidr-class-right__menu button {
  color: white;
}

.wrp {
  //background: rgba(255,255,255,0.1);
  transition: transform ease-out 0.7s, background 0.7s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 33px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 1;

  &::after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: "";
    box-sizing: content-box;
    top: 0;
    left: 0;
    padding: 0;
    z-index: -1;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
    opacity: 0;
    transform: scale(0.9);
  }

  &:hover {
    transform: scale(0.73);
    background: rgba(255, 255, 255, 0.05);

    &::after {
      animation: sonarEffect 1.3s ease-out 75ms;
    }
  }
}

@keyframes sonarEffect {
  0% {
    opacity: 0.3;
  }

  40% {
    opacity: 0.5;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1),
      0 0 10px 10px rgba(theme-color("primary"), 0.3),
      0 0 0 10px rgba(255, 255, 255, 0.15);
  }

  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1),
      0 0 10px 10px rgba(theme-color("primary"), 0.3),
      0 0 0 10px rgba(255, 255, 255, 0.15);
    transform: scale(1.5);
    opacity: 0;
  }
}

.burgermenu, .hamburger_wrapper {
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

@keyframes translate {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}


.sidr{
  background-color: theme-color("gray");
  top: 100px;
  padding: 10px;
  ul {
    @extend .list-unstyled;
  }
}

@include media-breakpoint-down(md) {
  .navigation{
    display: none;
  }
  .main__header{
    nav{
      display: flex;
      flex-wrap: wrap;
      padding:0;
      .main-logo{
        flex:1;
      }
      .hamburger_wrapper{
        max-width:20%;
        flex:1;
        display:flex;
        justify-content: flex-end;
        padding-right:10px;
      }
      .right_nav{
        display: none;
      }
    }
  }
}
.sidr-open{

  overflow: hidden;

  .sidr{
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100% !important;

  }

}

.sidr-class-navigation__list {
  padding: 10px;
  padding-top: 0;
  border-bottom: 1px solid darken(grey, 20);
  margin-bottom: 0;
  > li {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-top: 1rem;

    > a {
      color: theme-color("primary");
      font-family: $bukhari;
      font-size: 2rem;
      display: flex;
      letter-spacing: 1px;
      align-items: center;
      text-transform: lowercase;
      position: relative;

      &::after{

        font-family: "Font Awesome 5 Free" !important;
        font-weight: 900;

        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        content: "\f054";
        font-size: 14px;
        position: absolute;
        pointer-events: none;
        right: 0;
        top: 50%;
      }


      &.is_open{

        &::after{

          transform: rotate(90deg);
        }
      }
    }
  }

  .sidr-class-submenu{
    li{
      border-top: 0;
      position: relative;
      padding-left: 20px;
      &:not(:last-child){

        border-bottom: 1px dashed lighten(#222, 12);
      }
      &::before{
        font-family: "Font Awesome 5 Free" !important;
        font-weight: 900;
        color: white;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        content: "\f054";
        font-size: 10px;
        position: absolute;
        pointer-events: none;
        left: 0;
        top: 16px;
      }
    }
  }

  ul {
    margin-top: 2rem;
    padding-left: 20px;

    li{
      border-top: 1px solid darken(grey, 20);
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    a {
      color: white;
      font-size: 2rem;
    }
  }

  ul ul {
    display: none;
  }
}

// .weather, .sidr-class-weather{
//   .darksky-small {
//     .darksky-humidity,
//     .darksky-wind {
//       display: none;
//     }
//   }
// }

// .darksky-small {
//   display: flex;
//   align-items: center;
//   margin-right: 10px;
//   height: 33px;
// }

// .darksky-temperature {
//   font-size: 20px;
//   font-family: $intro_medium;
// }

// .darksky-icon {
//   font-size: 25px;
//   display: inline-flex;
//   font-family: $darksky;
//   font-style: normal;
//   font-weight: normal;
//   line-height: 1;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   margin-right: 10px;
// }

// .darksky {
//   &-clear-day::before {
//     content: "\f00d";
//   }

//   &-clear-night::before {
//     content: "\f02e";
//   }

//   &-rain::before {
//     content: "\f019";
//   }

//   &-snow::before {
//     content: "\f01b";
//   }

//   &-sleet::before {
//     content: "\f0b5";
//   }

//   &-wind::before {
//     content: "\f021";
//   }

//   &-fog::before {
//     content: "\f014";
//   }

//   &-cloudy::before {
//     content: "\f013";
//   }

//   &-partly-cloudy-day::before {
//     content: "\f002";
//   }

//   &-partly-cloudy-night::before {
//     content: "\f086";
//   }
// }

.sidr-class-fas{
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 20px;
}
.sidr-class-btn--search{
  background-color: transparent;
  border: 0;
}
.sidr-class-fa-search:before{
  content: "\f002";
  font-style: normal;
}

.sidr-class-sprite_groupes_gray{
  background-image: url(../img/sprite.png);
  background-position: 0px -212px;
  width: 38px;
  height: 24px;
}
.sidr-class-sprite_heart_gray{
  background-image: url(../img/sprite.png);
  background-position: 0px -236px;
  width: 27px;
  height: 22px;
}

.sidr-class-language__chooser{
  display: none;
}

#widget-panier .PanierVide .EnteteNbArticles {
  display: none;
}

body#id_landing,
body#id_index {
  #widget-panier .entete {
    // white basket icon
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='87' height='84' viewBox='-0.436 -0.021 87 84' fill='%23fff'%3E%3Cpath d='M83.35 31.598H60.359V21.081c.293-1.243 0-2.532 0-3.862C60.359 7.709 52.734 0 42.947 0c-9.788 0-17.485 7.709-17.485 17.219 0 1.177-.231 2.325 0 3.435v10.944H2.474c-.731 0-1.401.304-1.89.854-.487.548-.67 1.28-.548 2.011l8.166 47.361c.183 1.219 1.219 2.072 2.438 2.072h64.483c1.219 0 2.254-.854 2.438-2.072l8.166-46.935c.061-.244.121-.487.121-.792a2.47 2.47 0 00-2.498-2.499zM32.08 30.8V17.079c.389-5.508 5.1-9.863 10.867-9.863 5.44 0 9.938 3.875 10.758 8.938v15.443H32.08V30.8zm39.91 47.097H13.773L6.399 37.533h19.063V46.5h.003a3.302 3.302 0 006.604 0h.012v-8.967h21.625V46.5h.012a3.32 3.32 0 006.638 0h.004v-.032l.002-.02-.002-.02v-8.896h19.004L71.99 77.897z'/%3E%3C/svg%3E") !important;
  }
}

@include media-breakpoint-down(sm) {
  #widget-panier .entete {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='87' height='84' viewBox='-0.436 -0.021 87 84' fill='%236d7783'%3E%3Cpath d='M83.35 31.598H60.359V21.081c.293-1.243 0-2.532 0-3.862C60.359 7.709 52.734 0 42.947 0c-9.788 0-17.485 7.709-17.485 17.219 0 1.177-.231 2.325 0 3.435v10.944H2.474c-.731 0-1.401.304-1.89.854-.487.548-.67 1.28-.548 2.011l8.166 47.361c.183 1.219 1.219 2.072 2.438 2.072h64.483c1.219 0 2.254-.854 2.438-2.072l8.166-46.935c.061-.244.121-.487.121-.792a2.47 2.47 0 00-2.498-2.499zM32.08 30.8V17.079c.389-5.508 5.1-9.863 10.867-9.863 5.44 0 9.938 3.875 10.758 8.938v15.443H32.08V30.8zm39.91 47.097H13.773L6.399 37.533h19.063V46.5h.003a3.302 3.302 0 006.604 0h.012v-8.967h21.625V46.5h.012a3.32 3.32 0 006.638 0h.004v-.032l.002-.02-.002-.02v-8.896h19.004L71.99 77.897z'/%3E%3C/svg%3E") !important;
  }
}


#id_activity_results_list,
#id_activity_detail {
  &.scroll__margin{
    margin: 0 !important;
  }
  .main__header {
    position: unset;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;

  }

}

.sidr-class-fab{
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  &.sidr-class-fa-facebook-f:before {
    content: "\f39e";
  }
  &.sidr-class-fa-twitter:before {
    content: "\f099";
  }
  &.sidr-class-fa-youtube:before {
    content: "\f167";
  }
  &.sidr-class-fa-instagram:before {
    content: "\f16d";
  }
  &.sidr-class-fa-linkedin:before {
    content: "\f08c";
  }
}

.sidr-class-social__links{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding-top: 10px;
  a{
    color: theme-color('primary');
    border: 1px solid theme-color('primary');
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    i{
      font-size: 20px;
    }
  }
}

.mobile_topnav, .language__mobile, .group_mobile{
  display: none;
}


.sidr-class-search__form{
  display: grid;
  grid-template-columns: 70% 30%;
  input{
    padding: 1rem;
    border: 0;
    &[type="submit"]{
      background-color: theme-color('primary');
      color: white;
    }
  }
}

.sidr-class-group_mobile--ul{
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  gap: 1rem;
  justify-content: center;
  border-bottom: 1px solid darken(grey, 20);
  li{
    padding-left: 10px;
    padding-right: 10px;
  }
  a{
    color: white;
    font-weight: bold;
  }
}


.sidr-class-search__btn--mobile{
  background-color: transparent;
  border: 0;
}


.modal__mobile{
  right: 0;
  bottom: 0;
  width: 100%;

  z-index: 999999999;
  background-color: rgba(theme-color('third'), .9);
  &.show{
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  h2{
    font-size: 18px;
    color: theme-color('third');
  }
  .modal-content{
    border: 0;
  }
  form{
    display: flex;
    input{
      border-radius: 0;
    }
    .search__input{
      border: 1px solid #ddd;
      padding-left: 10px;
      border-right:0;
    }
    [type="submit"]{
      background-color: theme-color('primary');
      padding: 10px;
      font-family: $intro_bold;
      color: white;
      border: 1px solid theme-color('primary');
    }
  }
}
