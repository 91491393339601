.app-blog {
  @include make-container();
  display: flex;
  max-width: 1140px;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  header {
    h2 {
      font-size: 28px;
      color: theme-color("blue");
    }
  }

  .blog-back {
    display: block;
    padding-top: 3rem;
    border-top: 1px solid #eee;

    a {
      @extend .button;
    }
  }

  .blog-list {
    @include make-row();

    @include media-breakpoint-up(md) {
      max-width: 80%;
    }

    @include media-breakpoint-down(md) {
      flex: 1;
      min-width: 100%;
    }

    .post-item {
      h3 {
        font-size: 26px;
      }
    }
  }

  .blog-sidebar {
    @include media-breakpoint-up(md) {
      max-width: 30%;
      min-width: 30%;
    }

    padding-left: 40px;

    .blog-categories,
    .blog-tags,
    .blog-archive {
      margin-bottom: 30px;
      width: 100%;
    }

    .blog-archive {
      li {
        ul li {
          padding-left: 10px;
          border-left: 1px solid darken(white, 10);
        }
      }
    }

    .blog-categories {
      li {
        border-bottom: 1px dashed darken(white, 10);

        a {
          display: flex;
          justify-content: space-between;
        }

        span {
          display: block;
          font-size: 12px;
        }
      }
    }

    h3 {
      font-size: 20px;
      color: theme-color("primary");
    }

    a {
      color: theme-color("text-color");
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        padding: 5px 0px;

        span {
          display: none;
        }

        &:last-child {
          border-bottom: 0;
        }
      }

      ul {
        padding-left: 20px;
      }
    }
  }

  header,
  .blog-back {
    @include make-col-ready();
    @include make-col(12);
  }

  .post-item {
    @include make-col-ready();

    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }

    margin-bottom: 30px;

    .blog-visual {
      img {
        max-width: 100%;
        min-width: 100%;
      }

      > a {
        display: block;
        position: relative;
      }
    }

    .blog-lead {
      position: relative;
      padding: 2rem;
      min-width: 50%;

      .post__author {
        margin-top: -50px;

        img {
          display: block;
          border-radius: 50%;
          width: 60px;
          height: 60px;
        }
      }
    }

    .article-wrapper {
      margin-bottom: 2rem;
      background: $white;
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.06);
      height: 100%;
    }
  }
}

.post-detail-list {
  display: flex;
  @include make-row();

  .post-item {
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
      @include make-col(4);

      h3 {
        font-size: 20px;
      }

      .article-wrapper {
        background-color: $white;
        height: 100%;
        display: flex;
        flex-direction: column;

        .blog-lead {
          padding: 2rem;
          flex: 1;
          border: 1px solid #eee;
        }
      }
    }
  }
}

.post-item {
  transition: all 0.3s ease;

  h3 {
    transition: all 0.3s ease;

    a {
      color: inherit;
    }
  }

  &:hover {
    h3 {
      color: theme-color("primary");
    }
  }

  .article-wrapper {
    width: 100%;
  }
}

.post-detail {
  margin-bottom: 30px;
}

.blog_header {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;

  text-align: center;
  background-color: #555;
  background-blend-mode: overlay;
  background-position: center center;

  @include media-breakpoint-up(md) {
    align-items: center;
    min-height: 600px;
  }

  @include media-breakpoint-down(md) {
    min-height: 200px;
    position: relative;
    align-items: center;

    > div {
      display: flex !important;
      min-height: 100% !important;
      align-items: center;
    }

    .breadcrumb_blog {
      background-color: rgba(theme-color("dark"), 0.5);
      margin-bottom: 10px;
    }
  }

  h1 {
    color: theme-color("white");
    font-family: $bukhari;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 4px 11px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;

    @include media-breakpoint-down(md) {
      @include font-size(2.5);
    }
  }

  .breadcrumb {
    justify-content: center;

    a {
      color: theme-color("primary");
    }
  }

  .post__author {
    img {
      border-radius: 50%;
      width: 60px;
      height: 60px;
      border: 2px solid #fff;
    }
  }
}

.blog-latest-entries {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 1rem;
  article {
    margin-bottom: 10px;
    display: flex;

    @include media-breakpoint-up(md) {

      margin-bottom: 30px;
    }

    @include media-breakpoint-only(md) {
      margin-right: 10px;

      &:nth-child(even) {
        margin-right: 0;
      }
    }


    .blog-visual {
      position: relative;
      margin-bottom: 1rem;
      a {
        display: block;
      }

      img {
        box-shadow: 1px 2px 89px 0 rgba(black, 0.2);
        border-radius: 10px;
      }
    }

    .blog-lead {
      padding: 1rem;
      position: relative;

      .post__author {
        position: absolute;
        border-radius: 50%;
        display: flex;
        top: -25px;
        left: 10px;

        img {
          border-radius: 50% !important;
          width: 50px;
          height: 50px;
          border: 1px solid #fff;
        }
      }

      h3 {
        font-size: 20px;

        a {
          color: inherit;
        }
      }
    }

    .read-more {
      text-align: right;

      a {
        color: theme-color("text-color");

        &:hover {
          color: theme-color("blue");
        }
      }
    }
  }
  @include media-breakpoint-down(sm){
    display: block;
  }

}

.post__detail {
  .post-detail {
    margin-top: 10px;
    margin-bottom: 10px;
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    .post__author {
      img {
        border-radius: 50%;
        width: 50px;
        height: auto;
      }
    }

    li {
      margin-right: 1rem;
    }
  }
}

.blog {
  &__tags {
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;

    li {
      float: left;
    }

    .tag {
      background: #eee;
      border-radius: 3px 0 0 3px;
      color: #999;
      display: inline-block;
      height: 26px;
      line-height: 26px;
      padding: 0 20px 0 23px;
      position: relative;
      margin: 0 5px 0px 0;
      text-decoration: none;
      transition: color 0.2s;

      a {
        color: inherit;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
      }

      &::before {
        background: #fff;
        border-radius: 10px;
        box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
        height: 6px;
        left: 10px;
        width: 6px;
        top: 10px;
      }

      &::after {
        background: #fff;
        border-bottom: 13px solid transparent;
        border-left: 10px solid #eee;
        border-top: 13px solid transparent;

        right: 0;
        top: 0;
      }

      &:hover {
        background-color: theme-color("primary");
        color: theme-color("gray");

        &::after {
          border-left-color: theme-color("primary");
        }
      }
    }
  }
}
