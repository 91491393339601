.img-thumbnail-wrapper {
  display: block;
  position: relative;
  margin-bottom: 10px;
}

.photo-zoom-button {
  position: absolute;
  top: 0;
  display: block;
  padding: .2em .5em;
  background-color: $white;
}

.img-author-icon {
  @extend .fas;
  font-size: 12px;
  bottom: 0;
  color: $white;
  cursor: pointer;
  padding: .5em;
  position: absolute;
  right: 0;
  text-shadow: 0 0 5px rgba(0, 0, 0, .5);
  z-index: 999999;

  &::before {
    content: fa-content($fa-var-info-circle);
  }

  &:hover,
  &:focus {
    color: theme-color('red');
  }
}

.folder-plugin {
  .file-plugin {
    &:nth-child(2) {
      .img-author-icon {
        left: 0;
        right: auto;
      }
    }
    &:nth-child(3) {
      .img-author-icon {
        top: 0;
        bottom: auto;
      }
    }
    &:nth-child(4) {
      .img-author-icon {
        left: 0;
        right: auto;
        top: 0;
        bottom: auto;
      }
    }
  }
}
