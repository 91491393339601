@font-face {
  font-family: "AvenirNextLTPro-Demi";
  src: url("../fonts/avenir/avenirDemi.woff2") format("woff2"),
    url("../fonts/avenir/avenirDemi.woff") format("woff"),
    url("../fonts/avenir/avenirDemi.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

$avenir_medium: "AvenirNextLTPro-Demi",
"Helvetica Neue",
Arial,
sans-serif;

@font-face {
  font-family: "AvenirNextLTPro-Regular";
  src: url("../fonts/avenir/avenirRegular.woff2") format("woff2"),
    url("../fonts/avenir/avenirRegular.woff") format("woff"),
    url("../fonts/avenir/avenirRegular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

$avenir_regular: "AvenirNextLTPro-Regular",
"Helvetica Neue",
Arial,
sans-serif;
;


@font-face {
  font-family: 'introregular';
  src: url('../fonts/intro/intro_regular-webfont.woff2') format('woff2'),
    url('../fonts/intro/intro_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

$intro_regular : "introregular",
"Helvetica Neue",
Arial,
sans-serif;


@font-face {
  font-family: 'introbold';
  src: url('../fonts/intro/intro_bold-webfont.woff2') format('woff2'),
    url('../fonts/intro/intro_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

$intro_bold : "introbold",
"Helvetica Neue",
Arial,
sans-serif;

@font-face {
  font-family: 'introblack';
  src: url('../fonts/intro/intro_black-webfont.woff2') format('woff2'),
    url('../fonts/intro/intro_black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

$intro_black : "introblack",
"Helvetica Neue",
Arial,
sans-serif;


@font-face {
  font-family: 'intromedium';
  src: url('../fonts/intro/intro_medium-webfont.woff2') format('woff2'),
    url('../fonts/intro/intro_medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

$intro_medium : "intromedium",
"Helvetica Neue",
Arial,
sans-serif;


@font-face {
  font-family: "BukhariScript-Regular";
  src: url("../fonts/bukhari/bukhari.woff2") format("woff2"),
    url("../fonts/bukhari/bukhari.woff") format("woff"),
    url("../fonts/bukhari/bukhari.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

$bukhari: "BukhariScript-Regular",
"Helvetica Neue",
Arial,
sans-serif;


@font-face {
  font-family: 'weathericons';
  src: url('../fonts/weather/weathericons-regular-webfont.eot');
  src: url('../fonts/weather/weathericons-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/weather/weathericons-regular-webfont.woff2') format('woff2'),
    url('../fonts/weather/weathericons-regular-webfont.woff') format('woff'),
    url('../fonts/weather/weathericons-regular-webfont.ttf') format('truetype'),
    url('../fonts/weather/weathericons-regular-webfont.svg#weather_iconsregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

// $darksky : "weathericons";

@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) * 1px;
  font-size: $sizeValue * 1rem;
}

h1,
h2,
h3
 {
  letter-spacing: 1px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $intro_bold;
  color: theme-color("gray");
  letter-spacing: 1px;
}

h1 {
  @include font-size(4.2);
  @include media-breakpoint-down(sm) {
    @include font-size(3);
  }
}

h2 {
  @include font-size(3.8);

  @include media-breakpoint-down(sm) {
    @include font-size(2.8);
  }
}

h3 {
  @include font-size(3);
  letter-spacing: 0;
}

h4 {
  @include font-size(2.6);
}

h5 {
  @include font-size(2.2);
}

h6 {
  @include font-size(1.8);
}

p.intro {
  font-size: 16px;
  font-family: $intro_medium;
}


