#id_activity_results_list .footer {
  z-index: 9999;
}

.scroll__margin {
  @include media-breakpoint-up(sm) {
    margin-top: 100px;

  }

  &.scroll__largemargin {
    @include media-breakpoint-up(sm) {
      margin-top: 148px;
    }
  }
}

.catalog_header {
  background-color: #8ec4d0;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  @include media-breakpoint-down(md) {
    background-color: white;
    .row{
      .col-md-12:last-child{
        display: none !important;
      }
      .breadcrumb{
        justify-content: flex-start;
      }
    }
  }

  .breadcrumb {
    justify-content: center;
  }

  h1 {
    font-family: $bukhari;
    color: #ffffff;
    margin-top: 2rem;
  }
  p {
    color: white;
    font-size: 18px;
  }
  .multicolumn-wrapper {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.map__switcher {
  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
  }

  @include media-breakpoint-up(md) {
    margin-left: auto;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }

  .divider {
    display: inline-block;
    width: 2px;
    height: 30px;
    background-color: theme-color("primary");
    margin-left: 5px;
    margin-right: 5px;
  }
}

.filters_mobile {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.map__switcher .icn {
  margin: 0.2rem;
  height: 30px;
  min-width: 30px;
  background-color: transparent;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid theme-color("primary");
  color: theme-color("primary");
  cursor: pointer;
  transition: all 0.3s ease;

  &.active {
    background-color: $dark;
    color: theme-color("white");
    border-color: theme-color("primary");
    background-color: theme-color("primary");
  }

  @include media-breakpoint-down(md) {
    &.list_map__js {
      display: none !important;
    }
  }
}

.catalog {
  display: flex;
  min-height: 100vh;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  transition: all 0.3s ease;
  &.is__fixed {


    .filters {
      width: 100%;
      top: 0px;
      height: 60px;
      #id_criteria_form{

        flex-wrap: nowrap;
      }
      .map__switcher{
        display: none;
      }
      .right-side{
        min-width: auto;
      }
      .advanced_filters{
        span{
          display: none;
        }
      }

    }

    .map {
      right: 0;
      top: 60px;
      width: 35%;
      position: fixed;
    }
  }

  .result__count {
    .count {
      font-weight: bold;
    }
  }

  .pagination-container {
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 99;
    height: 50px;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    border-top: 1px solid #eee;
    padding: 5px 10px;
    width: 100%;

    ul {
      margin-bottom: 0;
    }
  }

  #id_advanced_filter_btn {
    height: 32px;
    padding: 5px;
    color: theme-color("gray");
    text-transform: capitalize;
    @include media-breakpoint-down(sm) {
      height: auto;
      padding: 10px;
    }
  }

  .advanced_filters {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .advanced_filters_mobile{
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .right-side {
    @include media-breakpoint-up(sm) {
      margin-left: auto;

    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      display: block !important;
      padding: 0;
      a{
        display: block;
        button{
          width: 100%;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      min-width: 260px;
      background-color: darken(#414242, 5);
    }

    height: 100%;
    padding-right: 10px;
    padding-left: 10px;
    justify-content: center;
    align-items: center;
  }
  @include media-breakpoint-up(sm) {

  }
  .filters {
    min-width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0rem;
    align-items: center;
    position: sticky;
    z-index: 1003;
    flex-wrap: wrap;
    @include media-breakpoint-up(sm) {
      background:#333;
      color: theme-color('yellow');
      .select2-selection__placeholder{
        color: theme-color('yellow') !important;
      }
      .select2-container--default .select2-selection--single .select2-selection__arrow b{
        border-top-color: theme-color('yellow') !important;;
      }

    }
    .search__by__name{
      display: flex;
      .btn{
        border:0;
      }
      >*{
        height: 40px;
      }
    }

    form {
      @include media-breakpoint-up(sm) {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
      }
      .badge {
        font-size: 8px !important;
        border-radius: 10px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: black;
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;
        display: none;
        position: absolute;
        background: #222;
        top: 49px;
        width: 100%;

        &.is-visible {
          display: block;
        }
        .select2-selection__placeholder{
          font-weight: bold;
        }
      }

      .daterangepicker-input {
        width: 244px;
      }
      @include media-breakpoint-down(sm) {
        .daterangepicker-input::placeholder, .daterangepicker-input{
          color: white !important;
        }
      }
      @include media-breakpoint-up(sm) {
        .daterangepicker-input::placeholder{
          color: #fdc600 !important;
        }
      }

      .dropdown-toggle::after {
        vertical-align: 1px;
      }

      @include media-breakpoint-down(sm) {
        >div:not(:last-child){
          border-top: 1px solid lighten(#222, 10);
          border-bottom: 1px solid darken(#222, 4);
          label{
            color: white;
          }
          .dropdown-toggle, >label{
            color: white;
            font-weight: bold;
          }
        }
      }
    }

    .daterangepicker-input {
      color: theme-color("primary");
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: theme-color("primary");
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: theme-color("primary");
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      color: theme-color("primary");
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: theme-color("primary");
    }

    .select2-container {
      min-width: 200px;
    }

    .select2-selection__rendered{
      padding-left: 0 !important;
    }

    .select2-container--default .select2-selection--single {
      border: 0;
      border-radius: unset;
      background-color: unset;

      .select2-selection__rendered,
      .select2-selection__placeholder {
        color: white;
      }

      .select2-selection__arrow b {
        border-top-color: white;
      }
    }

    .filter-item {
      padding: 1rem;
      @include media-breakpoint-down(sm) {
        width: 100%;
        &.date__picker{
          padding-top: 3px;
          padding-bottom: 3px;
        }
        .selection, .select2{
          width: 100% !important;
          display: block;
          padding: 0;
        }
        button[type="submit"]{
          text-transform: lowercase;
          width: 39px;
        }
      }

      .btn{
          padding: .5rem;
      }

      label {
        cursor: pointer;
      }
    }

    .geolocate{
      @include media-breakpoint-up(lg) {
        display: none !important;
      }
    }

    .dropdown-toggle {
      color: theme-color("primary");
      display: block;
      width: 100%;
      position: relative;

      > label {
        margin: 0;
      }
      &::after{
        @include media-breakpoint-down(sm) {

          margin-left: auto;
          position: absolute;
          right: 10px;
          top: 22px;
        }
      }
    }

    .dropdown-menu {
      ul {
        margin-bottom: 0;
      }
    }

    .dropdown,
    .div {
      &:hover {
        background-color: lighten(#414242, 10);
      }
    }
  }

  .dropdown,
  .div {
    border-right: 1px solid darken(#414242, 5);
    border-left: 1px solid lighten(#414242, 5);
    display: flex;
    align-items: center;
    color: theme-color("primary");

    &:first-child {
      border-left: 0;
    }

    input[type="text"] {
      @include media-breakpoint-up(sm) {
        border: 0;
      }
      @include media-breakpoint-down(sm) {
        height: 40px;
        border: 0;
      }

      &::placeholder{
        color: black;
      }
    }

    > .dropdown-toggle,
    > a {
      display: block;
      cursor: pointer;
      position: relative;

      .badge {
        position: absolute;
        right: 15px;
        top: 0px;
        width: 16px;
        height: 16px;
        background-color: white;
      }
    }

    ul {
      list-style-type: none;
      padding: 1rem;
      min-width: 270px;

      a {
        color: theme-color("dark");
      }
    }

    > label {
      color: theme-color("primary");
      margin-bottom: 0;
    }

    &:last-child {
      border-right: 0;
    }
    @include media-breakpoint-down(sm) {
      position: relative;
      .dropdown-menu{
        position: unset !important;
        transform: unset !important;
        min-width: 100%;
        background-color: darken(#222, 6);
        ul{
          overflow-y: scroll;
          max-height: 300px;
        }
        li{
          a{
            color: white;
          }
        }
      }
      &.show{
        flex-direction: column;
      }
      .select2-container--default .select2-selection--single .select2-selection__arrow{
        right: -6px;
      }
    }
  }

  .map {
    background-color: #eee;
    height: 100vh;
    flex: 1 1 35%;
    max-width: 35%;
    width: 35%;
    transition: all 0.3s ease;
    position: relative;
    z-index: 1;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  #id_map {
    height: 100%;

    .leaflet-popup-content {
      margin: 0;
    }

    .leaflet-popup-content-wrapper {
      border-radius: 0;
    }
  }

  .results-list-wrapper {
    flex: 1 1 65%;
    transition: all 0.3s ease;

    @include media-breakpoint-up(md) {
      width: 65%;
      max-width: 65%;
    }
    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 100%;
    }
    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }
  }

  .results-header {
    display: flex;
    width: 100%;
    padding: 10px 20px;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
  }

  .widget {
    display: block;
    font-family: $intro_bold;
    color: theme-color("text-color") !important;
    min-width: 270px;

    &__img {
      position: relative;
      display: block;
      min-width: 270px;

      &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-image: linear-gradient(
          180deg,
          rgba(86, 86, 86, 0.11) 0%,
          rgba(0, 0, 0, 0.2) 100%
        );
      }
    }

    &__content {
      padding: 1rem;

      .name {
        font-size: 18px;

        a {
          color: theme-color("text-color");
        }
      }

      .locality {
        color: theme-color("blue");
      }

      .type {
        font-size: 15px;
        font-family: $intro_regular;
      }
    }
  }

  .results-wrapper {
    display: grid;

    flex-wrap: wrap;
    padding: 2rem 0rem;
    @include media-breakpoint-up(lg) {
      gap:1rem;
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(4, 1fr);
    }
    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
      padding-top: 0;
    }
    .result {
      padding: 10px;
      font-family: $intro_bold;
      position: relative;
      margin-bottom: 30px;
      border:1px solid #eee;
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.06);
      // @include media-breakpoint-up(lg) {
      //   flex: 0 1 30%;
      // }

      // @include media-breakpoint-up(xl) {
      //   flex: 0 1 24%;
      // }

      // @include media-breakpoint-down(md) {
      //   flex: 0 1 50%;
      // }

      @include media-breakpoint-only(xs) {
        flex: 0 1 100%;
        display:grid;
        grid-template-columns: 1fr 1fr;
        align-items:center;
        .list-item-content{
          padding:1rem;
          padding-top: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .wishlist-controls-wrapper{
          right: calc(50% + 10px) !important;
          background-color: theme-color('gray');
          display: inline-flex;
          width:40px;
          height:40px;
          justify-content:center;
          align-items:center;
          border-radius:20px;
          background: #ededed;
          a{
            display: flex;
            justify-content:center;
            align-items:center;
            height: 100%;
            width: 100%;

          }
          i{
            font-size:unset !important;
          }
        }
      }


      &__photo {
        position: relative;

        &::after {
          position: absolute;
          content: "";
          height: 100%;
          width: 100%;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background-image: linear-gradient(
            180deg,
            rgba(86, 86, 86, 0.11) 0%,
            rgba(0, 0, 0, 0.2) 100%
          );
        }

        img.img-fluid {
          max-width: 100%;
          min-width: 100%;
          box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.06);
        }

        .rating {
          position: absolute;
          bottom: 10px;
          left: 10px;
          z-index: 1;

          i {
            font-size: 12px;
          }
        }

        .price {
          position: absolute;
          bottom: -20px;
          z-index: 1;
          right: 20px;
          background: #ffffff;
          box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.06);
          border-radius: 5px;
          padding: 1rem;
          font-size: 13px;

          &:hover {
            color: theme-color("primary");
          }
        }
      }

      .badge-member {
        position: absolute;
        top: 20px;
        left: 20px;
        color: theme-color("primary");
      }

      .toqué-du-local-member {
        position: absolute;
        bottom: 7px;
        left: 7px;
        @include media-breakpoint-up(md) {
          bottom: 13px;
          left: 15px;
        }
        max-width: 30px;
        transition: filter 0.5s;
        z-index: 1;
      }

      .toqué-du-local-member:hover {
        filter: drop-shadow(0 0 0.75rem white);
        transition: filter 0.2s;
      }

      .list-item-content {
        padding-top: 2rem;
      }

      .name {
        font-size: 18px;

        a {
          color: theme-color("text-color");
        }
      }

      .type {
        font-size: 15px;
        font-family: $intro_regular;
      }

      .locality {
        color: theme-color("blue");
      }

      .date {
        color: theme-color("primary");
      }

      .wishlist-controls-wrapper {
        position: absolute;
        top: 20px;
        right: 20px;

        i {
          font-size: 20px;
        }

        a {
          color: $white;
        }
      }

      &:hover {
        .price {
          color: theme-color("primary");
        }
      }
    }
  }

  &.map__mode {
    .map {
      min-width: 100%;
      max-width: 100%;
    }

    .results-list-wrapper {
      display: none;
    }
  }

  &.list__mode {
    .map {
      display: none;
    }

    .results-list-wrapper {
      min-width: 100%;
      max-width: 100%;

      .result {
        @include media-breakpoint-up(lg) {
          flex: 0 1 25%;
        }

        @include media-breakpoint-up(xl) {
          flex: 0 1 20%;
        }

        @include media-breakpoint-down(md) {
          flex: 0 1 50%;
        }
      }
    }
  }
}

.show {
  #id_selection__in,
  #id_services__in {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
      min-width: 600px;

      li {
        min-width: 50%;
        max-width: 50%;
      }
    }
  }
}

.loading {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(theme-color("dark"), 0.9);
  z-index: 99999;
  color: white;

  svg {
    transform: scale(1.6);
  }
}

.advanced_modal {
  .modal-header {
    text-transform: uppercase;

    .close{
      font-size: 5rem;
      font-weight: 300;
    }
  }

  .modal-content {
    padding: 3rem;
  }

  .modal-body {
    padding: 3rem;

    @include media-breakpoint-down(sm) {
      padding: 1.5rem;
    }

    .form-group {
      margin-bottom: 2rem;

      > label {
        font-weight: bold;
        color: theme-color("dark");
        display: block;
        margin-bottom: 10px;
        font-size: 20px;
        color: theme-color("blue");
      }

      ul {

          display: flex;
          flex-wrap: wrap;

        li {
          flex: 0 1 33%;

          @include media-breakpoint-down(sm) {
            flex: 0 1 50%;
          }
        }
      }
    }
  }

  .input-group {
    input {
      height: 50px;
      border-right: 0;
      border-color: #ccc;
    }

    label {
      min-width: 100%;
    }

    button {
      margin-left: -2px;
      border-color: #ccc;
      background-color: theme-color("light");
      font-weight: bold;
      position: relative;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .twitter-typeahead {
      flex: 1;
      display: block;
    }
  }
}

.form-control {
  font-size: 16px;
}

.select2-container .select2-dropdown {
  background-color: #414242;
  color: white;
  border-radius: unset;
  border: 0;
}

.activity-address-access {
  color: theme-color("text-color");
}

.documents-list {
  @extend .list-unstyled;
}

#id_activity_results_list{
  .results-wrapper{
    @include media-breakpoint-up(lg) {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
}

.messages-popup-overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);
  display: flex;
  z-index: 9999;
  align-items: center;
  justify-content: center;
}

.messages-popup {
  padding: 1em;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .messages {
    padding: 0;
    list-style-type: none;
  }

  button {
    max-width: 100px;
  }
}

.bg-dark hr{
  border: 1px solid #888;
  box-shadow: 0 0 3px 2px #444;
}

.img-toqué-detail{
  max-width: 40px;
  margin-right: 5px;
}

.toque-du-local-description{
  display: flex;
  gap: 0.5rem;
  align-items: center;
}


.daterangepicker .ranges li.active{
  background-color: theme-color('primary');
}
