$fa-font-path: "../fonts/fontawesome/";
$enable-rounded: false;
$slick-font-path: "../fonts/slick/";
$slick-loader-path: "../img/";
$theme-colors: (
  "primary": #fdc600,
  "secondary": #d45c4d,
  "third": #3f92b5,
  "red-darker": #d45c4d,
  "red": #f1a2a5,
  "text-color": #6d7783,
  "gray_light": #eceae2,
  "cyan": #9fd2c0,
  "gray": #222,
  "gray-dark": #343a40,
  "yellow": #f8d369,
  "blue": #3f92b5,
  "green-project": #909f85,
  "facebook": #3b5998,
  "twitter": #2aa3ef,
  "instagram": #b73678,
  "googleplus": #d34836,
  "linkedin": #0073b1,
  "light": #eee,
  "white_darker": #f5f5f6,
  "white": #fff,
);

:root {
  --sidrSidebarWidth: 100%;
}

$hamburger-padding-x: 0px;
$hamburger-padding-y: 0px;

$hamburger-layer-width: 30px;
$hamburger-layer-height: 4px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: #fff;
