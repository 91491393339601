.cluster0,
.cluster1,
.cluster2 {
    position: absolute;
    width: 40px;
    height: 40px;
    text-align: center;
    color: $white;
    line-height: 40px;
}

.marker-cluster-small,
.cluster0 {
    background-color: theme-color('primary');
}

.marker-cluster-small div {
    background-color: theme-color('primary');
}

.marker-cluster-medium,
.cluster1 {
    background-color: theme-color('primary');
}

.marker-cluster-medium div {
    background-color: theme-color('primary');
}

.marker-cluster-large,
.cluster2 {
    background-color: theme-color('primary');
}

.marker-cluster-large div {
    background-color: theme-color('primary');
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
    background-color: theme-color('primary');
}

.leaflet-oldie .marker-cluster-small div {
    background-color: theme-color('primary');
}

.leaflet-oldie .marker-cluster-medium {
    background-color: theme-color('primary');
}

.leaflet-oldie .marker-cluster-medium div {
    background-color: theme-color('primary');
}

.leaflet-oldie .marker-cluster-large {
    background-color: theme-color('primary');
}

.leaflet-oldie .marker-cluster-large div {
    background-color: theme-color('primary');
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
}

.marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;

    text-align: center;
    border-radius: 15px;
    color: $white;
}

.marker-cluster span {
    line-height: 30px;
}

.search-results__map-move-search {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9999;
  background-color: $white;
  padding: 5px 7px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  cursor: pointer;

  input{
      margin-right: 5px;
      cursor: pointer;
  }
  label{
      display: inline-block;
      margin: 0;
      cursor: pointer;
  }
}

.mapboxgl-popup-close-button {
  font-size: 2em;
  line-height: 1em;
  background-color: white;

  &:hover {
    background-color: white;
  }
}

.leaflet-container {
  font-size: 1.2rem;
}
