.djangocms_popup {
  position: fixed;
  visibility: hidden;
  bottom: 90px;
  right: 20px;
  max-height: 70%;
  overflow-y: auto;
  background-color: white;
  padding: 2rem;
  padding-top: 4rem;
  z-index: 999;
  border: 1px solid #eee;
  box-shadow: 0 12px 24px 0 rgba($dark, .2);
  max-width: 500px;
  transform: translate(-80px, 103%);
  transition: visibility 0s,
              transform 500ms;

  span {
    font-size: 1.2em;
  }

  .fas::before {
    content: "\f129";
  }

  &.popup-is-open {
    visibility: visible;
    transform: translate(0, 0);
    transition: visibility 0s,
                transform 100ms;
    .fas::before {
      content: "\f00d";
    }
  }

  @include media-breakpoint-up(lg) {
    max-width: 30%;
  }
}

.djangocms_popup_close {
  position: absolute;
  visibility: hidden;
  display: inline-flex;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: theme-color('primary');
  color: white;
}
