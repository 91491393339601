// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-bag-name: 'sprite_bag';
$sprite-bag-x: 212px;
$sprite-bag-y: 119px;
$sprite-bag-offset-x: -212px;
$sprite-bag-offset-y: -119px;
$sprite-bag-width: 26px;
$sprite-bag-height: 24px;
$sprite-bag-total-width: 304px;
$sprite-bag-total-height: 258px;
$sprite-bag-image: '../img/sprite.png';
$sprite-bag: (212px, 119px, -212px, -119px, 26px, 24px, 304px, 258px, '../img/sprite.png', 'sprite_bag', );
$sprite-bag-gray-name: 'sprite_bag_gray';
$sprite-bag-gray-x: 212px;
$sprite-bag-gray-y: 143px;
$sprite-bag-gray-offset-x: -212px;
$sprite-bag-gray-offset-y: -143px;
$sprite-bag-gray-width: 26px;
$sprite-bag-gray-height: 24px;
$sprite-bag-gray-total-width: 304px;
$sprite-bag-gray-total-height: 258px;
$sprite-bag-gray-image: '../img/sprite.png';
$sprite-bag-gray: (212px, 143px, -212px, -143px, 26px, 24px, 304px, 258px, '../img/sprite.png', 'sprite_bag_gray', );
$sprite-bar-name: 'sprite_bar';
$sprite-bar-x: 280px;
$sprite-bar-y: 150px;
$sprite-bar-offset-x: -280px;
$sprite-bar-offset-y: -150px;
$sprite-bar-width: 22px;
$sprite-bar-height: 21px;
$sprite-bar-total-width: 304px;
$sprite-bar-total-height: 258px;
$sprite-bar-image: '../img/sprite.png';
$sprite-bar: (280px, 150px, -280px, -150px, 22px, 21px, 304px, 258px, '../img/sprite.png', 'sprite_bar', );
$sprite-bike-name: 'sprite_bike';
$sprite-bike-x: 140px;
$sprite-bike-y: 212px;
$sprite-bike-offset-x: -140px;
$sprite-bike-offset-y: -212px;
$sprite-bike-width: 23px;
$sprite-bike-height: 16px;
$sprite-bike-total-width: 304px;
$sprite-bike-total-height: 258px;
$sprite-bike-image: '../img/sprite.png';
$sprite-bike: (140px, 212px, -140px, -212px, 23px, 16px, 304px, 258px, '../img/sprite.png', 'sprite_bike', );
$sprite-breakfast-name: 'sprite_breakfast';
$sprite-breakfast-x: 244px;
$sprite-breakfast-y: 50px;
$sprite-breakfast-offset-x: -244px;
$sprite-breakfast-offset-y: -50px;
$sprite-breakfast-width: 32px;
$sprite-breakfast-height: 28px;
$sprite-breakfast-total-width: 304px;
$sprite-breakfast-total-height: 258px;
$sprite-breakfast-image: '../img/sprite.png';
$sprite-breakfast: (244px, 50px, -244px, -50px, 32px, 28px, 304px, 258px, '../img/sprite.png', 'sprite_breakfast', );
$sprite-bungalow-name: 'sprite_bungalow';
$sprite-bungalow-x: 106px;
$sprite-bungalow-y: 173px;
$sprite-bungalow-offset-x: -106px;
$sprite-bungalow-offset-y: -173px;
$sprite-bungalow-width: 32px;
$sprite-bungalow-height: 34px;
$sprite-bungalow-total-width: 304px;
$sprite-bungalow-total-height: 258px;
$sprite-bungalow-image: '../img/sprite.png';
$sprite-bungalow: (106px, 173px, -106px, -173px, 32px, 34px, 304px, 258px, '../img/sprite.png', 'sprite_bungalow', );
$sprite-camping-name: 'sprite_camping';
$sprite-camping-x: 166px;
$sprite-camping-y: 106px;
$sprite-camping-offset-x: -166px;
$sprite-camping-offset-y: -106px;
$sprite-camping-width: 32px;
$sprite-camping-height: 35px;
$sprite-camping-total-width: 304px;
$sprite-camping-total-height: 258px;
$sprite-camping-image: '../img/sprite.png';
$sprite-camping: (166px, 106px, -166px, -106px, 32px, 35px, 304px, 258px, '../img/sprite.png', 'sprite_camping', );
$sprite-caravan-name: 'sprite_caravan';
$sprite-caravan-x: 76px;
$sprite-caravan-y: 212px;
$sprite-caravan-offset-x: -76px;
$sprite-caravan-offset-y: -212px;
$sprite-caravan-width: 32px;
$sprite-caravan-height: 19px;
$sprite-caravan-total-width: 304px;
$sprite-caravan-total-height: 258px;
$sprite-caravan-image: '../img/sprite.png';
$sprite-caravan: (76px, 212px, -76px, -212px, 32px, 19px, 304px, 258px, '../img/sprite.png', 'sprite_caravan', );
$sprite-clim-name: 'sprite_clim';
$sprite-clim-x: 244px;
$sprite-clim-y: 103px;
$sprite-clim-offset-x: -244px;
$sprite-clim-offset-y: -103px;
$sprite-clim-width: 34px;
$sprite-clim-height: 20px;
$sprite-clim-total-width: 304px;
$sprite-clim-total-height: 258px;
$sprite-clim-image: '../img/sprite.png';
$sprite-clim: (244px, 103px, -244px, -103px, 34px, 20px, 304px, 258px, '../img/sprite.png', 'sprite_clim', );
$sprite-couple-name: 'sprite_couple';
$sprite-couple-x: 166px;
$sprite-couple-y: 141px;
$sprite-couple-offset-x: -166px;
$sprite-couple-offset-y: -141px;
$sprite-couple-width: 32px;
$sprite-couple-height: 31px;
$sprite-couple-total-width: 304px;
$sprite-couple-total-height: 258px;
$sprite-couple-image: '../img/sprite.png';
$sprite-couple: (166px, 141px, -166px, -141px, 32px, 31px, 304px, 258px, '../img/sprite.png', 'sprite_couple', );
$sprite-couple-black-name: 'sprite_couple_black';
$sprite-couple-black-x: 212px;
$sprite-couple-black-y: 0px;
$sprite-couple-black-offset-x: -212px;
$sprite-couple-black-offset-y: 0px;
$sprite-couple-black-width: 32px;
$sprite-couple-black-height: 31px;
$sprite-couple-black-total-width: 304px;
$sprite-couple-black-total-height: 258px;
$sprite-couple-black-image: '../img/sprite.png';
$sprite-couple-black: (212px, 0px, -212px, 0px, 32px, 31px, 304px, 258px, '../img/sprite.png', 'sprite_couple_black', );
$sprite-drap-name: 'sprite_drap';
$sprite-drap-x: 280px;
$sprite-drap-y: 130px;
$sprite-drap-offset-x: -280px;
$sprite-drap-offset-y: -130px;
$sprite-drap-width: 24px;
$sprite-drap-height: 20px;
$sprite-drap-total-width: 304px;
$sprite-drap-total-height: 258px;
$sprite-drap-image: '../img/sprite.png';
$sprite-drap: (280px, 130px, -280px, -130px, 24px, 20px, 304px, 258px, '../img/sprite.png', 'sprite_drap', );
$sprite-elevator-name: 'sprite_elevator';
$sprite-elevator-x: 267px;
$sprite-elevator-y: 193px;
$sprite-elevator-offset-x: -267px;
$sprite-elevator-offset-y: -193px;
$sprite-elevator-width: 11px;
$sprite-elevator-height: 28px;
$sprite-elevator-total-width: 304px;
$sprite-elevator-total-height: 258px;
$sprite-elevator-image: '../img/sprite.png';
$sprite-elevator: (267px, 193px, -267px, -193px, 11px, 28px, 304px, 258px, '../img/sprite.png', 'sprite_elevator', );
$sprite-family-name: 'sprite_family';
$sprite-family-x: 212px;
$sprite-family-y: 31px;
$sprite-family-offset-x: -212px;
$sprite-family-offset-y: -31px;
$sprite-family-width: 32px;
$sprite-family-height: 30px;
$sprite-family-total-width: 304px;
$sprite-family-total-height: 258px;
$sprite-family-image: '../img/sprite.png';
$sprite-family: (212px, 31px, -212px, -31px, 32px, 30px, 304px, 258px, '../img/sprite.png', 'sprite_family', );
$sprite-family-black-name: 'sprite_family_black';
$sprite-family-black-x: 212px;
$sprite-family-black-y: 61px;
$sprite-family-black-offset-x: -212px;
$sprite-family-black-offset-y: -61px;
$sprite-family-black-width: 32px;
$sprite-family-black-height: 30px;
$sprite-family-black-total-width: 304px;
$sprite-family-black-total-height: 258px;
$sprite-family-black-image: '../img/sprite.png';
$sprite-family-black: (212px, 61px, -212px, -61px, 32px, 30px, 304px, 258px, '../img/sprite.png', 'sprite_family_black', );
$sprite-friends-name: 'sprite_friends';
$sprite-friends-x: 138px;
$sprite-friends-y: 173px;
$sprite-friends-offset-x: -138px;
$sprite-friends-offset-y: -173px;
$sprite-friends-width: 32px;
$sprite-friends-height: 34px;
$sprite-friends-total-width: 304px;
$sprite-friends-total-height: 258px;
$sprite-friends-image: '../img/sprite.png';
$sprite-friends: (138px, 173px, -138px, -173px, 32px, 34px, 304px, 258px, '../img/sprite.png', 'sprite_friends', );
$sprite-friends-black-name: 'sprite_friends_black';
$sprite-friends-black-x: 170px;
$sprite-friends-black-y: 173px;
$sprite-friends-black-offset-x: -170px;
$sprite-friends-black-offset-y: -173px;
$sprite-friends-black-width: 32px;
$sprite-friends-black-height: 34px;
$sprite-friends-black-total-width: 304px;
$sprite-friends-black-total-height: 258px;
$sprite-friends-black-image: '../img/sprite.png';
$sprite-friends-black: (170px, 173px, -170px, -173px, 32px, 34px, 304px, 258px, '../img/sprite.png', 'sprite_friends_black', );
$sprite-gourmet-name: 'sprite_gourmet';
$sprite-gourmet-x: 0px;
$sprite-gourmet-y: 0px;
$sprite-gourmet-offset-x: 0px;
$sprite-gourmet-offset-y: 0px;
$sprite-gourmet-width: 106px;
$sprite-gourmet-height: 106px;
$sprite-gourmet-total-width: 304px;
$sprite-gourmet-total-height: 258px;
$sprite-gourmet-image: '../img/sprite.png';
$sprite-gourmet: (0px, 0px, 0px, 0px, 106px, 106px, 304px, 258px, '../img/sprite.png', 'sprite_gourmet', );
$sprite-group-name: 'sprite_group';
$sprite-group-x: 244px;
$sprite-group-y: 0px;
$sprite-group-offset-x: -244px;
$sprite-group-offset-y: 0px;
$sprite-group-width: 36px;
$sprite-group-height: 25px;
$sprite-group-total-width: 304px;
$sprite-group-total-height: 258px;
$sprite-group-image: '../img/sprite.png';
$sprite-group: (244px, 0px, -244px, 0px, 36px, 25px, 304px, 258px, '../img/sprite.png', 'sprite_group', );
$sprite-group-gray-name: 'sprite_group_gray';
$sprite-group-gray-x: 244px;
$sprite-group-gray-y: 25px;
$sprite-group-gray-offset-x: -244px;
$sprite-group-gray-offset-y: -25px;
$sprite-group-gray-width: 36px;
$sprite-group-gray-height: 25px;
$sprite-group-gray-total-width: 304px;
$sprite-group-gray-total-height: 258px;
$sprite-group-gray-image: '../img/sprite.png';
$sprite-group-gray: (244px, 25px, -244px, -25px, 36px, 25px, 304px, 258px, '../img/sprite.png', 'sprite_group_gray', );
$sprite-groupes-name: 'sprite_groupes';
$sprite-groupes-x: 0px;
$sprite-groupes-y: 212px;
$sprite-groupes-offset-x: 0px;
$sprite-groupes-offset-y: -212px;
$sprite-groupes-width: 38px;
$sprite-groupes-height: 24px;
$sprite-groupes-total-width: 304px;
$sprite-groupes-total-height: 258px;
$sprite-groupes-image: '../img/sprite.png';
$sprite-groupes: (0px, 212px, 0px, -212px, 38px, 24px, 304px, 258px, '../img/sprite.png', 'sprite_groupes', );
$sprite-groupes-gray-name: 'sprite_groupes_gray';
$sprite-groupes-gray-x: 38px;
$sprite-groupes-gray-y: 212px;
$sprite-groupes-gray-offset-x: -38px;
$sprite-groupes-gray-offset-y: -212px;
$sprite-groupes-gray-width: 38px;
$sprite-groupes-gray-height: 24px;
$sprite-groupes-gray-total-width: 304px;
$sprite-groupes-gray-total-height: 258px;
$sprite-groupes-gray-image: '../img/sprite.png';
$sprite-groupes-gray: (38px, 212px, -38px, -212px, 38px, 24px, 304px, 258px, '../img/sprite.png', 'sprite_groupes_gray', );
$sprite-groups-name: 'sprite_groups';
$sprite-groups-x: 244px;
$sprite-groups-y: 149px;
$sprite-groups-offset-x: -244px;
$sprite-groups-offset-y: -149px;
$sprite-groups-width: 32px;
$sprite-groups-height: 21px;
$sprite-groups-total-width: 304px;
$sprite-groups-total-height: 258px;
$sprite-groups-image: '../img/sprite.png';
$sprite-groups: (244px, 149px, -244px, -149px, 32px, 21px, 304px, 258px, '../img/sprite.png', 'sprite_groups', );
$sprite-hamam-name: 'sprite_hamam';
$sprite-hamam-x: 73px;
$sprite-hamam-y: 236px;
$sprite-hamam-offset-x: -73px;
$sprite-hamam-offset-y: -236px;
$sprite-hamam-width: 20px;
$sprite-hamam-height: 17px;
$sprite-hamam-total-width: 304px;
$sprite-hamam-total-height: 258px;
$sprite-hamam-image: '../img/sprite.png';
$sprite-hamam: (73px, 236px, -73px, -236px, 20px, 17px, 304px, 258px, '../img/sprite.png', 'sprite_hamam', );
$sprite-handicap-name: 'sprite_handicap';
$sprite-handicap-x: 212px;
$sprite-handicap-y: 91px;
$sprite-handicap-offset-x: -212px;
$sprite-handicap-offset-y: -91px;
$sprite-handicap-width: 23px;
$sprite-handicap-height: 28px;
$sprite-handicap-total-width: 304px;
$sprite-handicap-total-height: 258px;
$sprite-handicap-image: '../img/sprite.png';
$sprite-handicap: (212px, 91px, -212px, -91px, 23px, 28px, 304px, 258px, '../img/sprite.png', 'sprite_handicap', );
$sprite-heart-name: 'sprite_heart';
$sprite-heart-x: 0px;
$sprite-heart-y: 236px;
$sprite-heart-offset-x: 0px;
$sprite-heart-offset-y: -236px;
$sprite-heart-width: 27px;
$sprite-heart-height: 22px;
$sprite-heart-total-width: 304px;
$sprite-heart-total-height: 258px;
$sprite-heart-image: '../img/sprite.png';
$sprite-heart: (0px, 236px, 0px, -236px, 27px, 22px, 304px, 258px, '../img/sprite.png', 'sprite_heart', );
$sprite-heart-gray-name: 'sprite_heart_gray';
$sprite-heart-gray-x: 27px;
$sprite-heart-gray-y: 236px;
$sprite-heart-gray-offset-x: -27px;
$sprite-heart-gray-offset-y: -236px;
$sprite-heart-gray-width: 27px;
$sprite-heart-gray-height: 22px;
$sprite-heart-gray-total-width: 304px;
$sprite-heart-gray-total-height: 258px;
$sprite-heart-gray-image: '../img/sprite.png';
$sprite-heart-gray: (27px, 236px, -27px, -236px, 27px, 22px, 304px, 258px, '../img/sprite.png', 'sprite_heart_gray', );
$sprite-heart-white-name: 'sprite_heart_white';
$sprite-heart-white-x: 244px;
$sprite-heart-white-y: 78px;
$sprite-heart-white-offset-x: -244px;
$sprite-heart-white-offset-y: -78px;
$sprite-heart-white-width: 29px;
$sprite-heart-white-height: 25px;
$sprite-heart-white-total-width: 304px;
$sprite-heart-white-total-height: 258px;
$sprite-heart-white-image: '../img/sprite.png';
$sprite-heart-white: (244px, 78px, -244px, -78px, 29px, 25px, 304px, 258px, '../img/sprite.png', 'sprite_heart_white', );
$sprite-jardin-name: 'sprite_jardin';
$sprite-jardin-x: 54px;
$sprite-jardin-y: 236px;
$sprite-jardin-offset-x: -54px;
$sprite-jardin-offset-y: -236px;
$sprite-jardin-width: 19px;
$sprite-jardin-height: 20px;
$sprite-jardin-total-width: 304px;
$sprite-jardin-total-height: 258px;
$sprite-jardin-image: '../img/sprite.png';
$sprite-jardin: (54px, 236px, -54px, -236px, 19px, 20px, 304px, 258px, '../img/sprite.png', 'sprite_jardin', );
$sprite-location-name: 'sprite_location';
$sprite-location-x: 106px;
$sprite-location-y: 106px;
$sprite-location-offset-x: -106px;
$sprite-location-offset-y: -106px;
$sprite-location-width: 60px;
$sprite-location-height: 67px;
$sprite-location-total-width: 304px;
$sprite-location-total-height: 258px;
$sprite-location-image: '../img/sprite.png';
$sprite-location: (106px, 106px, -106px, -106px, 60px, 67px, 304px, 258px, '../img/sprite.png', 'sprite_location', );
$sprite-offers-dishes-homemade-meal-activity-specialties-name: 'sprite_offers-dishes-homemade-meal-activity-specialties';
$sprite-offers-dishes-homemade-meal-activity-specialties-x: 244px;
$sprite-offers-dishes-homemade-meal-activity-specialties-y: 170px;
$sprite-offers-dishes-homemade-meal-activity-specialties-offset-x: -244px;
$sprite-offers-dishes-homemade-meal-activity-specialties-offset-y: -170px;
$sprite-offers-dishes-homemade-meal-activity-specialties-width: 29px;
$sprite-offers-dishes-homemade-meal-activity-specialties-height: 23px;
$sprite-offers-dishes-homemade-meal-activity-specialties-total-width: 304px;
$sprite-offers-dishes-homemade-meal-activity-specialties-total-height: 258px;
$sprite-offers-dishes-homemade-meal-activity-specialties-image: '../img/sprite.png';
$sprite-offers-dishes-homemade-meal-activity-specialties: (244px, 170px, -244px, -170px, 29px, 23px, 304px, 258px, '../img/sprite.png', 'sprite_offers-dishes-homemade-meal-activity-specialties', );
$sprite-parking-name: 'sprite_parking';
$sprite-parking-x: 280px;
$sprite-parking-y: 190px;
$sprite-parking-offset-x: -280px;
$sprite-parking-offset-y: -190px;
$sprite-parking-width: 20px;
$sprite-parking-height: 20px;
$sprite-parking-total-width: 304px;
$sprite-parking-total-height: 258px;
$sprite-parking-image: '../img/sprite.png';
$sprite-parking: (280px, 190px, -280px, -190px, 20px, 20px, 304px, 258px, '../img/sprite.png', 'sprite_parking', );
$sprite-patrimoine-name: 'sprite_patrimoine';
$sprite-patrimoine-x: 106px;
$sprite-patrimoine-y: 0px;
$sprite-patrimoine-offset-x: -106px;
$sprite-patrimoine-offset-y: 0px;
$sprite-patrimoine-width: 106px;
$sprite-patrimoine-height: 106px;
$sprite-patrimoine-total-width: 304px;
$sprite-patrimoine-total-height: 258px;
$sprite-patrimoine-image: '../img/sprite.png';
$sprite-patrimoine: (106px, 0px, -106px, 0px, 106px, 106px, 304px, 258px, '../img/sprite.png', 'sprite_patrimoine', );
$sprite-petanque-name: 'sprite_petanque';
$sprite-petanque-x: 244px;
$sprite-petanque-y: 123px;
$sprite-petanque-offset-x: -244px;
$sprite-petanque-offset-y: -123px;
$sprite-petanque-width: 26px;
$sprite-petanque-height: 26px;
$sprite-petanque-total-width: 304px;
$sprite-petanque-total-height: 258px;
$sprite-petanque-image: '../img/sprite.png';
$sprite-petanque: (244px, 123px, -244px, -123px, 26px, 26px, 304px, 258px, '../img/sprite.png', 'sprite_petanque', );
$sprite-pets-name: 'sprite_pets';
$sprite-pets-x: 280px;
$sprite-pets-y: 109px;
$sprite-pets-offset-x: -280px;
$sprite-pets-offset-y: -109px;
$sprite-pets-width: 23px;
$sprite-pets-height: 21px;
$sprite-pets-total-width: 304px;
$sprite-pets-total-height: 258px;
$sprite-pets-image: '../img/sprite.png';
$sprite-pets: (280px, 109px, -280px, -109px, 23px, 21px, 304px, 258px, '../img/sprite.png', 'sprite_pets', );
$sprite-piscine-name: 'sprite_piscine';
$sprite-piscine-x: 244px;
$sprite-piscine-y: 222px;
$sprite-piscine-offset-x: -244px;
$sprite-piscine-offset-y: -222px;
$sprite-piscine-width: 25px;
$sprite-piscine-height: 13px;
$sprite-piscine-total-width: 304px;
$sprite-piscine-total-height: 258px;
$sprite-piscine-image: '../img/sprite.png';
$sprite-piscine: (244px, 222px, -244px, -222px, 25px, 13px, 304px, 258px, '../img/sprite.png', 'sprite_piscine', );
$sprite-resto-name: 'sprite_resto';
$sprite-resto-x: 280px;
$sprite-resto-y: 171px;
$sprite-resto-offset-x: -280px;
$sprite-resto-offset-y: -171px;
$sprite-resto-width: 23px;
$sprite-resto-height: 19px;
$sprite-resto-total-width: 304px;
$sprite-resto-total-height: 258px;
$sprite-resto-image: '../img/sprite.png';
$sprite-resto: (280px, 171px, -280px, -171px, 23px, 19px, 304px, 258px, '../img/sprite.png', 'sprite_resto', );
$sprite-room-name: 'sprite_room';
$sprite-room-x: 212px;
$sprite-room-y: 194px;
$sprite-room-offset-x: -212px;
$sprite-room-offset-y: -194px;
$sprite-room-width: 24px;
$sprite-room-height: 16px;
$sprite-room-total-width: 304px;
$sprite-room-total-height: 258px;
$sprite-room-image: '../img/sprite.png';
$sprite-room: (212px, 194px, -212px, -194px, 24px, 16px, 304px, 258px, '../img/sprite.png', 'sprite_room', );
$sprite-sauna-name: 'sprite_sauna';
$sprite-sauna-x: 280px;
$sprite-sauna-y: 0px;
$sprite-sauna-offset-x: -280px;
$sprite-sauna-offset-y: 0px;
$sprite-sauna-width: 24px;
$sprite-sauna-height: 24px;
$sprite-sauna-total-width: 304px;
$sprite-sauna-total-height: 258px;
$sprite-sauna-image: '../img/sprite.png';
$sprite-sauna: (280px, 0px, -280px, 0px, 24px, 24px, 304px, 258px, '../img/sprite.png', 'sprite_sauna', );
$sprite-solo-name: 'sprite_solo';
$sprite-solo-x: 280px;
$sprite-solo-y: 24px;
$sprite-solo-offset-x: -280px;
$sprite-solo-offset-y: -24px;
$sprite-solo-width: 18px;
$sprite-solo-height: 32px;
$sprite-solo-total-width: 304px;
$sprite-solo-total-height: 258px;
$sprite-solo-image: '../img/sprite.png';
$sprite-solo: (280px, 24px, -280px, -24px, 18px, 32px, 304px, 258px, '../img/sprite.png', 'sprite_solo', );
$sprite-solo-black-name: 'sprite_solo_black';
$sprite-solo-black-x: 280px;
$sprite-solo-black-y: 56px;
$sprite-solo-black-offset-x: -280px;
$sprite-solo-black-offset-y: -56px;
$sprite-solo-black-width: 18px;
$sprite-solo-black-height: 32px;
$sprite-solo-black-total-width: 304px;
$sprite-solo-black-total-height: 258px;
$sprite-solo-black-image: '../img/sprite.png';
$sprite-solo-black: (280px, 56px, -280px, -56px, 18px, 32px, 304px, 258px, '../img/sprite.png', 'sprite_solo_black', );
$sprite-spa-name: 'sprite_spa';
$sprite-spa-x: 212px;
$sprite-spa-y: 167px;
$sprite-spa-offset-x: -212px;
$sprite-spa-offset-y: -167px;
$sprite-spa-width: 23px;
$sprite-spa-height: 27px;
$sprite-spa-total-width: 304px;
$sprite-spa-total-height: 258px;
$sprite-spa-image: '../img/sprite.png';
$sprite-spa: (212px, 167px, -212px, -167px, 23px, 27px, 304px, 258px, '../img/sprite.png', 'sprite_spa', );
$sprite-sport-name: 'sprite_sport';
$sprite-sport-x: 0px;
$sprite-sport-y: 106px;
$sprite-sport-offset-x: 0px;
$sprite-sport-offset-y: -106px;
$sprite-sport-width: 106px;
$sprite-sport-height: 106px;
$sprite-sport-total-width: 304px;
$sprite-sport-total-height: 258px;
$sprite-sport-image: '../img/sprite.png';
$sprite-sport: (0px, 106px, 0px, -106px, 106px, 106px, 304px, 258px, '../img/sprite.png', 'sprite_sport', );
$sprite-takeawaycooked-dishes-name: 'sprite_takeawaycooked-dishes';
$sprite-takeawaycooked-dishes-x: 244px;
$sprite-takeawaycooked-dishes-y: 193px;
$sprite-takeawaycooked-dishes-offset-x: -244px;
$sprite-takeawaycooked-dishes-offset-y: -193px;
$sprite-takeawaycooked-dishes-width: 23px;
$sprite-takeawaycooked-dishes-height: 29px;
$sprite-takeawaycooked-dishes-total-width: 304px;
$sprite-takeawaycooked-dishes-total-height: 258px;
$sprite-takeawaycooked-dishes-image: '../img/sprite.png';
$sprite-takeawaycooked-dishes: (244px, 193px, -244px, -193px, 23px, 29px, 304px, 258px, '../img/sprite.png', 'sprite_takeawaycooked-dishes', );
$sprite-tent-name: 'sprite_tent';
$sprite-tent-x: 108px;
$sprite-tent-y: 212px;
$sprite-tent-offset-x: -108px;
$sprite-tent-offset-y: -212px;
$sprite-tent-width: 32px;
$sprite-tent-height: 19px;
$sprite-tent-total-width: 304px;
$sprite-tent-total-height: 258px;
$sprite-tent-image: '../img/sprite.png';
$sprite-tent: (108px, 212px, -108px, -212px, 32px, 19px, 304px, 258px, '../img/sprite.png', 'sprite_tent', );
$sprite-terrasse-name: 'sprite_terrasse';
$sprite-terrasse-x: 280px;
$sprite-terrasse-y: 210px;
$sprite-terrasse-offset-x: -280px;
$sprite-terrasse-offset-y: -210px;
$sprite-terrasse-width: 20px;
$sprite-terrasse-height: 20px;
$sprite-terrasse-total-width: 304px;
$sprite-terrasse-total-height: 258px;
$sprite-terrasse-image: '../img/sprite.png';
$sprite-terrasse: (280px, 210px, -280px, -210px, 20px, 20px, 304px, 258px, '../img/sprite.png', 'sprite_terrasse', );
$sprite-tv-name: 'sprite_tv';
$sprite-tv-x: 280px;
$sprite-tv-y: 88px;
$sprite-tv-offset-x: -280px;
$sprite-tv-offset-y: -88px;
$sprite-tv-width: 24px;
$sprite-tv-height: 21px;
$sprite-tv-total-width: 304px;
$sprite-tv-total-height: 258px;
$sprite-tv-image: '../img/sprite.png';
$sprite-tv: (280px, 88px, -280px, -88px, 24px, 21px, 304px, 258px, '../img/sprite.png', 'sprite_tv', );
$sprite-wifi-name: 'sprite_wifi';
$sprite-wifi-x: 280px;
$sprite-wifi-y: 230px;
$sprite-wifi-offset-x: -280px;
$sprite-wifi-offset-y: -230px;
$sprite-wifi-width: 23px;
$sprite-wifi-height: 17px;
$sprite-wifi-total-width: 304px;
$sprite-wifi-total-height: 258px;
$sprite-wifi-image: '../img/sprite.png';
$sprite-wifi: (280px, 230px, -280px, -230px, 23px, 17px, 304px, 258px, '../img/sprite.png', 'sprite_wifi', );
$sprite-bag-2x-name: 'sprite_bag@2x';
$sprite-bag-2x-x: 424px;
$sprite-bag-2x-y: 238px;
$sprite-bag-2x-offset-x: -424px;
$sprite-bag-2x-offset-y: -238px;
$sprite-bag-2x-width: 52px;
$sprite-bag-2x-height: 48px;
$sprite-bag-2x-total-width: 608px;
$sprite-bag-2x-total-height: 516px;
$sprite-bag-2x-image: '../img/sprite-2x.png';
$sprite-bag-2x: (424px, 238px, -424px, -238px, 52px, 48px, 608px, 516px, '../img/sprite-2x.png', 'sprite_bag@2x', );
$sprite-bag-gray-2x-name: 'sprite_bag_gray@2x';
$sprite-bag-gray-2x-x: 424px;
$sprite-bag-gray-2x-y: 286px;
$sprite-bag-gray-2x-offset-x: -424px;
$sprite-bag-gray-2x-offset-y: -286px;
$sprite-bag-gray-2x-width: 52px;
$sprite-bag-gray-2x-height: 48px;
$sprite-bag-gray-2x-total-width: 608px;
$sprite-bag-gray-2x-total-height: 516px;
$sprite-bag-gray-2x-image: '../img/sprite-2x.png';
$sprite-bag-gray-2x: (424px, 286px, -424px, -286px, 52px, 48px, 608px, 516px, '../img/sprite-2x.png', 'sprite_bag_gray@2x', );
$sprite-bar-2x-name: 'sprite_bar@2x';
$sprite-bar-2x-x: 560px;
$sprite-bar-2x-y: 300px;
$sprite-bar-2x-offset-x: -560px;
$sprite-bar-2x-offset-y: -300px;
$sprite-bar-2x-width: 44px;
$sprite-bar-2x-height: 42px;
$sprite-bar-2x-total-width: 608px;
$sprite-bar-2x-total-height: 516px;
$sprite-bar-2x-image: '../img/sprite-2x.png';
$sprite-bar-2x: (560px, 300px, -560px, -300px, 44px, 42px, 608px, 516px, '../img/sprite-2x.png', 'sprite_bar@2x', );
$sprite-bike-2x-name: 'sprite_bike@2x';
$sprite-bike-2x-x: 280px;
$sprite-bike-2x-y: 424px;
$sprite-bike-2x-offset-x: -280px;
$sprite-bike-2x-offset-y: -424px;
$sprite-bike-2x-width: 46px;
$sprite-bike-2x-height: 32px;
$sprite-bike-2x-total-width: 608px;
$sprite-bike-2x-total-height: 516px;
$sprite-bike-2x-image: '../img/sprite-2x.png';
$sprite-bike-2x: (280px, 424px, -280px, -424px, 46px, 32px, 608px, 516px, '../img/sprite-2x.png', 'sprite_bike@2x', );
$sprite-breakfast-2x-name: 'sprite_breakfast@2x';
$sprite-breakfast-2x-x: 488px;
$sprite-breakfast-2x-y: 100px;
$sprite-breakfast-2x-offset-x: -488px;
$sprite-breakfast-2x-offset-y: -100px;
$sprite-breakfast-2x-width: 64px;
$sprite-breakfast-2x-height: 56px;
$sprite-breakfast-2x-total-width: 608px;
$sprite-breakfast-2x-total-height: 516px;
$sprite-breakfast-2x-image: '../img/sprite-2x.png';
$sprite-breakfast-2x: (488px, 100px, -488px, -100px, 64px, 56px, 608px, 516px, '../img/sprite-2x.png', 'sprite_breakfast@2x', );
$sprite-bungalow-2x-name: 'sprite_bungalow@2x';
$sprite-bungalow-2x-x: 212px;
$sprite-bungalow-2x-y: 346px;
$sprite-bungalow-2x-offset-x: -212px;
$sprite-bungalow-2x-offset-y: -346px;
$sprite-bungalow-2x-width: 64px;
$sprite-bungalow-2x-height: 68px;
$sprite-bungalow-2x-total-width: 608px;
$sprite-bungalow-2x-total-height: 516px;
$sprite-bungalow-2x-image: '../img/sprite-2x.png';
$sprite-bungalow-2x: (212px, 346px, -212px, -346px, 64px, 68px, 608px, 516px, '../img/sprite-2x.png', 'sprite_bungalow@2x', );
$sprite-camping-2x-name: 'sprite_camping@2x';
$sprite-camping-2x-x: 332px;
$sprite-camping-2x-y: 212px;
$sprite-camping-2x-offset-x: -332px;
$sprite-camping-2x-offset-y: -212px;
$sprite-camping-2x-width: 64px;
$sprite-camping-2x-height: 70px;
$sprite-camping-2x-total-width: 608px;
$sprite-camping-2x-total-height: 516px;
$sprite-camping-2x-image: '../img/sprite-2x.png';
$sprite-camping-2x: (332px, 212px, -332px, -212px, 64px, 70px, 608px, 516px, '../img/sprite-2x.png', 'sprite_camping@2x', );
$sprite-caravan-2x-name: 'sprite_caravan@2x';
$sprite-caravan-2x-x: 152px;
$sprite-caravan-2x-y: 424px;
$sprite-caravan-2x-offset-x: -152px;
$sprite-caravan-2x-offset-y: -424px;
$sprite-caravan-2x-width: 64px;
$sprite-caravan-2x-height: 38px;
$sprite-caravan-2x-total-width: 608px;
$sprite-caravan-2x-total-height: 516px;
$sprite-caravan-2x-image: '../img/sprite-2x.png';
$sprite-caravan-2x: (152px, 424px, -152px, -424px, 64px, 38px, 608px, 516px, '../img/sprite-2x.png', 'sprite_caravan@2x', );
$sprite-clim-2x-name: 'sprite_clim@2x';
$sprite-clim-2x-x: 488px;
$sprite-clim-2x-y: 206px;
$sprite-clim-2x-offset-x: -488px;
$sprite-clim-2x-offset-y: -206px;
$sprite-clim-2x-width: 68px;
$sprite-clim-2x-height: 40px;
$sprite-clim-2x-total-width: 608px;
$sprite-clim-2x-total-height: 516px;
$sprite-clim-2x-image: '../img/sprite-2x.png';
$sprite-clim-2x: (488px, 206px, -488px, -206px, 68px, 40px, 608px, 516px, '../img/sprite-2x.png', 'sprite_clim@2x', );
$sprite-couple-2x-name: 'sprite_couple@2x';
$sprite-couple-2x-x: 332px;
$sprite-couple-2x-y: 282px;
$sprite-couple-2x-offset-x: -332px;
$sprite-couple-2x-offset-y: -282px;
$sprite-couple-2x-width: 64px;
$sprite-couple-2x-height: 62px;
$sprite-couple-2x-total-width: 608px;
$sprite-couple-2x-total-height: 516px;
$sprite-couple-2x-image: '../img/sprite-2x.png';
$sprite-couple-2x: (332px, 282px, -332px, -282px, 64px, 62px, 608px, 516px, '../img/sprite-2x.png', 'sprite_couple@2x', );
$sprite-couple-black-2x-name: 'sprite_couple_black@2x';
$sprite-couple-black-2x-x: 424px;
$sprite-couple-black-2x-y: 0px;
$sprite-couple-black-2x-offset-x: -424px;
$sprite-couple-black-2x-offset-y: 0px;
$sprite-couple-black-2x-width: 64px;
$sprite-couple-black-2x-height: 62px;
$sprite-couple-black-2x-total-width: 608px;
$sprite-couple-black-2x-total-height: 516px;
$sprite-couple-black-2x-image: '../img/sprite-2x.png';
$sprite-couple-black-2x: (424px, 0px, -424px, 0px, 64px, 62px, 608px, 516px, '../img/sprite-2x.png', 'sprite_couple_black@2x', );
$sprite-drap-2x-name: 'sprite_drap@2x';
$sprite-drap-2x-x: 560px;
$sprite-drap-2x-y: 260px;
$sprite-drap-2x-offset-x: -560px;
$sprite-drap-2x-offset-y: -260px;
$sprite-drap-2x-width: 48px;
$sprite-drap-2x-height: 40px;
$sprite-drap-2x-total-width: 608px;
$sprite-drap-2x-total-height: 516px;
$sprite-drap-2x-image: '../img/sprite-2x.png';
$sprite-drap-2x: (560px, 260px, -560px, -260px, 48px, 40px, 608px, 516px, '../img/sprite-2x.png', 'sprite_drap@2x', );
$sprite-elevator-2x-name: 'sprite_elevator@2x';
$sprite-elevator-2x-x: 534px;
$sprite-elevator-2x-y: 386px;
$sprite-elevator-2x-offset-x: -534px;
$sprite-elevator-2x-offset-y: -386px;
$sprite-elevator-2x-width: 22px;
$sprite-elevator-2x-height: 56px;
$sprite-elevator-2x-total-width: 608px;
$sprite-elevator-2x-total-height: 516px;
$sprite-elevator-2x-image: '../img/sprite-2x.png';
$sprite-elevator-2x: (534px, 386px, -534px, -386px, 22px, 56px, 608px, 516px, '../img/sprite-2x.png', 'sprite_elevator@2x', );
$sprite-family-2x-name: 'sprite_family@2x';
$sprite-family-2x-x: 424px;
$sprite-family-2x-y: 62px;
$sprite-family-2x-offset-x: -424px;
$sprite-family-2x-offset-y: -62px;
$sprite-family-2x-width: 64px;
$sprite-family-2x-height: 60px;
$sprite-family-2x-total-width: 608px;
$sprite-family-2x-total-height: 516px;
$sprite-family-2x-image: '../img/sprite-2x.png';
$sprite-family-2x: (424px, 62px, -424px, -62px, 64px, 60px, 608px, 516px, '../img/sprite-2x.png', 'sprite_family@2x', );
$sprite-family-black-2x-name: 'sprite_family_black@2x';
$sprite-family-black-2x-x: 424px;
$sprite-family-black-2x-y: 122px;
$sprite-family-black-2x-offset-x: -424px;
$sprite-family-black-2x-offset-y: -122px;
$sprite-family-black-2x-width: 64px;
$sprite-family-black-2x-height: 60px;
$sprite-family-black-2x-total-width: 608px;
$sprite-family-black-2x-total-height: 516px;
$sprite-family-black-2x-image: '../img/sprite-2x.png';
$sprite-family-black-2x: (424px, 122px, -424px, -122px, 64px, 60px, 608px, 516px, '../img/sprite-2x.png', 'sprite_family_black@2x', );
$sprite-friends-2x-name: 'sprite_friends@2x';
$sprite-friends-2x-x: 276px;
$sprite-friends-2x-y: 346px;
$sprite-friends-2x-offset-x: -276px;
$sprite-friends-2x-offset-y: -346px;
$sprite-friends-2x-width: 64px;
$sprite-friends-2x-height: 68px;
$sprite-friends-2x-total-width: 608px;
$sprite-friends-2x-total-height: 516px;
$sprite-friends-2x-image: '../img/sprite-2x.png';
$sprite-friends-2x: (276px, 346px, -276px, -346px, 64px, 68px, 608px, 516px, '../img/sprite-2x.png', 'sprite_friends@2x', );
$sprite-friends-black-2x-name: 'sprite_friends_black@2x';
$sprite-friends-black-2x-x: 340px;
$sprite-friends-black-2x-y: 346px;
$sprite-friends-black-2x-offset-x: -340px;
$sprite-friends-black-2x-offset-y: -346px;
$sprite-friends-black-2x-width: 64px;
$sprite-friends-black-2x-height: 68px;
$sprite-friends-black-2x-total-width: 608px;
$sprite-friends-black-2x-total-height: 516px;
$sprite-friends-black-2x-image: '../img/sprite-2x.png';
$sprite-friends-black-2x: (340px, 346px, -340px, -346px, 64px, 68px, 608px, 516px, '../img/sprite-2x.png', 'sprite_friends_black@2x', );
$sprite-gourmet-2x-name: 'sprite_gourmet@2x';
$sprite-gourmet-2x-x: 0px;
$sprite-gourmet-2x-y: 0px;
$sprite-gourmet-2x-offset-x: 0px;
$sprite-gourmet-2x-offset-y: 0px;
$sprite-gourmet-2x-width: 212px;
$sprite-gourmet-2x-height: 212px;
$sprite-gourmet-2x-total-width: 608px;
$sprite-gourmet-2x-total-height: 516px;
$sprite-gourmet-2x-image: '../img/sprite-2x.png';
$sprite-gourmet-2x: (0px, 0px, 0px, 0px, 212px, 212px, 608px, 516px, '../img/sprite-2x.png', 'sprite_gourmet@2x', );
$sprite-group-2x-name: 'sprite_group@2x';
$sprite-group-2x-x: 488px;
$sprite-group-2x-y: 0px;
$sprite-group-2x-offset-x: -488px;
$sprite-group-2x-offset-y: 0px;
$sprite-group-2x-width: 72px;
$sprite-group-2x-height: 50px;
$sprite-group-2x-total-width: 608px;
$sprite-group-2x-total-height: 516px;
$sprite-group-2x-image: '../img/sprite-2x.png';
$sprite-group-2x: (488px, 0px, -488px, 0px, 72px, 50px, 608px, 516px, '../img/sprite-2x.png', 'sprite_group@2x', );
$sprite-group-gray-2x-name: 'sprite_group_gray@2x';
$sprite-group-gray-2x-x: 488px;
$sprite-group-gray-2x-y: 50px;
$sprite-group-gray-2x-offset-x: -488px;
$sprite-group-gray-2x-offset-y: -50px;
$sprite-group-gray-2x-width: 72px;
$sprite-group-gray-2x-height: 50px;
$sprite-group-gray-2x-total-width: 608px;
$sprite-group-gray-2x-total-height: 516px;
$sprite-group-gray-2x-image: '../img/sprite-2x.png';
$sprite-group-gray-2x: (488px, 50px, -488px, -50px, 72px, 50px, 608px, 516px, '../img/sprite-2x.png', 'sprite_group_gray@2x', );
$sprite-groupes-2x-name: 'sprite_groupes@2x';
$sprite-groupes-2x-x: 0px;
$sprite-groupes-2x-y: 424px;
$sprite-groupes-2x-offset-x: 0px;
$sprite-groupes-2x-offset-y: -424px;
$sprite-groupes-2x-width: 76px;
$sprite-groupes-2x-height: 48px;
$sprite-groupes-2x-total-width: 608px;
$sprite-groupes-2x-total-height: 516px;
$sprite-groupes-2x-image: '../img/sprite-2x.png';
$sprite-groupes-2x: (0px, 424px, 0px, -424px, 76px, 48px, 608px, 516px, '../img/sprite-2x.png', 'sprite_groupes@2x', );
$sprite-groupes-gray-2x-name: 'sprite_groupes_gray@2x';
$sprite-groupes-gray-2x-x: 76px;
$sprite-groupes-gray-2x-y: 424px;
$sprite-groupes-gray-2x-offset-x: -76px;
$sprite-groupes-gray-2x-offset-y: -424px;
$sprite-groupes-gray-2x-width: 76px;
$sprite-groupes-gray-2x-height: 48px;
$sprite-groupes-gray-2x-total-width: 608px;
$sprite-groupes-gray-2x-total-height: 516px;
$sprite-groupes-gray-2x-image: '../img/sprite-2x.png';
$sprite-groupes-gray-2x: (76px, 424px, -76px, -424px, 76px, 48px, 608px, 516px, '../img/sprite-2x.png', 'sprite_groupes_gray@2x', );
$sprite-groups-2x-name: 'sprite_groups@2x';
$sprite-groups-2x-x: 488px;
$sprite-groups-2x-y: 298px;
$sprite-groups-2x-offset-x: -488px;
$sprite-groups-2x-offset-y: -298px;
$sprite-groups-2x-width: 64px;
$sprite-groups-2x-height: 42px;
$sprite-groups-2x-total-width: 608px;
$sprite-groups-2x-total-height: 516px;
$sprite-groups-2x-image: '../img/sprite-2x.png';
$sprite-groups-2x: (488px, 298px, -488px, -298px, 64px, 42px, 608px, 516px, '../img/sprite-2x.png', 'sprite_groups@2x', );
$sprite-hamam-2x-name: 'sprite_hamam@2x';
$sprite-hamam-2x-x: 146px;
$sprite-hamam-2x-y: 472px;
$sprite-hamam-2x-offset-x: -146px;
$sprite-hamam-2x-offset-y: -472px;
$sprite-hamam-2x-width: 40px;
$sprite-hamam-2x-height: 34px;
$sprite-hamam-2x-total-width: 608px;
$sprite-hamam-2x-total-height: 516px;
$sprite-hamam-2x-image: '../img/sprite-2x.png';
$sprite-hamam-2x: (146px, 472px, -146px, -472px, 40px, 34px, 608px, 516px, '../img/sprite-2x.png', 'sprite_hamam@2x', );
$sprite-handicap-2x-name: 'sprite_handicap@2x';
$sprite-handicap-2x-x: 424px;
$sprite-handicap-2x-y: 182px;
$sprite-handicap-2x-offset-x: -424px;
$sprite-handicap-2x-offset-y: -182px;
$sprite-handicap-2x-width: 46px;
$sprite-handicap-2x-height: 56px;
$sprite-handicap-2x-total-width: 608px;
$sprite-handicap-2x-total-height: 516px;
$sprite-handicap-2x-image: '../img/sprite-2x.png';
$sprite-handicap-2x: (424px, 182px, -424px, -182px, 46px, 56px, 608px, 516px, '../img/sprite-2x.png', 'sprite_handicap@2x', );
$sprite-heart-2x-name: 'sprite_heart@2x';
$sprite-heart-2x-x: 0px;
$sprite-heart-2x-y: 472px;
$sprite-heart-2x-offset-x: 0px;
$sprite-heart-2x-offset-y: -472px;
$sprite-heart-2x-width: 54px;
$sprite-heart-2x-height: 44px;
$sprite-heart-2x-total-width: 608px;
$sprite-heart-2x-total-height: 516px;
$sprite-heart-2x-image: '../img/sprite-2x.png';
$sprite-heart-2x: (0px, 472px, 0px, -472px, 54px, 44px, 608px, 516px, '../img/sprite-2x.png', 'sprite_heart@2x', );
$sprite-heart-gray-2x-name: 'sprite_heart_gray@2x';
$sprite-heart-gray-2x-x: 54px;
$sprite-heart-gray-2x-y: 472px;
$sprite-heart-gray-2x-offset-x: -54px;
$sprite-heart-gray-2x-offset-y: -472px;
$sprite-heart-gray-2x-width: 54px;
$sprite-heart-gray-2x-height: 44px;
$sprite-heart-gray-2x-total-width: 608px;
$sprite-heart-gray-2x-total-height: 516px;
$sprite-heart-gray-2x-image: '../img/sprite-2x.png';
$sprite-heart-gray-2x: (54px, 472px, -54px, -472px, 54px, 44px, 608px, 516px, '../img/sprite-2x.png', 'sprite_heart_gray@2x', );
$sprite-heart-white-2x-name: 'sprite_heart_white@2x';
$sprite-heart-white-2x-x: 488px;
$sprite-heart-white-2x-y: 156px;
$sprite-heart-white-2x-offset-x: -488px;
$sprite-heart-white-2x-offset-y: -156px;
$sprite-heart-white-2x-width: 58px;
$sprite-heart-white-2x-height: 50px;
$sprite-heart-white-2x-total-width: 608px;
$sprite-heart-white-2x-total-height: 516px;
$sprite-heart-white-2x-image: '../img/sprite-2x.png';
$sprite-heart-white-2x: (488px, 156px, -488px, -156px, 58px, 50px, 608px, 516px, '../img/sprite-2x.png', 'sprite_heart_white@2x', );
$sprite-jardin-2x-name: 'sprite_jardin@2x';
$sprite-jardin-2x-x: 108px;
$sprite-jardin-2x-y: 472px;
$sprite-jardin-2x-offset-x: -108px;
$sprite-jardin-2x-offset-y: -472px;
$sprite-jardin-2x-width: 38px;
$sprite-jardin-2x-height: 40px;
$sprite-jardin-2x-total-width: 608px;
$sprite-jardin-2x-total-height: 516px;
$sprite-jardin-2x-image: '../img/sprite-2x.png';
$sprite-jardin-2x: (108px, 472px, -108px, -472px, 38px, 40px, 608px, 516px, '../img/sprite-2x.png', 'sprite_jardin@2x', );
$sprite-location-2x-name: 'sprite_location@2x';
$sprite-location-2x-x: 212px;
$sprite-location-2x-y: 212px;
$sprite-location-2x-offset-x: -212px;
$sprite-location-2x-offset-y: -212px;
$sprite-location-2x-width: 120px;
$sprite-location-2x-height: 134px;
$sprite-location-2x-total-width: 608px;
$sprite-location-2x-total-height: 516px;
$sprite-location-2x-image: '../img/sprite-2x.png';
$sprite-location-2x: (212px, 212px, -212px, -212px, 120px, 134px, 608px, 516px, '../img/sprite-2x.png', 'sprite_location@2x', );
$sprite-offers-dishes-homemade-meal-activity-specialties-2x-name: 'sprite_offers-dishes-homemade-meal-activity-specialties@2x';
$sprite-offers-dishes-homemade-meal-activity-specialties-2x-x: 488px;
$sprite-offers-dishes-homemade-meal-activity-specialties-2x-y: 340px;
$sprite-offers-dishes-homemade-meal-activity-specialties-2x-offset-x: -488px;
$sprite-offers-dishes-homemade-meal-activity-specialties-2x-offset-y: -340px;
$sprite-offers-dishes-homemade-meal-activity-specialties-2x-width: 58px;
$sprite-offers-dishes-homemade-meal-activity-specialties-2x-height: 46px;
$sprite-offers-dishes-homemade-meal-activity-specialties-2x-total-width: 608px;
$sprite-offers-dishes-homemade-meal-activity-specialties-2x-total-height: 516px;
$sprite-offers-dishes-homemade-meal-activity-specialties-2x-image: '../img/sprite-2x.png';
$sprite-offers-dishes-homemade-meal-activity-specialties-2x: (488px, 340px, -488px, -340px, 58px, 46px, 608px, 516px, '../img/sprite-2x.png', 'sprite_offers-dishes-homemade-meal-activity-specialties@2x', );
$sprite-parking-2x-name: 'sprite_parking@2x';
$sprite-parking-2x-x: 560px;
$sprite-parking-2x-y: 380px;
$sprite-parking-2x-offset-x: -560px;
$sprite-parking-2x-offset-y: -380px;
$sprite-parking-2x-width: 40px;
$sprite-parking-2x-height: 40px;
$sprite-parking-2x-total-width: 608px;
$sprite-parking-2x-total-height: 516px;
$sprite-parking-2x-image: '../img/sprite-2x.png';
$sprite-parking-2x: (560px, 380px, -560px, -380px, 40px, 40px, 608px, 516px, '../img/sprite-2x.png', 'sprite_parking@2x', );
$sprite-patrimoine-2x-name: 'sprite_patrimoine@2x';
$sprite-patrimoine-2x-x: 212px;
$sprite-patrimoine-2x-y: 0px;
$sprite-patrimoine-2x-offset-x: -212px;
$sprite-patrimoine-2x-offset-y: 0px;
$sprite-patrimoine-2x-width: 212px;
$sprite-patrimoine-2x-height: 212px;
$sprite-patrimoine-2x-total-width: 608px;
$sprite-patrimoine-2x-total-height: 516px;
$sprite-patrimoine-2x-image: '../img/sprite-2x.png';
$sprite-patrimoine-2x: (212px, 0px, -212px, 0px, 212px, 212px, 608px, 516px, '../img/sprite-2x.png', 'sprite_patrimoine@2x', );
$sprite-petanque-2x-name: 'sprite_petanque@2x';
$sprite-petanque-2x-x: 488px;
$sprite-petanque-2x-y: 246px;
$sprite-petanque-2x-offset-x: -488px;
$sprite-petanque-2x-offset-y: -246px;
$sprite-petanque-2x-width: 52px;
$sprite-petanque-2x-height: 52px;
$sprite-petanque-2x-total-width: 608px;
$sprite-petanque-2x-total-height: 516px;
$sprite-petanque-2x-image: '../img/sprite-2x.png';
$sprite-petanque-2x: (488px, 246px, -488px, -246px, 52px, 52px, 608px, 516px, '../img/sprite-2x.png', 'sprite_petanque@2x', );
$sprite-pets-2x-name: 'sprite_pets@2x';
$sprite-pets-2x-x: 560px;
$sprite-pets-2x-y: 218px;
$sprite-pets-2x-offset-x: -560px;
$sprite-pets-2x-offset-y: -218px;
$sprite-pets-2x-width: 46px;
$sprite-pets-2x-height: 42px;
$sprite-pets-2x-total-width: 608px;
$sprite-pets-2x-total-height: 516px;
$sprite-pets-2x-image: '../img/sprite-2x.png';
$sprite-pets-2x: (560px, 218px, -560px, -218px, 46px, 42px, 608px, 516px, '../img/sprite-2x.png', 'sprite_pets@2x', );
$sprite-piscine-2x-name: 'sprite_piscine@2x';
$sprite-piscine-2x-x: 488px;
$sprite-piscine-2x-y: 444px;
$sprite-piscine-2x-offset-x: -488px;
$sprite-piscine-2x-offset-y: -444px;
$sprite-piscine-2x-width: 50px;
$sprite-piscine-2x-height: 26px;
$sprite-piscine-2x-total-width: 608px;
$sprite-piscine-2x-total-height: 516px;
$sprite-piscine-2x-image: '../img/sprite-2x.png';
$sprite-piscine-2x: (488px, 444px, -488px, -444px, 50px, 26px, 608px, 516px, '../img/sprite-2x.png', 'sprite_piscine@2x', );
$sprite-resto-2x-name: 'sprite_resto@2x';
$sprite-resto-2x-x: 560px;
$sprite-resto-2x-y: 342px;
$sprite-resto-2x-offset-x: -560px;
$sprite-resto-2x-offset-y: -342px;
$sprite-resto-2x-width: 46px;
$sprite-resto-2x-height: 38px;
$sprite-resto-2x-total-width: 608px;
$sprite-resto-2x-total-height: 516px;
$sprite-resto-2x-image: '../img/sprite-2x.png';
$sprite-resto-2x: (560px, 342px, -560px, -342px, 46px, 38px, 608px, 516px, '../img/sprite-2x.png', 'sprite_resto@2x', );
$sprite-room-2x-name: 'sprite_room@2x';
$sprite-room-2x-x: 424px;
$sprite-room-2x-y: 388px;
$sprite-room-2x-offset-x: -424px;
$sprite-room-2x-offset-y: -388px;
$sprite-room-2x-width: 48px;
$sprite-room-2x-height: 32px;
$sprite-room-2x-total-width: 608px;
$sprite-room-2x-total-height: 516px;
$sprite-room-2x-image: '../img/sprite-2x.png';
$sprite-room-2x: (424px, 388px, -424px, -388px, 48px, 32px, 608px, 516px, '../img/sprite-2x.png', 'sprite_room@2x', );
$sprite-sauna-2x-name: 'sprite_sauna@2x';
$sprite-sauna-2x-x: 560px;
$sprite-sauna-2x-y: 0px;
$sprite-sauna-2x-offset-x: -560px;
$sprite-sauna-2x-offset-y: 0px;
$sprite-sauna-2x-width: 48px;
$sprite-sauna-2x-height: 48px;
$sprite-sauna-2x-total-width: 608px;
$sprite-sauna-2x-total-height: 516px;
$sprite-sauna-2x-image: '../img/sprite-2x.png';
$sprite-sauna-2x: (560px, 0px, -560px, 0px, 48px, 48px, 608px, 516px, '../img/sprite-2x.png', 'sprite_sauna@2x', );
$sprite-solo-2x-name: 'sprite_solo@2x';
$sprite-solo-2x-x: 560px;
$sprite-solo-2x-y: 48px;
$sprite-solo-2x-offset-x: -560px;
$sprite-solo-2x-offset-y: -48px;
$sprite-solo-2x-width: 36px;
$sprite-solo-2x-height: 64px;
$sprite-solo-2x-total-width: 608px;
$sprite-solo-2x-total-height: 516px;
$sprite-solo-2x-image: '../img/sprite-2x.png';
$sprite-solo-2x: (560px, 48px, -560px, -48px, 36px, 64px, 608px, 516px, '../img/sprite-2x.png', 'sprite_solo@2x', );
$sprite-solo-black-2x-name: 'sprite_solo_black@2x';
$sprite-solo-black-2x-x: 560px;
$sprite-solo-black-2x-y: 112px;
$sprite-solo-black-2x-offset-x: -560px;
$sprite-solo-black-2x-offset-y: -112px;
$sprite-solo-black-2x-width: 36px;
$sprite-solo-black-2x-height: 64px;
$sprite-solo-black-2x-total-width: 608px;
$sprite-solo-black-2x-total-height: 516px;
$sprite-solo-black-2x-image: '../img/sprite-2x.png';
$sprite-solo-black-2x: (560px, 112px, -560px, -112px, 36px, 64px, 608px, 516px, '../img/sprite-2x.png', 'sprite_solo_black@2x', );
$sprite-spa-2x-name: 'sprite_spa@2x';
$sprite-spa-2x-x: 424px;
$sprite-spa-2x-y: 334px;
$sprite-spa-2x-offset-x: -424px;
$sprite-spa-2x-offset-y: -334px;
$sprite-spa-2x-width: 46px;
$sprite-spa-2x-height: 54px;
$sprite-spa-2x-total-width: 608px;
$sprite-spa-2x-total-height: 516px;
$sprite-spa-2x-image: '../img/sprite-2x.png';
$sprite-spa-2x: (424px, 334px, -424px, -334px, 46px, 54px, 608px, 516px, '../img/sprite-2x.png', 'sprite_spa@2x', );
$sprite-sport-2x-name: 'sprite_sport@2x';
$sprite-sport-2x-x: 0px;
$sprite-sport-2x-y: 212px;
$sprite-sport-2x-offset-x: 0px;
$sprite-sport-2x-offset-y: -212px;
$sprite-sport-2x-width: 212px;
$sprite-sport-2x-height: 212px;
$sprite-sport-2x-total-width: 608px;
$sprite-sport-2x-total-height: 516px;
$sprite-sport-2x-image: '../img/sprite-2x.png';
$sprite-sport-2x: (0px, 212px, 0px, -212px, 212px, 212px, 608px, 516px, '../img/sprite-2x.png', 'sprite_sport@2x', );
$sprite-takeawaycooked-dishes-2x-name: 'sprite_takeawaycooked-dishes@2x';
$sprite-takeawaycooked-dishes-2x-x: 488px;
$sprite-takeawaycooked-dishes-2x-y: 386px;
$sprite-takeawaycooked-dishes-2x-offset-x: -488px;
$sprite-takeawaycooked-dishes-2x-offset-y: -386px;
$sprite-takeawaycooked-dishes-2x-width: 46px;
$sprite-takeawaycooked-dishes-2x-height: 58px;
$sprite-takeawaycooked-dishes-2x-total-width: 608px;
$sprite-takeawaycooked-dishes-2x-total-height: 516px;
$sprite-takeawaycooked-dishes-2x-image: '../img/sprite-2x.png';
$sprite-takeawaycooked-dishes-2x: (488px, 386px, -488px, -386px, 46px, 58px, 608px, 516px, '../img/sprite-2x.png', 'sprite_takeawaycooked-dishes@2x', );
$sprite-tent-2x-name: 'sprite_tent@2x';
$sprite-tent-2x-x: 216px;
$sprite-tent-2x-y: 424px;
$sprite-tent-2x-offset-x: -216px;
$sprite-tent-2x-offset-y: -424px;
$sprite-tent-2x-width: 64px;
$sprite-tent-2x-height: 38px;
$sprite-tent-2x-total-width: 608px;
$sprite-tent-2x-total-height: 516px;
$sprite-tent-2x-image: '../img/sprite-2x.png';
$sprite-tent-2x: (216px, 424px, -216px, -424px, 64px, 38px, 608px, 516px, '../img/sprite-2x.png', 'sprite_tent@2x', );
$sprite-terrasse-2x-name: 'sprite_terrasse@2x';
$sprite-terrasse-2x-x: 560px;
$sprite-terrasse-2x-y: 420px;
$sprite-terrasse-2x-offset-x: -560px;
$sprite-terrasse-2x-offset-y: -420px;
$sprite-terrasse-2x-width: 40px;
$sprite-terrasse-2x-height: 40px;
$sprite-terrasse-2x-total-width: 608px;
$sprite-terrasse-2x-total-height: 516px;
$sprite-terrasse-2x-image: '../img/sprite-2x.png';
$sprite-terrasse-2x: (560px, 420px, -560px, -420px, 40px, 40px, 608px, 516px, '../img/sprite-2x.png', 'sprite_terrasse@2x', );
$sprite-tv-2x-name: 'sprite_tv@2x';
$sprite-tv-2x-x: 560px;
$sprite-tv-2x-y: 176px;
$sprite-tv-2x-offset-x: -560px;
$sprite-tv-2x-offset-y: -176px;
$sprite-tv-2x-width: 48px;
$sprite-tv-2x-height: 42px;
$sprite-tv-2x-total-width: 608px;
$sprite-tv-2x-total-height: 516px;
$sprite-tv-2x-image: '../img/sprite-2x.png';
$sprite-tv-2x: (560px, 176px, -560px, -176px, 48px, 42px, 608px, 516px, '../img/sprite-2x.png', 'sprite_tv@2x', );
$sprite-wifi-2x-name: 'sprite_wifi@2x';
$sprite-wifi-2x-x: 560px;
$sprite-wifi-2x-y: 460px;
$sprite-wifi-2x-offset-x: -560px;
$sprite-wifi-2x-offset-y: -460px;
$sprite-wifi-2x-width: 46px;
$sprite-wifi-2x-height: 34px;
$sprite-wifi-2x-total-width: 608px;
$sprite-wifi-2x-total-height: 516px;
$sprite-wifi-2x-image: '../img/sprite-2x.png';
$sprite-wifi-2x: (560px, 460px, -560px, -460px, 46px, 34px, 608px, 516px, '../img/sprite-2x.png', 'sprite_wifi@2x', );
$spritesheet-width: 304px;
$spritesheet-height: 258px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($sprite-bag, $sprite-bag-gray, $sprite-bar, $sprite-bike, $sprite-breakfast, $sprite-bungalow, $sprite-camping, $sprite-caravan, $sprite-clim, $sprite-couple, $sprite-couple-black, $sprite-drap, $sprite-elevator, $sprite-family, $sprite-family-black, $sprite-friends, $sprite-friends-black, $sprite-gourmet, $sprite-group, $sprite-group-gray, $sprite-groupes, $sprite-groupes-gray, $sprite-groups, $sprite-hamam, $sprite-handicap, $sprite-heart, $sprite-heart-gray, $sprite-heart-white, $sprite-jardin, $sprite-location, $sprite-offers-dishes-homemade-meal-activity-specialties, $sprite-parking, $sprite-patrimoine, $sprite-petanque, $sprite-pets, $sprite-piscine, $sprite-resto, $sprite-room, $sprite-sauna, $sprite-solo, $sprite-solo-black, $sprite-spa, $sprite-sport, $sprite-takeawaycooked-dishes, $sprite-tent, $sprite-terrasse, $sprite-tv, $sprite-wifi, );
$spritesheet: (304px, 258px, '../img/sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 608px;
$retina-spritesheet-height: 516px;
$retina-spritesheet-image: '../img/sprite-2x.png';
$retina-spritesheet-sprites: ($sprite-bag-2x, $sprite-bag-gray-2x, $sprite-bar-2x, $sprite-bike-2x, $sprite-breakfast-2x, $sprite-bungalow-2x, $sprite-camping-2x, $sprite-caravan-2x, $sprite-clim-2x, $sprite-couple-2x, $sprite-couple-black-2x, $sprite-drap-2x, $sprite-elevator-2x, $sprite-family-2x, $sprite-family-black-2x, $sprite-friends-2x, $sprite-friends-black-2x, $sprite-gourmet-2x, $sprite-group-2x, $sprite-group-gray-2x, $sprite-groupes-2x, $sprite-groupes-gray-2x, $sprite-groups-2x, $sprite-hamam-2x, $sprite-handicap-2x, $sprite-heart-2x, $sprite-heart-gray-2x, $sprite-heart-white-2x, $sprite-jardin-2x, $sprite-location-2x, $sprite-offers-dishes-homemade-meal-activity-specialties-2x, $sprite-parking-2x, $sprite-patrimoine-2x, $sprite-petanque-2x, $sprite-pets-2x, $sprite-piscine-2x, $sprite-resto-2x, $sprite-room-2x, $sprite-sauna-2x, $sprite-solo-2x, $sprite-solo-black-2x, $sprite-spa-2x, $sprite-sport-2x, $sprite-takeawaycooked-dishes-2x, $sprite-tent-2x, $sprite-terrasse-2x, $sprite-tv-2x, $sprite-wifi-2x, );
$retina-spritesheet: (608px, 516px, '../img/sprite-2x.png', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$sprite-bag-group-name: 'sprite_bag';
$sprite-bag-group: ('sprite_bag', $sprite-bag, $sprite-bag-2x, );
$sprite-bag-gray-group-name: 'sprite_bag_gray';
$sprite-bag-gray-group: ('sprite_bag_gray', $sprite-bag-gray, $sprite-bag-gray-2x, );
$sprite-bar-group-name: 'sprite_bar';
$sprite-bar-group: ('sprite_bar', $sprite-bar, $sprite-bar-2x, );
$sprite-bike-group-name: 'sprite_bike';
$sprite-bike-group: ('sprite_bike', $sprite-bike, $sprite-bike-2x, );
$sprite-breakfast-group-name: 'sprite_breakfast';
$sprite-breakfast-group: ('sprite_breakfast', $sprite-breakfast, $sprite-breakfast-2x, );
$sprite-bungalow-group-name: 'sprite_bungalow';
$sprite-bungalow-group: ('sprite_bungalow', $sprite-bungalow, $sprite-bungalow-2x, );
$sprite-camping-group-name: 'sprite_camping';
$sprite-camping-group: ('sprite_camping', $sprite-camping, $sprite-camping-2x, );
$sprite-caravan-group-name: 'sprite_caravan';
$sprite-caravan-group: ('sprite_caravan', $sprite-caravan, $sprite-caravan-2x, );
$sprite-clim-group-name: 'sprite_clim';
$sprite-clim-group: ('sprite_clim', $sprite-clim, $sprite-clim-2x, );
$sprite-couple-group-name: 'sprite_couple';
$sprite-couple-group: ('sprite_couple', $sprite-couple, $sprite-couple-2x, );
$sprite-couple-black-group-name: 'sprite_couple_black';
$sprite-couple-black-group: ('sprite_couple_black', $sprite-couple-black, $sprite-couple-black-2x, );
$sprite-drap-group-name: 'sprite_drap';
$sprite-drap-group: ('sprite_drap', $sprite-drap, $sprite-drap-2x, );
$sprite-elevator-group-name: 'sprite_elevator';
$sprite-elevator-group: ('sprite_elevator', $sprite-elevator, $sprite-elevator-2x, );
$sprite-family-group-name: 'sprite_family';
$sprite-family-group: ('sprite_family', $sprite-family, $sprite-family-2x, );
$sprite-family-black-group-name: 'sprite_family_black';
$sprite-family-black-group: ('sprite_family_black', $sprite-family-black, $sprite-family-black-2x, );
$sprite-friends-group-name: 'sprite_friends';
$sprite-friends-group: ('sprite_friends', $sprite-friends, $sprite-friends-2x, );
$sprite-friends-black-group-name: 'sprite_friends_black';
$sprite-friends-black-group: ('sprite_friends_black', $sprite-friends-black, $sprite-friends-black-2x, );
$sprite-gourmet-group-name: 'sprite_gourmet';
$sprite-gourmet-group: ('sprite_gourmet', $sprite-gourmet, $sprite-gourmet-2x, );
$sprite-group-group-name: 'sprite_group';
$sprite-group-group: ('sprite_group', $sprite-group, $sprite-group-2x, );
$sprite-group-gray-group-name: 'sprite_group_gray';
$sprite-group-gray-group: ('sprite_group_gray', $sprite-group-gray, $sprite-group-gray-2x, );
$sprite-groupes-group-name: 'sprite_groupes';
$sprite-groupes-group: ('sprite_groupes', $sprite-groupes, $sprite-groupes-2x, );
$sprite-groupes-gray-group-name: 'sprite_groupes_gray';
$sprite-groupes-gray-group: ('sprite_groupes_gray', $sprite-groupes-gray, $sprite-groupes-gray-2x, );
$sprite-groups-group-name: 'sprite_groups';
$sprite-groups-group: ('sprite_groups', $sprite-groups, $sprite-groups-2x, );
$sprite-hamam-group-name: 'sprite_hamam';
$sprite-hamam-group: ('sprite_hamam', $sprite-hamam, $sprite-hamam-2x, );
$sprite-handicap-group-name: 'sprite_handicap';
$sprite-handicap-group: ('sprite_handicap', $sprite-handicap, $sprite-handicap-2x, );
$sprite-heart-group-name: 'sprite_heart';
$sprite-heart-group: ('sprite_heart', $sprite-heart, $sprite-heart-2x, );
$sprite-heart-gray-group-name: 'sprite_heart_gray';
$sprite-heart-gray-group: ('sprite_heart_gray', $sprite-heart-gray, $sprite-heart-gray-2x, );
$sprite-heart-white-group-name: 'sprite_heart_white';
$sprite-heart-white-group: ('sprite_heart_white', $sprite-heart-white, $sprite-heart-white-2x, );
$sprite-jardin-group-name: 'sprite_jardin';
$sprite-jardin-group: ('sprite_jardin', $sprite-jardin, $sprite-jardin-2x, );
$sprite-location-group-name: 'sprite_location';
$sprite-location-group: ('sprite_location', $sprite-location, $sprite-location-2x, );
$sprite-offers-dishes-homemade-meal-activity-specialties-group-name: 'sprite_offers-dishes-homemade-meal-activity-specialties';
$sprite-offers-dishes-homemade-meal-activity-specialties-group: ('sprite_offers-dishes-homemade-meal-activity-specialties', $sprite-offers-dishes-homemade-meal-activity-specialties, $sprite-offers-dishes-homemade-meal-activity-specialties-2x, );
$sprite-parking-group-name: 'sprite_parking';
$sprite-parking-group: ('sprite_parking', $sprite-parking, $sprite-parking-2x, );
$sprite-patrimoine-group-name: 'sprite_patrimoine';
$sprite-patrimoine-group: ('sprite_patrimoine', $sprite-patrimoine, $sprite-patrimoine-2x, );
$sprite-petanque-group-name: 'sprite_petanque';
$sprite-petanque-group: ('sprite_petanque', $sprite-petanque, $sprite-petanque-2x, );
$sprite-pets-group-name: 'sprite_pets';
$sprite-pets-group: ('sprite_pets', $sprite-pets, $sprite-pets-2x, );
$sprite-piscine-group-name: 'sprite_piscine';
$sprite-piscine-group: ('sprite_piscine', $sprite-piscine, $sprite-piscine-2x, );
$sprite-resto-group-name: 'sprite_resto';
$sprite-resto-group: ('sprite_resto', $sprite-resto, $sprite-resto-2x, );
$sprite-room-group-name: 'sprite_room';
$sprite-room-group: ('sprite_room', $sprite-room, $sprite-room-2x, );
$sprite-sauna-group-name: 'sprite_sauna';
$sprite-sauna-group: ('sprite_sauna', $sprite-sauna, $sprite-sauna-2x, );
$sprite-solo-group-name: 'sprite_solo';
$sprite-solo-group: ('sprite_solo', $sprite-solo, $sprite-solo-2x, );
$sprite-solo-black-group-name: 'sprite_solo_black';
$sprite-solo-black-group: ('sprite_solo_black', $sprite-solo-black, $sprite-solo-black-2x, );
$sprite-spa-group-name: 'sprite_spa';
$sprite-spa-group: ('sprite_spa', $sprite-spa, $sprite-spa-2x, );
$sprite-sport-group-name: 'sprite_sport';
$sprite-sport-group: ('sprite_sport', $sprite-sport, $sprite-sport-2x, );
$sprite-takeawaycooked-dishes-group-name: 'sprite_takeawaycooked-dishes';
$sprite-takeawaycooked-dishes-group: ('sprite_takeawaycooked-dishes', $sprite-takeawaycooked-dishes, $sprite-takeawaycooked-dishes-2x, );
$sprite-tent-group-name: 'sprite_tent';
$sprite-tent-group: ('sprite_tent', $sprite-tent, $sprite-tent-2x, );
$sprite-terrasse-group-name: 'sprite_terrasse';
$sprite-terrasse-group: ('sprite_terrasse', $sprite-terrasse, $sprite-terrasse-2x, );
$sprite-tv-group-name: 'sprite_tv';
$sprite-tv-group: ('sprite_tv', $sprite-tv, $sprite-tv-2x, );
$sprite-wifi-group-name: 'sprite_wifi';
$sprite-wifi-group: ('sprite_wifi', $sprite-wifi, $sprite-wifi-2x, );
$retina-groups: ($sprite-bag-group, $sprite-bag-gray-group, $sprite-bar-group, $sprite-bike-group, $sprite-breakfast-group, $sprite-bungalow-group, $sprite-camping-group, $sprite-caravan-group, $sprite-clim-group, $sprite-couple-group, $sprite-couple-black-group, $sprite-drap-group, $sprite-elevator-group, $sprite-family-group, $sprite-family-black-group, $sprite-friends-group, $sprite-friends-black-group, $sprite-gourmet-group, $sprite-group-group, $sprite-group-gray-group, $sprite-groupes-group, $sprite-groupes-gray-group, $sprite-groups-group, $sprite-hamam-group, $sprite-handicap-group, $sprite-heart-group, $sprite-heart-gray-group, $sprite-heart-white-group, $sprite-jardin-group, $sprite-location-group, $sprite-offers-dishes-homemade-meal-activity-specialties-group, $sprite-parking-group, $sprite-patrimoine-group, $sprite-petanque-group, $sprite-pets-group, $sprite-piscine-group, $sprite-resto-group, $sprite-room-group, $sprite-sauna-group, $sprite-solo-group, $sprite-solo-black-group, $sprite-spa-group, $sprite-sport-group, $sprite-takeawaycooked-dishes-group, $sprite-tent-group, $sprite-terrasse-group, $sprite-tv-group, $sprite-wifi-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
