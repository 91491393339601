html {
  font-size: 62.5%;
  scroll-padding-top: 100px;
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-family: $avenir_regular;
  font-family: $intro_regular;
  color: theme-color("text-color");
  font-size: 16px;
  min-height: 100%; // [2]
  height: 100%;
  -webkit-text-size-adjust: 100%; // [3]
  -ms-text-size-adjust: 100%; // [3]
  display: flex;
  flex-direction: column;
}
.hidden-svg {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
}
@mixin container-widths {
  @include media-breakpoint-up(xs) {
    max-width: 480px;
  }

  @include media-breakpoint-up(md) {
    max-width: 720px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 960px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1140px;
  }
}

.icn {
  display: inline-block;
}

a {
  color: theme-color("blue");

  &:hover {
    text-decoration: none;
    color: theme-color("blue");
  }
}

p,
.content ul {
  a:not(.btn) {
    box-shadow: inset 0 -10px 0 0 rgba(theme-color("primary"), 0.3);
    padding-bottom: 0;
    transition: all 0.3s ease;
    .fas,
    .fab {
      transition: all 0.3s ease;
    }

    &:hover {
      box-shadow: inset 0 -20px 0 0 rgba(theme-color("primary"), 0.4);
      .fas,
      .fab {
        display: inline-block;
        transition: all 0.3s ease;
        margin-right: 5px;
      }
    }
  }
}

#id_index {
  .container-fluid {
    max-width: 1500px;
  }
}

main,
.main-wrap {
  flex: 1 0 auto;
  width: 100%;
  min-width: 100%;

  @include media-breakpoint-down(sm) {
    // overflow-x: hidden;
  }
}

.footer__site {
  flex-shrink: 0;
}

blockquote {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.4;
  width: 90%;
  margin: 5vh 0 5vh -55px;
  padding: 20px 50px;
  border-left: 5px solid #f1f4f4;
}

.pagination {
  .page-item {
    background-color: theme-color("primary");

    &.active {
      background-color: darken(theme-color("primary"), 3);
    }

    .page-link {
      background-color: transparent;
      color: $white;
      border: 0;
      font-weight: bold;
      padding: 1rem;
    }
  }
}

.center {
  @include make-container();
  max-width: 1140px;

  &__fluid {
    max-width: 100%;
  }

  @include media-breakpoint-down(md) {
    overflow-x: hidden;
  }
}

.block {
  @include make-row();
}

.page__title {
  @include make-container();
  @include container-widths;
}

.breadcrumb {
  background-color: transparent;
  padding-left: 0;
  margin-bottom: 0;

  a {
    color: theme-color("text-color");
  }

  li + li {
    &:before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/";
    }
  }

  li + li {
    padding-left: 0.5rem;
  }

  a,
  li {
    font-size: 14px;
  }

  li.active {
    font-family: $intro_medium;
  }

  li:last-child {
    @include media-breakpoint-down(md) {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.breadcrumb_blog {
  color: white;

  .breadcrumb-item + .breadcrumb-item::before {
    color: white;
  }
}

.cms_content_banner,
#id_landing {
  .breadcrumb-item.active {
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.btn-link {
  padding: 0;
  border: 0;
  background-color: transparent;
  color: theme-color("primary");
}

#id_legalnotice {
  h1 {
    color: theme-color("primary");
  }

  h2 {
    font-size: 24px;
    color: theme-color("blue");
  }

  .legalnotice-list {
    list-style-type: none;
    padding-left: 0;

    > li {
      padding: 3rem 0;
      margin-bottom: 10px;
    }
  }

  .legalnotice-extra {
    margin-bottom: 3rem;
  }
}

.error_wrapper {
  max-width: 600px;
}

#id_error_page .main-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;

  h1 {
    color: theme-color("blue");
  }
  .button {
    all: unset;
    background-color: theme-color("primary");
    color: white;
    padding: 1rem 2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: darken(theme-color("primary"), 4);
    }
    i {
      margin-right: 1rem;
    }
  }
}

//curator

.crt-widget-grid.crt-grid-col4 .crt-grid-post {
  padding: 1rem;
}

.crt-widget-grid.crt-grid-col3 .crt-grid-post {
  padding: 10px;
}

.crt-feed {
  @include media-breakpoint-up(lg) {
    margin-top: 2rem;
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

.dropdown-menu {
  @include font-size(1.6);
}

svg {
  text {
    font-family: $avenir_regular;
  }
}

.share__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .share_title {
    font-weight: bold;
    margin-right: 10px;
  }

  a.social-share-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: white;
    border-radius: 50%;
    margin-right: 10px;

    i {
      font-size: 12px;
    }

    &.facebook-share-button {
      background-color: theme-color("facebook");
    }

    &.twitter-share-button {
      background-color: theme-color("twitter");
    }

    &.googleplus-share-button {
      background-color: theme-color("googleplus");
    }

    &.linkedin-share-button {
      background-color: theme-color("linkedin");
    }

    &.email-share-button {
      background-color: theme-color("primary");
      margin-right: 0;
    }
  }
}

[data-readmore-enable],
.readmore {
  overflow: hidden;
  position: relative;
}

[data-readmore] {
  &::after {
    height: 60px;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.readmore {
  &::after {
    background: linear-gradient(
      to top,
      theme-color("white_darker") 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  &.after-none {
    &::after {
      display: none;
    }
  }
}

[data-readmore-enable] {
  &::after {
    background: linear-gradient(to top, white 0%, rgba(255, 255, 255, 0) 100%);
  }
}

.after-none {
  &::after {
    display: none;
  }
}

.readmore-toggle {
  margin-bottom: 20px;
  margin-top: 10px;
}

.date-range {
  span:not(:first-child) {
    display: inline-block;
    margin-left: 3px;
  }
}

.maplist {
  display: block;
  height: 18px;
  width: 20px;
  background-image: url(../img/map-list-yellow.svg);
}

.active {
  .maplist {
    background-image: url(../img/map-list-white.svg);
  }
}

.drp-calendar.right {
  display: none !important;
}

@include media-breakpoint-only(xs) {
  .hidden-xs {
    display: none;
  }
}
@include media-breakpoint-down(md) {
  .hidden-sm {
    display: none;
  }
}

.crisp-client .cc-tlyw {
  z-index: 999 !important;
}

.modal{
  z-index: 999999;
}
