.footer {
  background: #222;
  padding-top: 4rem;
  position: relative;
  font-family: $intro_medium;
  color: $white;

  @include media-breakpoint-down(md) {
    text-align: center;
    padding-bottom: 0;

  }

  a {
    color: $white;
    transition: all 0.3s ease;

    &:hover {
      color: theme-color("primary");
    }
  }

  &__nav {
    @extend .list-unstyled;

    a {
      color: theme-color("text-color");
      font-size: 18px;
      line-height: 29px;
    }
  }

  .border_top {
    padding-top: 3rem;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
  }

  .legal {
    background-color: $white;
    position: relative;
    z-index: 1;
    padding: 1rem;
    font-family: $intro_regular;
    color: theme-color("text-color");

    a {
      font-family: $intro_regular;
      color: theme-color("text-color");

      &:hover {
        color: theme-color('blue');
      }
    }

    @include media-breakpoint-down(md) {
      li {
        min-width: 100%;
      }

      li:not(:last-child) {

        border-bottom: 1px solid #eee;
        text-align: center;
      }
    }
  }

  .container {
    z-index: 1;
    position: relative;
  }

  form {
    opacity: 0.9;
    background: #ffffff;
    box-shadow: 0 2px 70px 0 rgba(222, 222, 222, 0.5);
    border-radius: 100px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0.5rem;
    padding-left: 1.5rem;
    display: flex;

    input {
      border: 0;
      flex: 1;

      &:hover,
      &:focus {
        outline: none;
      }
    }

    input[type="submit"] {
      border-radius: 50%;
      color: $white;
      background: theme-color("primary");
      box-shadow: 0 4px 14px 0 theme-color("primary");
      height: 40px;
      width: 40px;
      max-width: 40px;
    }
  }

  h3 {
    text-transform: uppercase;
    font-size: 16px;
    font-family: $intro_medium;
    letter-spacing: 0px;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 1rem;
    color: $white;

    @include media-breakpoint-down(md) {
      margin-top: 2rem;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 30px;
      height: 1px;
      background-color: theme-color("primary");

      @include media-breakpoint-down(md) {
        left: 50%;
        margin-left: -15px;

      }
    }
  }

  .d-flex.address {
    @include media-breakpoint-down(md) {
      flex-direction: column;

      .flex-column {
        flex-direction: row !important;
        width: 100%;
        justify-content: space-between;

        &.nav {
          border-right: 0;
          border-bottom: 1px solid theme-color('primary');
          padding-bottom: 0;
          margin-bottom: 20px;

          a::after {
            display: none;
          }
        }
      }
    }
  }

  .footer-links {
    a {
      display: block;
      position: relative;
      text-transform: uppercase;
      margin-bottom: 1rem;
      padding-left: 2rem;

      @include media-breakpoint-up(md) {
        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          left: 0;
          bottom: unset;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 10px solid theme-color("primary");
          background-color: transparent;
        }
      }
    }
  }

  .logo {
    margin-bottom: 3rem;
  }

  // &::after {
  //   height: 100%;
  //   width: 750px;
  //   content: "";
  //   background-image: url(../img/shape.svg);
  //   background-repeat: no-repeat;
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  // }

  .address {
    .nav {
      border-right: 1px solid theme-color("primary");

      &-item {
        min-width: 100px;
        font-family: $intro_medium;
        letter-spacing: 1px;
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 0.6rem;

        a {
          color: inherit;
          display: block;
          position: relative;
          padding-right: 0.5rem;

          &.active {
            color: theme-color("primary");

            &::after {
              content: "";
              position: absolute;
              right: -17px;
              top: 5px;
              width: 0;
              height: 0;
              border-top: 7px solid transparent;
              border-bottom: 7px solid transparent;
              border-left: 7px solid theme-color("primary");
              clear: both;
            }
          }
        }
      }
    }

    ul {
      list-style-type: none;
      padding: 2rem 1rem;
      padding-top: 0;

      li {
        line-height: 1.8;
      }

      a {
        color: theme-color("primary");
      }
    }
  }
}

.row {
  &.first {
    margin-bottom: 2rem;
  }
}

.social__links {
  display: flex;
  padding-left: 0;

  @include media-breakpoint-down(md) {
    justify-content: center;
  }

  li {
    margin-right: 1rem;
    width: 35px;
    height: 35px;
    background-color: $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      color: #222;
      display: flex;

      &.facebook:hover {
        color: theme-color("facebook");
      }

      &.twitter:hover {
        color: theme-color("twitter");
      }

      &.instagram:hover {
        color: theme-color("instagram");
      }

      &.linkedin:hover {
        color: theme-color("linkedin");
      }
    }
  }
}


.fixed__bar {
  transition: all .5s ease;

  &.is__fixed {

    opacity: 1;

    .dropup {

      position: fixed;
      left: 1rem;
      bottom: 1rem;
      z-index: 999;
    }

    .booking__btn {
      zoom: .8;
      transition: all .3s linear;

      .text {
        opacity: 0;
        visibility: hidden;
        width: 0;
      }

      &:hover,
      &:focus-within {
        zoom: 1;

        .text {
          opacity: 1;
          visibility: visible;
          width: auto;
          margin-left: 1rem;
        }
      }
    }
  }

  transition: all .3s ease;
  opacity: 0;
}

.dropup {
  ul {
    height: 0;
    overflow: hidden;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    transition: all .3s linear;

    li {
      padding: 1rem;
      background-color: lighten(theme-color('primary'), 10);
      border-bottom: 1px solid lighten(theme-color('primary'), 20);
      ;

      &:last-child {
        border-bottom: 0;
      }
    }

    a {
      display: block;
    }

    a:hover {
      color: theme-color('gray') !important;
    }

  }

  &.hover ul {
    height: 134px;
  }

  span.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;


  }
}

@include media-breakpoint-up(lg) {

  // main{
  //    min-height: 100%;
  //    margin-bottom: -530px;
  //    position: relative;
  //    &::after{
  //      content:"";
  //      display: block;
  //      height: 590px;
  //      width: 100%;
  //    }
  // }


  // .footer{
  //   height: 530px;
  // }
}


.logo_pertners {
  background: #fff;

  .nav {
    margin-bottom: 0;
    justify-content: center;
    border-bottom: 1px solid #eee;

    li {
      margin: 1px;

      img {
        transform: scale(.6);
        filter: grayscale(1);
        transition: all .3s ease;
      }

      &:hover img {
        transform: scale(.8);
        filter: grayscale(0);
      }
    }
  }
}
