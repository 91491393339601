.daterangepicker-input {
  background-color: transparent;
  color: $yellow;
  padding: 0 0 0 1rem;
  width: 240px;
  cursor: pointer;
}

.daterangepicker {
  z-index: 9999999;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  .ranges li {
    font-size: 18px;
    padding: 10px 12px;
  }

  .drp-calendar {
    max-width: 100%;
  }
  .calendar-table {
    .month {
      font-size: 16px;
    }

    th,
    td {
      font-size: 14px;
    }
  }

  .drp-selected {
    font-size: 16px;
    font-weight: bold;
  }
  .drp-buttons .btn {
    font-size: 14px;
  }
}
.daterangepicker.show-calendar .ranges{
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
